import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'languages/i18n';

import Main from 'pages/Main';
import Admin from 'pages/Admin';
import Login from 'pages/Login';

import { unregister } from './registerServiceWorker';

ReactDOM.render(<I18nextProvider i18n={i18n} >
  <Router basename='/fototeca'>
    <Switch>
      <Route path='/' exact={true} component={Main} />
      <Route path='/signin' exact={true} component={Login} />
      <Route path='/admin' exact={true} component={Admin} />
      {/*<Route path='*' exact={true} component={Main}/>*/}
    </Switch>
  </Router>
</I18nextProvider>, document.getElementById('root'));

unregister();
