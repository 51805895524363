import React, { Component } from 'react';
import Draggabilly from 'draggabilly';
import { translate } from 'react-i18next';
import { store } from 'react-notifications-component';
import { Icon } from 'react-icons-kit';
import { map } from 'react-icons-kit/ionicons/map';
import { close } from 'react-icons-kit/fa/close';
import { pencil } from 'react-icons-kit/fa/pencil';
import { arrowUp } from 'react-icons-kit/fa/arrowUp';
import { arrowDown } from 'react-icons-kit/fa/arrowDown';
import { ic_done } from 'react-icons-kit/md/ic_done';
import { ic_close } from 'react-icons-kit/md/ic_close';
import { chevronUp } from 'react-icons-kit/fa/chevronUp';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import { ic_remove_red_eye } from 'react-icons-kit/md/ic_remove_red_eye';
import { ic_visibility_off } from 'react-icons-kit/md/ic_visibility_off';
import { initInfoMap } from 'utils/Visor';

import Button from 'components/Button/Button';
import GroupForm from 'components/GroupForm/GroupForm';
import FlightForm from 'components/FlightForm/FlightForm';
import CustomModal from 'components/CustomModal/CustomModal';
import ActionButton from 'components/ActionButton/ActionButton';

import ApiService from 'services/ApiService';
import LocalStorage from 'utils/LocalStorage';
import Utils from 'utils/Utils';

import './FlightsConfig.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class FlightsConfig extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: LocalStorage.getString('language') || 'es',
      opened: false,
      selected: undefined,
      option: undefined,
      data: {},
      groupsData: [],
      collapsedGroups: false,
      collapsedFlights: false,
    };
  }

  componentWillMount() {
    this.loadData(true);
  }

  loadData(startBlock) {
    const { t } = this.props;
    const _this = this;
    if (startBlock === true) {
      _this.props.block();
    }

    ApiService.getRequest(API_REST + 'api/flight_group')
    .then((response) => {
      const groupsData = response;
      ApiService.getRequest(API_REST + 'api/flight')
      .then((response2) => {
        const data = {};
        groupsData.forEach((gd) => {
          const name = gd.name;
          const gdRows = [];
          response2.forEach((m) => {
            if (name === m.category_name) {
              gdRows.push(m);
            }
          });

          if (gdRows.length > 0) {
            data[name] = gdRows;
          }
        });

        _this.setState({ groupsData, data }, () => {
          _this.props.unblock();
        });
      }).catch((error) => {
        _this.props.unblock();
        store.addNotification({
          title: t('flightsConfig.error'),
          message: t('flightsConfig.load_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }).catch((error) => {
      _this.props.unblock();
      store.addNotification({
        title: t('flightsConfig.error'),
        message: t('flightsConfig.load_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  handleOpen = (selected, option) => {
    this.setState({ opened: true, selected: selected, option }, () => {
      if (option === 'map') {
        this.loadMap();
      }

      setTimeout(() => {
        if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
          if (option === 'group') {
            document.querySelector('#groupformName').focus();
          } else if (option === 'flight') {
            document.querySelector('#flightformTitle').focus();
          }

          const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
            containment: 'body',
            handle: '.customModalTitle',
          });

          draggable.enable();
        }
      }, 50);
    });
  }

  handleClose = () => {
    this.setState({ opened: false, selected: undefined, option: undefined });
  }

  loadMap = () => {
    const url = CONFIGURATIONS.geojson_url + 'coverages/' + this.state.selected.filesName + '.geojson';
    Utils.getFile(url).then((geojson) => {
      setTimeout(() => {
        initInfoMap(geojson);
      }, 200);
    });
  }

  saveGroup = (group) => {
    const { t } = this.props;
    const _this = this;
    const newData = JSON.parse(JSON.stringify(group));
    _this.props.block();
    if (newData.id !== undefined) {
      ApiService.putRequest(API_REST + 'api/flight_group/' + newData.id, newData)
      .then((response) => {
        _this.handleClose();
        _this.loadData(false);
        store.addNotification({
          title: t('flightsConfig.saved'),
          message: t('flightsConfig.changes_saved'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('flightsConfig.error'),
          message: t('flightsConfig.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      newData.sort = _this.getGroupSort();
      newData.hidden = false;
      ApiService.postRequest(API_REST + 'api/flight_group/', newData)
      .then((response) => {
        _this.handleClose();
        _this.loadData(false);
        store.addNotification({
          title: t('flightsConfig.saved'),
          message: t('flightsConfig.created'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('flightsConfig.error'),
          message: t('flightsConfig.save_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  saveFlight = (flight) => {
    const { t } = this.props;
    const _this = this;
    const newData = JSON.parse(JSON.stringify(flight));
    _this.props.block();
    if (newData.id !== undefined) {
      ApiService.putRequest(API_REST + 'api/flight/' + newData.id, newData)
      .then((response) => {
        _this.handleClose();
        _this.loadData(false);
        store.addNotification({
          title: t('flightsConfig.saved'),
          message: t('flightsConfig.changes_saved'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('flightsConfig.error'),
          message: t('flightsConfig.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      newData.sort = _this.getFlightSort(newData.category_name);
      newData.hidden = false;
      ApiService.postRequest(API_REST + 'api/flight/', newData)
      .then((response) => {
        _this.handleClose();
        _this.loadData(false);
        store.addNotification({
          title: t('flightsConfig.saved'),
          message: t('flightsConfig.created'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('flightsConfig.error'),
          message: t('flightsConfig.save_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  toogleShowHide = (elem, option) => {
    const newElem = elem;
    newElem.hidden = !elem.hidden;
    if (option === 'group') {
      this.saveGroup(newElem);
    } else if (option === 'flight') {
      this.saveFlight(newElem);
    }
  }

  removeGroup = (group) => {
    const { t } = this.props;
    const _this = this;
    if (_this.state.data[group.name] === undefined) {
      _this.props.block();
      ApiService.deleteRequest(API_REST + 'api/flight_group/' + group.id)
      .then((response) => {
        _this.loadData(false);
        store.addNotification({
          title: t('flightsConfig.saved'),
          message: t('flightsConfig.removed'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        store.addNotification({
          title: t('flightsConfig.error'),
          message: t('flightsConfig.delete_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      store.addNotification({
        title: t('flightsConfig.warning'),
        message: t('flightsConfig.group_not_empty'),
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }
  }

  removeFlight = (flight) => {
    const { t } = this.props;
    const _this = this;
    _this.props.block();
    ApiService.deleteRequest(API_REST + 'api/flight/' + flight.id)
    .then((response) => {
      _this.loadData(false);
      store.addNotification({
        title: t('flightsConfig.saved'),
        message: t('flightsConfig.removed'),
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 1500,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }).catch((error) => {
      _this.props.unblock();
      store.addNotification({
        title: t('flightsConfig.error'),
        message: t('flightsConfig.delete_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  moveGroup = (direction, og) => {
    const _this = this;
    const { t } = _this.props;
    const { groupsData } = _this.state;
    const modified = JSON.parse(JSON.stringify(og));
    let affected = undefined;
    if (direction === 'up') {
      const affectedFiltered = groupsData.filter((b) => {
        return b.sort === (og.sort - 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort - 1;
        affected.sort = affected.sort + 1;
      }
    } else if (direction === 'down'){
      const affectedFiltered = groupsData.filter((b) => {
        return b.sort === (og.sort + 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort + 1;
        affected.sort = affected.sort - 1;
      }
    }

    if (affected !== undefined) {
      _this.props.block();
      Promise.all([
        ApiService.putRequest(API_REST + 'api/flight_group/' + modified.id, modified),
        ApiService.putRequest(API_REST + 'api/flight_group/' + affected.id, affected),
      ]).then((responses) => {
        _this.loadData(false);
      }).catch((errors) => {
        store.addNotification({
          title: t('flightsConfig.error'),
          message: t('flightsConfig.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  moveFlight = (direction, flight) => {
    const _this = this;
    const { t } = _this.props;
    const data = _this.state.data[flight.category_name];
    const modified = JSON.parse(JSON.stringify(flight));
    let affected = undefined;
    if (direction === 'up') {
      const affectedFiltered = data.filter((b) => {
        return b.sort === (flight.sort - 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort - 1;
        affected.sort = affected.sort + 1;
      }
    } else if (direction === 'down'){
      const affectedFiltered = data.filter((b) => {
        return b.sort === (flight.sort + 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort + 1;
        affected.sort = affected.sort - 1;
      }
    }

    if (affected !== undefined) {
      _this.props.block();
      Promise.all([
        ApiService.putRequest(API_REST + 'api/flight/' + modified.id, modified),
        ApiService.putRequest(API_REST + 'api/flight/' + affected.id, affected),
      ]).then((responses) => {
        _this.loadData(false);
      }).catch((errors) => {
        store.addNotification({
          title: t('flightsConfig.error'),
          message: t('flightsConfig.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  getGroupSort = () => {
    return this.getMaxSort(this.state.groupsData) + 1;
  }

  getFlightSort = (group) => {
    return this.getMaxSort(this.state.data[group]) + 1;
  }

  getMaxSort = (array) => {
    let sort = 0;
    if (array !== undefined) {
      array.forEach((d) => {
        if (d.sort > sort) {
          sort = d.sort;
        }
      });
    }

    return sort;
  }

  toogleHide = (selector) => {
    const elem = document.querySelector(selector);
    if (elem.style.display === 'none') {
      elem.style.display = 'flex';
      if (selector.indexOf('flightsgroups') > -1) {
        this.setState({ collapsedGroups: false }, this.resizeTable);
      } else {
        this.setState({ collapsedFlights: false }, this.resizeTable);
      }
    } else {
      elem.style.display = 'none';
      if (selector.indexOf('flightsgroups') > -1) {
        this.setState({ collapsedGroups: true }, this.resizeTable);
      } else {
        this.setState({ collapsedFlights: true }, this.resizeTable);
      }
    }
  }

  resizeTable = () => {
    const flights = document.querySelector('.admin-flightslayers > div');
    if (this.state.collapsedFlights === false && this.state.collapsedGroups === true) {
      flights.style.maxHeight = '62vh';
    } else {
      flights.style.maxHeight = '46vh';
    }
  }

  render() {
    const { t } = this.props;
    const { lang, opened, selected, groupsData, data, option, collapsedGroups, collapsedFlights } = this.state;
    const rows = [];
    const groupRows = [];
    groupsData.forEach((fd) => {
      groupRows.push(
        <tr key={'fg' + fd.id}>
          <td>{fd.name}</td>
          <td>{fd.nameEn}</td>
          <td>
            <div>
              { fd.sort > 1 ?
                <ActionButton id={'flightgroupUp' + fd.id} icon={arrowUp} size={'18px'} color={'#fda823'} tooltip={t('flightsConfig.up')} onClick={this.moveGroup.bind(this, 'up', fd)} />
              : null }
              { fd.sort < this.getMaxSort(groupsData) ?
                <ActionButton id={'flightgroupDown' + fd.id} icon={arrowDown} size={'18px'} color={'#fda823'} tooltip={t('flightsConfig.down')} onClick={this.moveGroup.bind(this, 'down', fd)} />
              : null }
              <ActionButton id={'flightgroupHide' + fd.id} icon={fd.hidden ? ic_visibility_off : ic_remove_red_eye} size={'18px'} color={fd.hidden ? '#c2c2c2' : '#fda823'} tooltip={fd.hidden ? t('flightsConfig.show') : t('flightsConfig.hide')} onClick={this.toogleShowHide.bind(this, fd, 'group')} />
              <ActionButton id={'flightgroupEdit' + fd.id} icon={pencil} size={'18px'} color={'#fda823'} tooltip={t('flightsConfig.edit')} onClick={this.handleOpen.bind(this, fd, 'group')} />
              <ActionButton id={'flightgroupDelete' + fd.id} icon={close} size={'18px'} color={'#ff0000'} tooltip={t('flightsConfig.remove')} onClick={this.removeGroup.bind(this, fd)} />
            </div>
          </td>
        </tr>
      );
    });

    Object.keys(data).forEach((kd, index) => {
      const filtered = groupsData.filter(gd => gd.name === kd)[0];
      rows.push([<tr key={'fg' + index} className='flight-row-group'><td colSpan={8}>{lang === 'en' ? filtered.nameEn : filtered.name}</td></tr>]);
      data[kd].forEach((d) => {
        rows.push(
          <tr key={d.id}>
            <td style={{ minWidth: '90px', textAlign: 'center' }}>{d.title}</td>
            <td>{d.subtitle}</td>
            <td>{d.subtitleEn}</td>
            <td>{d.description}</td>
            <td>{d.descriptionEn}</td>
            <td>{d.certifiable === true ? <div style={{ color: '#fda823' }}><Icon size={'28px'} icon={ic_done} /></div> : d.certifiable === false ? <div><Icon size={'28px'} icon={ic_close} /></div> : <div>-</div>}</td>
            <td>{d.printable === true ? <div style={{ color: '#fda823' }}><Icon size={'28px'} icon={ic_done} /></div> : d.printable === false ? <div><Icon size={'28px'} icon={ic_close} /></div> : <div>-</div>}</td>
            <td>
              <div>
                { d.sort > 1 ?
                  <ActionButton id={'flightlayerUp' + d.id} icon={arrowUp} size={'18px'} color={'#fda823'} tooltip={t('flightsConfig.up')} onClick={this.moveFlight.bind(this, 'up', d)} />
                : null }
                { d.sort < this.getMaxSort(data[kd]) ?
                  <ActionButton id={'flightlayerDown' + d.id} icon={arrowDown} size={'18px'} color={'#fda823'} tooltip={t('flightsConfig.down')} onClick={this.moveFlight.bind(this, 'down', d)} />
                : null }
                <ActionButton id={'flightlayerHide' + d.id} icon={d.hidden ? ic_visibility_off : ic_remove_red_eye} size={'18px'} color={d.hidden ? '#c2c2c2' : '#fda823'} tooltip={d.hidden ? t('flightsConfig.show') : t('flightsConfig.hide')} onClick={this.toogleShowHide.bind(this, d, 'flight')} />
                <ActionButton id={'flightlayerSee' + d.id} icon={map} size={'18px'} color={'#fda823'} tooltip={t('flightsConfig.seeCoverage')} onClick={this.handleOpen.bind(this, d, 'map')} />
                <ActionButton id={'flightlayerEdit' + d.id} icon={pencil} size={'18px'} color={'#fda823'} tooltip={t('flightsConfig.edit')} onClick={this.handleOpen.bind(this, d, 'flight')} />
                <ActionButton id={'flightlayerDelete' + d.id} icon={close} size={'18px'} color={'#ff0000'} tooltip={t('flightsConfig.remove')} onClick={this.removeFlight.bind(this, d)} />
              </div>
            </td>
          </tr>
        );
      });
    });

    return (
      <React.Fragment>
        <div className='admin-content'>
          <p className='admin-content-title'>{t('flightsConfig.flights')}</p>
          <p className='admin-flight-title'>
            {t('flightsConfig.groups')}
            <span onClick={this.toogleHide.bind(this, '.admin-flightsgroups')}><Icon size={'13px'} icon={collapsedGroups ? chevronDown : chevronUp} /></span>
          </p>
          <div className='admin-flightsgroups'>
            <div>
              <table>
                <thead>
                  <tr>
                    <td>{t('flightsConfig.name') + ' (es)'}</td>
                    <td>{t('flightsConfig.name') + ' (en)'}</td>
                    <td>{t('flightsConfig.actions')}</td>
                  </tr>
                </thead>
                <tbody>
                  { groupRows }
                </tbody>
              </table>
            </div>
          </div>
          <br/>
          <p className='admin-flight-title'>
            {t('flightsConfig.flights')}
            <span onClick={this.toogleHide.bind(this, '.admin-flightslayers')}><Icon size={'13px'} icon={collapsedFlights ? chevronDown : chevronUp} /></span>
          </p>
          <div className='admin-flightslayers'>
            <div>
              <table>
                <thead>
                  <tr>
                    <td>{t('flightsConfig.title')}</td>
                    <td>{t('flightsConfig.subtitle') + ' (es)'}</td>
                    <td>{t('flightsConfig.subtitle') + ' (en)'}</td>
                    <td>{t('flightsConfig.descriptionEs')}</td>
                    <td>{t('flightsConfig.descriptionEn')}</td>
                    <td>{t('flightsConfig.certifiable')}</td>
                    <td>{t('flightsConfig.printable')}</td>
                    <td>{t('flightsConfig.actions')}</td>
                  </tr>
                </thead>
                <tbody>
                  { rows }
                </tbody>
              </table>
            </div>
          </div>
          <div className='admin-menu-button-container2'>
            <Button text={t('flightsConfig.addGroup')} active={true} onClick={this.handleOpen.bind(this, undefined, 'group')} />
            <Button text={t('flightsConfig.addFlight')} active={true} onClick={this.handleOpen.bind(this, undefined, 'flight')} />
          </div>
        </div>
        <CustomModal open={opened} onClose={this.handleClose} title={option === 'map' ? t('flightsConfig.flightCoverage') : option === 'flight' ? t('flightsConfig.flightData') : t('flightsConfig.groupData')}>
          { option === 'map' ?
            <div>
              <div className='flight-coverage-map' id='infoMap'></div>
            </div>
          : option === 'flight' ?
            <FlightForm data={selected} groupsData={groupsData} lang={lang} saveData={this.saveFlight} />
          : option === 'group' ?
            <GroupForm data={selected} saveData={this.saveGroup} />
          : null }
        </CustomModal>
      </React.Fragment>
    );
  }
}

export default translate()(FlightsConfig);
