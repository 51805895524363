import React, { Component } from 'react';
import { translate } from 'react-i18next';

import Button from 'components/Button/Button';

import './GroupForm.css';

class GroupForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data !== undefined ? JSON.parse(JSON.stringify(this.props.data)) : { name: '',  nameEn: '' },
      errorMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.data !== undefined) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 100);
    }
  }

  save = () => {
    let newData = this.state.data;
    let emptyFields = false;
    const name = document.querySelector('#groupformName');
    const nameEn = document.querySelector('#groupformNameEn');
    newData.name = name.value.trim();
    newData.nameEn = nameEn.value.trim();
    if (newData.name === '') {
      emptyFields = true;
      name.style.border = '2px solid red';
    } else {
      name.style.border = '1px solid black';
    }

    if (newData.nameEn === '') {
      emptyFields = true;
      nameEn.style.border = '2px solid red';
    } else {
      nameEn.style.border = '1px solid black';
    }

    if (!emptyFields) {
      this.setState({ errorMessage: false }, () => {
        this.props.saveData(newData);
      });
    } else {
      this.setState({ errorMessage: true });
    }
  }

  render() {
    const { t } = this.props;
    const { data, errorMessage } = this.state;

    return (
      <div className='groupform-container'>
        <div className='groupform' style={{ position: 'relative', left: '-7px' }}>
          <div>
            <label>{t('groupform.name') + ' (es)'}</label>
            <br/>
            <input type='text' id='groupformName' defaultValue={data.name} />
          </div>
          <div>
            <label>{t('groupform.name') + ' (en)'}</label>
            <br/>
            <input type='text' id='groupformNameEn' defaultValue={data.nameEn} />
          </div>
        </div>
        { errorMessage === true ?
          <div className='groupform-error'>{t('groupform.error')}</div>
        : null }
        <div className='groupform-button-container'>
          <Button text={t('groupform.save')} active={true} onClick={this.save} />
        </div>
      </div>
    );
  }
}

export default translate()(GroupForm);
