import React, { Component } from 'react';
import { translate } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import List from 'components/List/List';
import Actions from 'components/Actions/Actions';

import ApiService from 'services/ApiService';

import BlueIcon from 'static/img/blue_search.svg';
import WhiteIcon from 'static/img/white_search.svg';

import './Menu.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class Menu extends Component {
  constructor(props) {
    super(props);
    this.tooltipRef = null;
    this.state = {
      configurations: {},
    }
  }

  componentWillMount() {
    ApiService.getRequest(API_REST + 'api/configurations')
      .then((response) => {
        this.setState({ configurations: response });
      }).catch((error) => {
        this.setState({
          configurations: {
            searchByFile: true,
            searchByView: true,
            searchByMtn: true,
            photogramCertify: true,
            photogramInfo: true,
            photogramZoom: true,
            searchByDraw: true,
            photogramViewHide: true,
          }
        });
      });
  }

  handleClick = () => {
    this.props.openMenuHandler();
    if (this.tooltipRef !== null) {
      ReactTooltip.hide(this.tooltipRef);
    }
  }

  render() {
    const { t, menuOpened, selectFeatures, selectedFeatures, flights, selectTempFlight,
      addCentersLayer, centersLayer, flightLayer, flightName, block, unblock, onlyButton,
      shoppingCart, updateShoppingCart, } = this.props;
    const mobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const style = {
      left: menuOpened && !mobile ? '470px' : '0',
    };
    if (onlyButton) {
      return (
        <nav className={'tools-menu nav-wrapper icon-w0'}>
          <div ref={ref => this.tooltipRef = ref} className='collapseButton' style={{ left: '470px' }} data-event="mouseover"
            onClick={this.handleClick} data-tip data-for={'menuTooltip'} data-event-off="mouseout">
            <i style={{ display: 'inline-block' }}>
              <img style={{ height: '38px', position: 'relative', top: '2px', right: '2px' }} src={menuOpened ? BlueIcon : WhiteIcon} alt='Search menu icon' />
            </i>
          </div>
          <ReactTooltip id={'menuTooltip'} effect='solid' place='right' globalEventOff='touchstart'>
            {t('menu.search_on_view')}
          </ReactTooltip>
        </nav>
      );
    } else {
      return (
        <nav className={menuOpened && !mobile ? 'tools-menu nav-wrapper' : 'tools-menu nav-wrapper icon-w0'}>
          <div ref={ref => this.tooltipRef = ref} className={'collapseButton' + (menuOpened && !mobile ? ' opened' : '')} style={style} data-event="mouseover" data-event-off="mouseout"
            onClick={this.handleClick} data-tip data-for={'menuTooltip'}>
            <i style={{ display: 'inline-block' }}>
              <img style={{ height: '38px', position: 'relative', top: '2px', right: '2px' }} src={menuOpened && !mobile ? BlueIcon : WhiteIcon} alt='Search menu icon' />
            </i>
          </div>
          <ReactTooltip id={'menuTooltip'} effect='solid' place='right'>
            {t('menu.search_on_view')}
          </ReactTooltip>
          <Actions selectFeatures={selectFeatures} selectedFeatures={selectedFeatures} flights={flights} menuOpened={menuOpened} selectTempFlight={selectTempFlight}
            addCentersLayer={addCentersLayer} centersLayer={centersLayer} block={block} unblock={unblock} flightLayer={flightLayer} configurations={this.state.configurations} />
          <List selectFeatures={selectFeatures} selectedFeatures={selectedFeatures} flights={flights}
            flightLayer={flightLayer} flightName={flightName} block={block} unblock={unblock} configurations={this.state.configurations}
            selectTempFlight={selectTempFlight} centersLayer={centersLayer} shoppingCart={shoppingCart} updateShoppingCart={updateShoppingCart} />
        </nav>
      );
    }
  }
}

export default translate()(Menu);
