import React, { Component } from 'react';
import Draggabilly from 'draggabilly';
import { translate } from 'react-i18next';
import { Icon } from 'react-icons-kit';
import { store } from 'react-notifications-component';
import { close } from 'react-icons-kit/fa/close';
import { pencil } from 'react-icons-kit/fa/pencil';
import { arrowUp } from 'react-icons-kit/fa/arrowUp';
import { arrowDown } from 'react-icons-kit/fa/arrowDown';
import { chevronUp } from 'react-icons-kit/fa/chevronUp';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import { ic_remove_red_eye } from 'react-icons-kit/md/ic_remove_red_eye';
import { ic_visibility_off } from 'react-icons-kit/md/ic_visibility_off';

import Button from 'components/Button/Button';
import GroupForm from 'components/GroupForm/GroupForm';
import MosaicForm from 'components/MosaicForm/MosaicForm';
import CustomModal from 'components/CustomModal/CustomModal';
import ActionButton from 'components/ActionButton/ActionButton';

import ApiService from 'services/ApiService';
import LocalStorage from 'utils/LocalStorage';

import './MosaicsConfig.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class MosaicsConfig extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: LocalStorage.getString('language') || 'es',
      opened: false,
      selected: undefined,
      option: undefined,
      data: {},
      groupsData: [],
      collapsedGroups: false,
      collapsedMosaics: false,
    };
  }

  componentWillMount() {
    this.loadData(true);
  }

  loadData(startBlock) {
    const { t } = this.props;
    const _this = this;
    if (startBlock === true) {
      _this.props.block();
    }

    ApiService.getRequest(API_REST + 'api/orthophoto_group')
    .then((response) => {
      const groupsData = response;
      ApiService.getRequest(API_REST + 'api/orthophoto')
      .then((response2) => {
        const data = {};
        groupsData.forEach((gd) => {
          const name = gd.name;
          const gdRows = [];
          response2.forEach((m) => {
            if (name === m.category_name) {
              gdRows.push(m);
            }
          });

          if (gdRows.length > 0) {
            data[name] = gdRows;
          }
        });

        _this.setState({ groupsData, data }, () => {
          _this.props.unblock();
        });
      }).catch((error) => {
        _this.props.unblock();
        store.addNotification({
          title: t('mosaicsConfig.error'),
          message: t('mosaicsConfig.load_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }).catch((error) => {
      _this.props.unblock();
      store.addNotification({
        title: t('mosaicsConfig.error'),
        message: t('mosaicsConfig.load_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  handleOpen = (selected, option) => {
    this.setState({ opened: true, selected, option }, () => {
      setTimeout(() => {
        if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
          if (option === 'group') {
            document.querySelector('#groupformName').focus();
          } else {
            document.querySelector('#mosaicformTitle').focus();
          }

          const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
            containment: 'body',
            handle: '.customModalTitle',
          });

          draggable.enable();
        }
      }, 50);
    });
  }

  handleClose = () => {
    this.setState({ opened: false, selected: undefined, option: undefined });
  }

  saveGroup = (group) => {
    const { t } = this.props;
    const _this = this;
    const newData = JSON.parse(JSON.stringify(group));
    _this.props.block();
    if (newData.id !== undefined) {
      ApiService.putRequest(API_REST + 'api/orthophoto_group/' + newData.id, newData)
      .then((response) => {
        _this.handleClose();
        _this.loadData(false);
        store.addNotification({
          title: t('mosaicsConfig.saved'),
          message: t('mosaicsConfig.changes_saved'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('mosaicsConfig.error'),
          message: t('mosaicsConfig.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      newData.sort = _this.getGroupSort();
      newData.hidden = false;
      ApiService.postRequest(API_REST + 'api/orthophoto_group/', newData)
      .then((response) => {
        _this.handleClose();
        _this.loadData(false);
        store.addNotification({
          title: t('mosaicsConfig.saved'),
          message: t('mosaicsConfig.created'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('mosaicsConfig.error'),
          message: t('mosaicsConfig.save_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  saveMosaic = (mosaic) => {
    const { t } = this.props;
    const _this = this;
    const newData = JSON.parse(JSON.stringify(mosaic));
    _this.props.block();
    if (newData.id !== undefined) {
      ApiService.putRequest(API_REST + 'api/orthophoto/' + newData.id, newData)
      .then((response) => {
        _this.handleClose();
        _this.loadData(false);
        store.addNotification({
          title: t('mosaicsConfig.saved'),
          message: t('mosaicsConfig.changes_saved'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('mosaicsConfig.error'),
          message: t('mosaicsConfig.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      newData.sort = _this.getMosaicSort(newData.category_name);
      newData.hidden = false;
      ApiService.postRequest(API_REST + 'api/orthophoto/', newData)
      .then((response) => {
        _this.handleClose();
        _this.loadData(false);
        store.addNotification({
          title: t('mosaicsConfig.saved'),
          message: t('mosaicsConfig.created'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('mosaicsConfig.error'),
          message: t('mosaicsConfig.save_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  removeGroup = (group) => {
    const { t } = this.props;
    const _this = this;
    if (_this.state.data[group.name] === undefined) {
      _this.props.block();
      ApiService.deleteRequest(API_REST + 'api/orthophoto_group/' + group.id)
      .then((response) => {
        _this.loadData(false);
        store.addNotification({
          title: t('mosaicsConfig.saved'),
          message: t('mosaicsConfig.removed'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        store.addNotification({
          title: t('mosaicsConfig.error'),
          message: t('mosaicsConfig.delete_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      store.addNotification({
        title: t('mosaicsConfig.warning'),
        message: t('mosaicsConfig.group_not_empty'),
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }
  }

  removeMosaic = (mosaic) => {
    const { t } = this.props;
    const _this = this;
    _this.props.block();
    ApiService.deleteRequest(API_REST + 'api/orthophoto/' + mosaic.id)
    .then((response) => {
      _this.loadData(false);
      store.addNotification({
        title: t('mosaicsConfig.saved'),
        message: t('mosaicsConfig.removed'),
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 1500,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }).catch((error) => {
      _this.props.unblock();
      store.addNotification({
        title: t('mosaicsConfig.error'),
        message: t('mosaicsConfig.delete_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  moveGroup = (direction, og) => {
    const _this = this;
    const { t } = _this.props;
    const { groupsData } = _this.state;
    const modified = JSON.parse(JSON.stringify(og));
    let affected = undefined;
    if (direction === 'up') {
      const affectedFiltered = groupsData.filter((b) => {
        return b.sort === (og.sort - 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort - 1;
        affected.sort = affected.sort + 1;
      }
    } else if (direction === 'down'){
      const affectedFiltered = groupsData.filter((b) => {
        return b.sort === (og.sort + 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort + 1;
        affected.sort = affected.sort - 1;
      }
    }

    if (affected !== undefined) {
      _this.props.block();
      Promise.all([
        ApiService.putRequest(API_REST + 'api/orthophoto_group/' + modified.id, modified),
        ApiService.putRequest(API_REST + 'api/orthophoto_group/' + affected.id, affected),
      ]).then((responses) => {
        _this.loadData(false);
      }).catch((errors) => {
        store.addNotification({
          title: t('mosaicsConfig.error'),
          message: t('mosaicsConfig.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  moveMosaic = (direction, mosaic) => {
    const _this = this;
    const { t } = _this.props;
    const data = _this.state.data[mosaic.category_name];
    const modified = JSON.parse(JSON.stringify(mosaic));
    let affected = undefined;
    if (direction === 'up') {
      const affectedFiltered = data.filter((b) => {
        return b.sort === (mosaic.sort - 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort - 1;
        affected.sort = affected.sort + 1;
      }
    } else if (direction === 'down'){
      const affectedFiltered = data.filter((b) => {
        return b.sort === (mosaic.sort + 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort + 1;
        affected.sort = affected.sort - 1;
      }
    }

    if (affected !== undefined) {
      _this.props.block();
      Promise.all([
        ApiService.putRequest(API_REST + 'api/orthophoto/' + modified.id, modified),
        ApiService.putRequest(API_REST + 'api/orthophoto/' + affected.id, affected),
      ]).then((responses) => {
        _this.loadData(false);
      }).catch((errors) => {
        store.addNotification({
          title: t('mosaicsConfig.error'),
          message: t('mosaicsConfig.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  getGroupSort = () => {
    return this.getMaxSort(this.state.groupsData) + 1;
  }

  getMosaicSort = (group) => {
    return this.getMaxSort(this.state.data[group]) + 1;
  }

  getMaxSort = (array) => {
    let sort = 0;
    if (array !== undefined) {
      array.forEach((d) => {
        if (d.sort > sort) {
          sort = d.sort;
        }
      });
    }

    return sort;
  }

  toogleHide = (selector) => {
    const elem = document.querySelector(selector);
    if (elem.style.display === 'none') {
      elem.style.display = 'flex';
      if (selector.indexOf('mosaicsgroups') > -1) {
        this.setState({ collapsedGroups: false }, this.resizeTable);
      } else {
        this.setState({ collapsedFlights: false }, this.resizeTable);
      }
    } else {
      elem.style.display = 'none';
      if (selector.indexOf('mosaicsgroups') > -1) {
        this.setState({ collapsedGroups: true }, this.resizeTable);
      } else {
        this.setState({ collapsedFlights: true }, this.resizeTable);
      }
    }
  }

  resizeTable = () => {
    const flights = document.querySelector('.admin-mosaicslayers > div');
    if (this.state.collapsedMosaics === false && this.state.collapsedGroups === true) {
      flights.style.maxHeight = '62vh';
    } else {
      flights.style.maxHeight = '41vh';
    }
  }

  toogleShowHide = (elem, option) => {
    const newElem = elem;
    newElem.hidden = !elem.hidden;
    if (option === 'group') {
      this.saveGroup(newElem);
    } else if (option === 'flight') {
      this.saveMosaic(newElem);
    }
  }

  render() {
    const { t } = this.props;
    const { lang, opened, selected, groupsData, data, option, collapsedGroups, collapsedMosaics } = this.state;
    const rows = [];
    const groupRows = [];
    groupsData.forEach((gd) => {
      groupRows.push(
        <tr key={'og' + gd.id}>
          <td>{gd.name}</td>
          <td>{gd.nameEn}</td>
          <td>
            <div>
              { gd.sort > 1 ?
                <ActionButton id={'mosaicgroupUp' + gd.id} icon={arrowUp} size={'18px'} color={'#fda823'} tooltip={t('mosaicsConfig.up')} onClick={this.moveGroup.bind(this, 'up', gd)} />
              : null }
              { gd.sort < this.getMaxSort(groupsData) ?
                <ActionButton id={'mosaicgroupDown' + gd.id} icon={arrowDown} size={'18px'} color={'#fda823'} tooltip={t('mosaicsConfig.down')} onClick={this.moveGroup.bind(this, 'down', gd)} />
              : null }
              <ActionButton id={'mosaicgroupHide' + gd.id} icon={gd.hidden ? ic_visibility_off : ic_remove_red_eye} size={'18px'} color={gd.hidden ? '#c2c2c2' : '#fda823'} tooltip={gd.hidden ? t('mosaicsConfig.show') : t('mosaicsConfig.hide')} onClick={this.toogleShowHide.bind(this, gd, 'group')} />
              <ActionButton id={'mosaicgroupEdit' + gd.id} icon={pencil} size={'18px'} color={'#fda823'} tooltip={t('mosaicsConfig.edit')} onClick={this.handleOpen.bind(this, gd, 'group')} />
              <ActionButton id={'mosaicgroupDelete' + gd.id} icon={close} size={'18px'} color={'#ff0000'} tooltip={t('mosaicsConfig.remove')} onClick={this.removeGroup.bind(this, gd)} />
            </div>
          </td>
        </tr>
      );
    });

    Object.keys(data).forEach((kd, index) => {
      const filtered = groupsData.filter(gd => gd.name === kd)[0];
      rows.push([<tr key={'mg' + index} className='mosaic-row-group'><td colSpan={8}>{lang === 'en' ? filtered.nameEn : filtered.name}</td></tr>]);
      data[kd].forEach((d) => {
        rows.push(
          <tr key={d.id}>
            <td>{d.title}</td>
            <td>{d.titleEn}</td>
            <td>{d.name}</td>
            <td>{d.url}</td>
            <td style={{ textAlign: 'center' }}>{d.version}</td>
            <td>{d.description}</td>
            <td>{d.descriptionEn}</td>
            <td>
              <div>
                { d.sort > 1 ?
                  <ActionButton id={'mosaiclayerUp' + d.id} icon={arrowUp} size={'18px'} color={'#fda823'} tooltip={t('mosaicsConfig.up')} onClick={this.moveMosaic.bind(this, 'up', d)} />
                : null }
                { d.sort < this.getMaxSort(data[kd]) ?
                  <ActionButton id={'mosaiclayerDown' + d.id} icon={arrowDown} size={'18px'} color={'#fda823'} tooltip={t('mosaicsConfig.down')} onClick={this.moveMosaic.bind(this, 'down', d)} />
                : null }
                <ActionButton id={'mosaiclayerHide' + d.id} icon={d.hidden ? ic_visibility_off : ic_remove_red_eye} size={'18px'} color={d.hidden ? '#c2c2c2' : '#fda823'} tooltip={d.hidden ? t('mosaicsConfig.show') : t('mosaicsConfig.hide')} onClick={this.toogleShowHide.bind(this, d, 'flight')} />
                <ActionButton id={'mosaiclayerEdit' + d.id} icon={pencil} size={'18px'} color={'#fda823'} tooltip={t('mosaicsConfig.edit')} onClick={this.handleOpen.bind(this, d, 'mosaic')} />
                <ActionButton id={'mosaiclayerDelete' + d.id} icon={close} size={'18px'} color={'#ff0000'} tooltip={t('mosaicsConfig.remove')} onClick={this.removeMosaic.bind(this, d)} />
              </div>
            </td>
          </tr>
        );
      });
    });

    return (
      <React.Fragment>
        <div className='admin-content'>
          <p className='admin-content-title'>{t('mosaicsConfig.mosaics')}</p>
          <p className='admin-mosaic-title'>
            {t('mosaicsConfig.groups')}
            <span onClick={this.toogleHide.bind(this, '.admin-mosaicsgroups')}><Icon size={'13px'} icon={collapsedGroups ? chevronDown : chevronUp} /></span>
          </p>
          <div className='admin-mosaicsgroups'>
            <div>
              <table>
                <thead>
                  <tr>
                    <td>{t('mosaicsConfig.name') + ' (es)'}</td>
                    <td>{t('mosaicsConfig.name') + ' (en)'}</td>
                    <td>{t('mosaicsConfig.actions')}</td>
                  </tr>
                </thead>
                <tbody>
                  { groupRows }
                </tbody>
              </table>
            </div>
          </div>
          <br/>
          <p className='admin-mosaic-title'>
            {t('mosaicsConfig.mosaics')}
            <span onClick={this.toogleHide.bind(this, '.admin-mosaicslayers')}><Icon size={'13px'} icon={collapsedMosaics ? chevronDown : chevronUp} /></span>
          </p>
          <div className='admin-mosaicslayers'>
            <div>
              <table>
                <thead>
                  <tr>
                    <td>{t('mosaicsConfig.legend') + ' (es)'}</td>
                    <td>{t('mosaicsConfig.legend') + ' (en)'}</td>
                    <td>{t('mosaicsConfig.name')}</td>
                    <td>URL</td>
                    <td>{t('mosaicsConfig.version')}</td>
                    <td>{t('mosaicsConfig.descriptionEs')}</td>
                    <td>{t('mosaicsConfig.descriptionEn')}</td>
                    <td>{t('mosaicsConfig.actions')}</td>
                  </tr>
                </thead>
                <tbody>
                  { rows }
                </tbody>
              </table>
            </div>
          </div>
          <div className='admin-menu-button-container2'>
            <Button text={t('mosaicsConfig.addGroup')} active={true} onClick={this.handleOpen.bind(this, undefined, 'group')} />
            <Button text={t('mosaicsConfig.addMosaic')} active={true} onClick={this.handleOpen.bind(this, undefined, 'mosaic')} />
          </div>
        </div>
        <CustomModal open={opened} onClose={this.handleClose} title={option === 'mosaic' ? t('mosaicsConfig.mosaicData') : t('mosaicsConfig.groupData')}>
          { option === 'mosaic' ?
            <MosaicForm data={selected} groupsData={groupsData} lang={lang} saveData={this.saveMosaic} />
          : option === 'group' ?
            <GroupForm data={selected} saveData={this.saveGroup} />
          : null }
        </CustomModal>
      </React.Fragment>
    );
  }
}

export default translate()(MosaicsConfig);
