import React, { Component } from 'react';
import { Loader } from 'react-loaders';
import { translate } from 'react-i18next';

import Map from 'components/Map/Map';
import Menu from 'components/Menu/Menu';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import SearchMenu from 'components/SearchMenu/SearchMenu';
import CustomModal from 'components/CustomModal/CustomModal';
import ApiService from 'services/ApiService';
import Utils from 'utils/Utils';
import { initMap, isLoadedMap, highlightFeatures, addCustomWMSLayer, addFlightLayers, newGeoJSONLayer,
  removeAllCustomWMSLayer, isCustomWMSLayerActive, removeAllFlightLayers, mapjs, clearPrintLayer, } from 'utils/Visor';

import 'loaders.css/loaders.min.css';
import './Layout.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class Layout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      flights: [],
      menuOpened: false,
      selectedFeatures: [],
      selectedFlight: undefined,
      flightLayer: undefined,
      flightName: undefined,
      centersLayer: undefined,
      marksLayer: undefined,
      blocking: true,
      menuSearchOpened: false,
      loading: true,
      shoppingCart: [],
      mobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
    };
  }

  componentDidMount() {
    const _this = this;
    ApiService.getRequest(API_REST + 'api/flight')
    .then((response) => {
      const newFlights = response.filter(d => d.hidden === false && d.category_hidden === false).map((d) => {
        const newD = Utils.copyObject(d);
        newD.text = d.description;
        newD.text_en = d.descriptionEn;
        newD.printPaper = d.printable;
        newD.fileName = d.filesName;
        newD.keys = d.keys.split(',').map(k => k.trim());
        newD.scales = d.printScales.split(',').map(k => k.trim());
        newD.printSizes = d.printSizes.split(',').map(k => k.trim());
        newD.coverage = CONFIGURATIONS.geojson_url + 'coverages/' + d.filesName + '.geojson';
        newD.centers = CONFIGURATIONS.geojson_url + 'centers/' + d.filesName + '.geojson';
        newD.marks = CONFIGURATIONS.geojson_url + 'marks/' + d.filesName + '.geojson';
        newD.inverse_coverage = CONFIGURATIONS.geojson_url + 'reverse_coverages/' + d.filesName + '.geojson';
        delete newD.filesName;
        delete newD.printable;
        delete newD.printScales;
        delete newD.description;
        delete newD.descriptionEn;
        return newD;
      });

      _this.setState({ flights: newFlights, loading: false }, () => {
        initMap(_this.getSelectedFlight, _this.getSelectedFeatures, _this.selectFeatures, _this.unblock, _this.props.t, this.props.params, _this.activateFlightFromPhotogram);
      });
    });
    if (this.state.mobile) {
      this.useEffect();
    }
  }

  getSelectedFlight = () => {
    return this.state.selectedFlight;
  }

  getSelectedFeatures = () => {
    return this.state.selectedFeatures;
  }

  selectFlight = (flight, flightLayer, flightName) => {
    clearPrintLayer();
    this.setState({ selectedFlight: flight, flightLayer: flightLayer, centersLayer: undefined, flightName: flightName });
  }

  allFotogramsOpenedSameLayer = () => {
    const _this = this;
    let res = false;
    if (this.state.selectedFeatures.length > 0) {
      let opened = this.state.selectedFeatures.filter((f) => {
        const flight = _this.state.flights.filter((flight) => {
          let res = false;
          flight.keys.forEach((k) => {
            res = res || f.indexOf(k) > -1;
          });

          return res;
        })[0];

        return isCustomWMSLayerActive(f) && _this.state.selectedFlight === flight.id;
      });

      res = res || opened.length > 0;
    } else {
      res = res || true;
    }

    return res;
  }

  selectFeatures = (selectedFeatures, fromMap) => {
    const _this = this;
    const oldSelected = _this.state.selectedFeatures;
    if (fromMap === true) {
      highlightFeatures(selectedFeatures);
      selectedFeatures.forEach((f) => {
        if (oldSelected.indexOf(f) === -1) {
          _this.block();
          if (!_this.allFotogramsOpenedSameLayer()) {
            removeAllCustomWMSLayer();
          }

          addCustomWMSLayer(CONFIGURATIONS.fotograms_layer_url, _this.state.flightLayer, f, this.unblock);
        }
      });

      this.setState({ selectedFeatures: selectedFeatures }, () => {
        if (!_this.state.menuOpened) {
          _this.openMenuHandler();
        }
      });
    } else {
      highlightFeatures(selectedFeatures);
      this.setState({ selectedFeatures: selectedFeatures });
    }
  }

  addCentersLayer = (centersLayer) => {
    this.setState({ centersLayer: centersLayer });
  }

  addMarksLayer = (marksLayer) => {
    this.setState({ marksLayer: marksLayer });
  }

  block = () => {
    this.setState({ blocking: true });
  }

  unblock = () => {
    this.setState({ blocking: false });
  }

  selectTempFlight = (flightSelected, callback) => {
    const _this = this;
    if (flightSelected !== undefined && this.state.flightLayer !== flightSelected) {
      removeAllFlightLayers();
      let flight = this.state.flights.filter((f) => {
        return f.layer === flightSelected;
      })[0];

      if (flight.coverage !== undefined) {
        this.block();
        Utils.getFile(flight.coverage).then((geojson) => {
          const coverage = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.coverage_name + ' ' + flight.title }, { displayInLayerSwitcher: false });
          _this.selectFlight(flight.id, flight.layer, flight.title + ' ' + flight.subtitle);
          Utils.getFile(flight.inverse_coverage).then((inverse_geojson) => {
            const inverseCoverage = newGeoJSONLayer({ source: inverse_geojson, extract: false, name: CONFIGURATIONS.coverage_name + ' ' + flight.title + ' Reverse' }, { displayInLayerSwitcher: false });
            Utils.getFile(flight.centers).then((geojson) => {
              const centers = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.centers_name }, { displayInLayerSwitcher: false });
              Utils.getFile(flight.marks).then((geojson) => {
                const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
                addFlightLayers(coverage, centers, marks, _this.getSelectedFeatures, _this.unblock, inverseCoverage);
                _this.addCentersLayer(centers);
                _this.addMarksLayer(marks);
                if (callback !== undefined) {
                  callback();
                }
              });
            });
          });
        });
      } else {
        _this.selectFlight(undefined, undefined, undefined);
      }
    } else {
      removeAllFlightLayers();
      _this.selectFlight(undefined, undefined, undefined);
    }
  }

  activateFlightFromPhotogram = (sharePhotogram) => {
    if (sharePhotogram !== undefined) {
      let flightRes = undefined;
      const photogram = sharePhotogram.map((elem) => {
        return '/var/www/apps/fototeca/data'.concat(elem);
      });

      for (let flight of this.state.flights) {
        if (photogram !== undefined && photogram.length > 0) {
          // eslint-disable-next-line
          flight.keys.forEach((k) => {
            if (photogram[0].indexOf(k) > -1) {
              flightRes = flight;
            }
          });
        }
      }

      if (flightRes !== undefined) {
        this.selectTempFlight(flightRes.layer, () => {
          this.selectFeatures(photogram, true);
        });
      }
    }
  }

  updateShoppingCart = (newShoppingCart) => {
    this.setState({ shoppingCart: newShoppingCart });
  }

  openMenuHandler = (evt) => {
    if (this.state.mobile) {
      this.openMobileMenuHandler(evt);
    } else {
      const { menuOpened, menuSearchOpened } = this.state;
      if (menuSearchOpened) {
        this.setState({ menuOpened: !menuOpened, menuSearchOpened: false });
      } else {
        this.setState({ menuOpened: !menuOpened });
        if (isLoadedMap()) {
          setTimeout(() => {
            const center = mapjs.getCenter();
            mapjs.getMapImpl().updateSize();
            setTimeout(() => {
              mapjs.setCenter(center);
              mapjs.refresh();
            }, CONFIGURATIONS.map_loaded_timeout);
              if (document.querySelector('#div-contenedor.m-mousesrs-container') !== null && document.querySelector('.m-panel.m-plugin-locator.opened') !== null) {
                let classes = document.querySelector('#div-contenedor.m-mousesrs-container').classList;
                let classes2 = document.querySelector('.m-panel.m-plugin-locator.opened').classList;
                if (classes.contains('mouseMenuOpened')) {
                  classes.remove('mouseMenuOpened');
                } else {
                  classes.add('mouseMenuOpened');
                }

                if (classes2.contains('ignSearchMenuOpened')) {
                  classes2.remove('ignSearchMenuOpened');
                } else {
                  classes2.add('ignSearchMenuOpened');
                }
              }

              if (menuOpened === false && menuSearchOpened === false) {
                document.querySelector('.footer-tab-button.ftb1').style.left = '500px';
                document.querySelector('.footer-tab-button.ftb2').style.left = '640px';
              } else {
                document.querySelector('.footer-tab-button.ftb1').style.left = '40px';
                document.querySelector('.footer-tab-button.ftb2').style.left = '180px';
              }

          }, CONFIGURATIONS.map_loaded_timeout);
        }
      }
    }
  };

  openMobileMenuHandler = (evt) => {
    let elem = document.querySelector('.collapse-icon-frames');
    let event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    elem.dispatchEvent(event);
  }

  openSearchMenuHandler = (evt) => {
    const { menuSearchOpened, menuOpened } = this.state;
    if (menuOpened) {
      this.setState({ menuSearchOpened: !menuSearchOpened, menuOpened: false });
    } else {
      this.setState({ menuSearchOpened: !menuSearchOpened });
      if (isLoadedMap()) {
        setTimeout(() => {
          const center = mapjs.getCenter();
          mapjs.getMapImpl().updateSize();
          setTimeout(() => {
            mapjs.setCenter(center);
            mapjs.refresh();
          }, CONFIGURATIONS.map_loaded_timeout);
          if (document.querySelector('#div-contenedor.m-mousesrs-container') !== null && document.querySelector('.m-panel.m-plugin-locator.opened') !== null) {
            let classes = document.querySelector('#div-contenedor.m-mousesrs-container').classList;
            let classes2 = document.querySelector('.m-panel.m-plugin-locator.opened').classList;
            if (classes.contains('mouseMenuOpened')) {
              classes.remove('mouseMenuOpened');
            } else {
              classes.add('mouseMenuOpened');
            }

            if (classes2.contains('ignSearchMenuOpened')) {
              classes2.remove('ignSearchMenuOpened');
            } else {
              classes2.add('ignSearchMenuOpened');
            }
          }

          if (menuOpened === false && menuSearchOpened === false) {
            document.querySelector('.footer-tab-button.ftb1').style.left = '500px';
            document.querySelector('.footer-tab-button.ftb2').style.left = '640px';
          } else {
            document.querySelector('.footer-tab-button.ftb1').style.left = '40px';
            document.querySelector('.footer-tab-button.ftb2').style.left = '180px';
          }
        }, CONFIGURATIONS.map_loaded_timeout);
      }
    }
  };

  useEffect = () => {
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    mediaQueryList.addEventListener('change', this.handleOrientationChange);
  }

  handleOrientationChange = (event) => {
    const elem = document.querySelector('.content-wrapper');
    elem.style.height = (window.innerHeight - 90) + 'px';
    mapjs.refresh();
  };

  render() {
    const { menuOpened, selectedFlight, selectedFeatures, centersLayer, flightLayer,
      flightName, blocking, flights, menuSearchOpened, loading, shoppingCart, } = this.state;

    return (
      <div className='content-wrapper' style={this.state.mobile ? {height: (window.innerHeight-90)+'px'} : {}}>
        <Header shoppingCart={shoppingCart} block={this.block} unblock={this.unblock}
          updateShoppingCart={this.updateShoppingCart} />
        <section className='layout-wrapper'>
          { !menuSearchOpened ?
            <Menu openMenuHandler={this.openMenuHandler} menuOpened={menuOpened} flights={flights}
              selectFeatures={this.selectFeatures} selectedFeatures={selectedFeatures} selectTempFlight={this.selectTempFlight}
              addCentersLayer={this.addCentersLayer} centersLayer={centersLayer} block={this.block}
              flightLayer={flightLayer} flightName={flightName} unblock={this.unblock}
              shoppingCart={shoppingCart} updateShoppingCart={this.updateShoppingCart} />
            : <Menu openMenuHandler={this.openMenuHandler} onlyButton /> }
          { !menuOpened ?
            <SearchMenu openSearchMenuHandler={this.openSearchMenuHandler} block={this.block}
              menuSearchOpened={menuSearchOpened} unblock={this.unblock} />
          :  <SearchMenu openSearchMenuHandler={this.openSearchMenuHandler } onlyButton /> }
          <Map />
        </section>
        { !loading ?
          <Footer selectFlight={this.selectFlight} selectedFlight={selectedFlight} flights={flights}
            getSelectedFeatures={this.getSelectedFeatures} addCentersLayer={this.addCentersLayer}
            addMarksLayer={this.addMarksLayer} block={this.block} unblock={this.unblock} />
        : null }
        <CustomModal open={blocking} blocking={true} onClose={this.unblock}>
          <div className='block-loader-container'>
            <Loader active type={'ball-triangle-path'} color='#364B5F'/>
          </div>
        </CustomModal>
      </div>
    );
  }
}

export default translate()(Layout);
