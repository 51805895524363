import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Icon } from 'react-icons-kit';
import { sort } from 'react-icons-kit/fa/sort';
import { ic_remove_red_eye } from 'react-icons-kit/md/ic_remove_red_eye';
import { ic_visibility_off } from 'react-icons-kit/md/ic_visibility_off';

import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import ActionButton from 'components/ActionButton/ActionButton';
import Utils from 'utils/Utils';
import { initCompareSelectionMap, getExtentByCoordinates } from 'utils/Visor';

import './CompareSelector.css';

declare var CONFIGURATIONS;

class CompareSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      flightSelected1: undefined,
      flightSelected2: undefined,
      totalTable1: [],
      totalTable2: [],
      selectedTable1: [],
      selectedTable2: [],
      mapOpened: false,
      mapSelected: undefined,
    };
  }

  componentWillMount() {
    const { flights, fotograms, getFlightOfFotogram } = this.props;
    this.setState({
      flightSelected1: flights[0],
      flightSelected2: flights.length > 1 ? flights[1] : flights[0],
      totalTable1: fotograms.filter(f => flights[0].id === getFlightOfFotogram(f).id),
      totalTable2: fotograms.filter(f => flights.length > 1 ? flights[1].id === getFlightOfFotogram(f).id : flights[0].id === getFlightOfFotogram(f).id),
    });
  }

  componentDidMount() {
    document.addEventListener('keyup', this.enterKeyCompare);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.enterKeyCompare);
  }

  enterKeyCompare = (evt) => {
    const keyCode = evt.charCode || evt.which;
    if (keyCode === 13 && (this.state.selectedTable1.length > 0 && this.state.selectedTable2.length > 0)) {
      this.openComparator();
    }
  }

  selectAll = (numTable) => {
    const newState = this.state;
    if (this.state['selectedTable' + numTable].length < this.state['totalTable' + numTable].length) {
      newState['selectedTable' + numTable] = this.state['totalTable' + numTable];
    } else {
      newState['selectedTable' + numTable] = [];
    }

    this.setState(newState);
  }

  changeSelectFlight = (numTable, evt) => {
    const { flights, fotograms, getFlightOfFotogram } = this.props;
    const id = parseInt(evt.target.value, 10);
    const filtered = flights.filter((f) => {
      return f.id === id;
    });

    const newState = this.state;
    newState['flightSelected' + numTable] = filtered[0];
    newState['totalTable' + numTable] = fotograms.filter(f => filtered[0].id === getFlightOfFotogram(f).id);
    this.setState(newState);
  }

  selectFotogram = (numTable, f) => {
    const newState = this.state;
    let newSelected  = [];
    if (this.state['selectedTable' + numTable].indexOf(f) > -1) {
      newSelected = this.state['selectedTable' + numTable].filter(item => item !== f);
    } else {
      newSelected = this.state['selectedTable' + numTable];
      newSelected.push(f);
    }

    newState['selectedTable' + numTable] = newSelected;
    this.setState(newState);
  }

  openMapFotogram = (f) => {
    if (document.getElementById('compareSelectionMap') !== null) {
      document.getElementById('compareSelectionMap').innerHTML = '';
    }

    if (!this.state.mapOpened || this.state.mapSelected !== f) {
      this.setState({ mapOpened: true, mapSelected: f }, () => {
        const flight = this.props.getFlightOfFotogram(f);
        Promise.all([Utils.getFile(flight.marks)]).then((geojsons) => {
          const marks = geojsons[0];
          let extent = [];
          for (let feature of marks.features) {
            if (Utils.stringContainsString(feature.properties[CONFIGURATIONS.fotogram_layer_attr], f)) {
              extent = getExtentByCoordinates(feature.geometry);
              break;
            }
          }

          initCompareSelectionMap(f, flight.layer, extent);
        });
      });
    } else {
      this.setState({ mapOpened: false, mapSelected: undefined });
    }
  }

  openComparator = () => {
    const { flightSelected1, flightSelected2, selectedTable1, selectedTable2 } = this.state;
    const data = {
      screen1: {
        fotograms: selectedTable1,
        flight: flightSelected1,
      },
      screen2: {
        fotograms: selectedTable2,
        flight: flightSelected2,
      },
    };

    document.removeEventListener('keyup', this.enterKeyCompare);
    this.props.openComparator(data);
  }

  render() {
    const { t, flights } = this.props;
    const { flightSelected1, flightSelected2, totalTable1, totalTable2, selectedTable1, selectedTable2, mapOpened, mapSelected } = this.state;
    const options = [];
    const table1 = [];
    const table2 = [];
    flights.forEach((f) => {
      options.push(<option key={'optFlight' + f.id} value={f.id}>{f.title + ' ' + f.subtitle}</option>);
    });

    totalTable1.forEach((f, i) => {
      table1.push(
        <tr key={'fS1' + i} className='tableRow'>
          <td className='tableRowCell cellCheckbox'>
            <CheckBox id={'tableRowCBS1' + i} onClick={this.selectFotogram.bind(null, 1, f)} isSelected={selectedTable1.indexOf(f) > -1} />
          </td>
          <td className='tableRowCell tableRowFotogramName'>
            {f !== undefined ? f.substring(f.lastIndexOf('/') + 1, f.lastIndexOf('.')) : '-'}
          </td>
          <td className='tableRowCell buttons'>
            <div className='buttonsContainerTableRow'>
              <ActionButton id={'tableRowShowS1' + f} icon={(mapOpened === false || mapSelected !== f) ? ic_remove_red_eye : ic_visibility_off} size={'18px'} color={'#fda823'} tooltip={''} onClick={this.openMapFotogram.bind(null, f)} />
            </div>
          </td>
        </tr>
      );
    });

    totalTable2.forEach((f, i) => {
      table2.push(
        <tr key={'fS2' + i} className='tableRow'>
          <td className='tableRowCell cellCheckbox'>
            <CheckBox id={'tableRowCBS2' + i} onClick={this.selectFotogram.bind(null, 2, f)} isSelected={selectedTable2.indexOf(f) > -1} />
          </td>
          <td className='tableRowCell tableRowFotogramName'>
            {f !== undefined ? f.substring(f.lastIndexOf('/') + 1, f.lastIndexOf('.')) : '-'}
          </td>
          <td className='tableRowCell buttons'>
            <div className='buttonsContainerTableRow'>
              <ActionButton id={'tableRowShowS2' + f} icon={(mapOpened === false || mapSelected !== f) ? ic_remove_red_eye : ic_visibility_off} size={'18px'} color={'#fda823'} tooltip={''} onClick={this.openMapFotogram.bind(null, f)} />
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div style={{ maxHeight: '70vh' }}>
        <div className='compareSelectionContainer row'>
          <p>{t('compareSelector.text_description')}</p>
            <div className='row'>
              <div className='compareTableContainer'>
                <div className='compareTableScreenSelector'>
                  <Fragment>
                    <span>{t('compareSelector.screen1') + ':'}</span>
                    <select className='flightSelector' value={flightSelected1.id} onChange={this.changeSelectFlight.bind(null, 1)}>
                      {options}
                    </select>
                  </Fragment>
                </div>
                <div className='compareTableScreen'>
                  <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                      <tr className='tableRowHeader'>
                        <th className='tableCell'><CheckBox onClick={this.selectAll.bind(null, 1)} isSelected={this.state.selectedTable1.length === this.state.totalTable1.length && this.state.selectedTable1.length > 0} all={true}/></th>
                        <th className='tableCell'>{t('list.fotograms')}
                          <span className='sortButton' onClick={this.sort}>
                            <Icon size={'12px'} icon={sort} />
                          </span>
                        </th>
                        <th className='tableCell'>{t('list.actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      { table1 }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='compareTableContainer'>
                <div className='compareTableScreenSelector'>
                  <Fragment>
                    <span>{t('compareSelector.screen2') + ':'}</span>
                    <select className='flightSelector' value={flightSelected2.id} onChange={this.changeSelectFlight.bind(null, 2)}>
                      {options}
                    </select>
                  </Fragment>
                </div>
                <div className='compareTableScreen'>
                  <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                      <tr className='tableRowHeader'>
                        <th className='tableCell'><CheckBox onClick={this.selectAll.bind(null, 2)} isSelected={this.state.selectedTable2.length === this.state.totalTable2.length && this.state.selectedTable2.length > 0} all={true}/></th>
                        <th className='tableCell'>{t('list.fotograms')}
                          <span className='sortButton' onClick={this.sort}>
                            <Icon size={'12px'} icon={sort} />
                          </span>
                        </th>
                        <th className='tableCell'>{t('list.actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      { table2 }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
        <div className='compareSelectionButtonsContainer row'>
          <Button text={t('compareSelector.compare')} active={this.state.selectedTable1.length > 0 && this.state.selectedTable2.length > 0} onClick={this.openComparator} />
        </div>
        { mapOpened ?
          <div className='compareSelectionMapContainer'>
            <div id='compareSelectionMap'></div>
          </div>
        : null }
        { mapOpened ?
          <br/>
        : null}
      </div>
    );
  }
}

export default translate()(CompareSelector);
