import React, { Component } from 'react';
import Draggabilly from 'draggabilly';
import { translate } from 'react-i18next';
import { store } from 'react-notifications-component';
import { close } from 'react-icons-kit/fa/close';
import { pencil } from 'react-icons-kit/fa/pencil';
import { arrowUp } from 'react-icons-kit/fa/arrowUp';
import { arrowDown } from 'react-icons-kit/fa/arrowDown';

import Button from 'components/Button/Button';
import CustomModal from 'components/CustomModal/CustomModal';
import ActionButton from 'components/ActionButton/ActionButton';
import BackgroundLayerForm from 'components/BackgroundLayerForm/BackgroundLayerForm';

import ApiService from 'services/ApiService';

import './BackgroundLayers.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class BackgroundLayers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      selected: undefined,
      data: [],
    };
  }

  componentWillMount() {
    this.loadBackgroundLayers(true);
  }

  loadBackgroundLayers(startBlock) {
    const { t } = this.props;
    const _this = this;
    if (startBlock === true) {
      _this.props.block();
    }

    ApiService.getRequest(API_REST + 'api/backgroundlayer')
    .then((response) => {
      _this.setState({ data: response }, () => {
        _this.props.unblock();
      });
    }).catch((error) => {
      _this.props.unblock();
      store.addNotification({
        title: t('backgroundlayers.error'),
        message: t('backgroundlayers.load_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  handleOpen = (selected) => {
    this.setState({ opened: true, selected: selected }, () => {
      setTimeout(() => {
        if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
          document.querySelector('#backgroundlayerName').focus();
          const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
            containment: 'body',
            handle: '.customModalTitle',
          });

          draggable.enable();
        }
      }, 50);
    });
  }

  handleClose = () => {
    this.setState({ opened: false, selected: undefined });
  }

  move = (direction, bl) => {
    const _this = this;
    const { t } = _this.props;
    const { data } = _this.state;
    const modified = JSON.parse(JSON.stringify(bl));
    let affected = undefined;
    if (direction === 'up') {
      const affectedFiltered = data.filter((b) => {
        return b.sort === (bl.sort - 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort - 1;
        affected.sort = affected.sort + 1;
      }
    } else if (direction === 'down'){
      const affectedFiltered = data.filter((b) => {
        return b.sort === (bl.sort + 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort + 1;
        affected.sort = affected.sort - 1;
      }
    }

    if (affected !== undefined) {
      _this.props.block();
      Promise.all([
        ApiService.putRequest(API_REST + 'api/backgroundlayer/' + modified.id, modified),
        ApiService.putRequest(API_REST + 'api/backgroundlayer/' + affected.id, affected),
      ]).then((responses) => {
        _this.loadBackgroundLayers(false);
      }).catch((errors) => {
        store.addNotification({
          title: t('backgroundlayers.error'),
          message: t('backgroundlayers.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  saveData = (data) => {
    const { t } = this.props;
    const _this = this;
    const newData = JSON.parse(JSON.stringify(data));
    _this.props.block();
    if (newData.id !== undefined) {
      ApiService.putRequest(API_REST + 'api/backgroundlayer/' + newData.id, newData)
      .then((response) => {
        _this.handleClose();
        _this.loadBackgroundLayers(false);
        store.addNotification({
          title: t('backgroundlayers.saved'),
          message: t('backgroundlayers.changes_saved'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('backgroundlayers.error'),
          message: t('backgroundlayers.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      newData.sort = _this.getMaxSort() + 1;
      ApiService.postRequest(API_REST + 'api/backgroundlayer/', newData)
      .then((response) => {
        _this.handleClose();
        _this.loadBackgroundLayers(false);
        store.addNotification({
          title: t('backgroundlayers.saved'),
          message: t('backgroundlayers.created'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('backgroundlayers.error'),
          message: t('backgroundlayers.save_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  remove = (data) => {
    const { t } = this.props;
    const _this = this;
    _this.props.block();
    ApiService.deleteRequest(API_REST + 'api/backgroundlayer/' + data.id)
    .then((response) => {
      _this.loadBackgroundLayers(false);
      store.addNotification({
        title: t('backgroundlayers.saved'),
        message: t('backgroundlayers.removed'),
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 1500,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }).catch((error) => {
      store.addNotification({
        title: t('backgroundlayers.error'),
        message: t('backgroundlayers.delete_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  getMaxSort = () => {
    let sort = 0;
    this.state.data.forEach((d) => {
      if (d.sort > sort) {
        sort = d.sort;
      }
    });

    return sort;
  }

  render() {
    const { t } = this.props;
    const { opened, selected, data } = this.state;
    const rows = [];
    data.forEach((d) => {
      rows.push(
        <tr key={'backgroundLayer' + d.id}>
          <td><img src={d.image} alt='thumbnail'/></td>
          <td style={{ textAlign: 'center' }}>{d.type}</td>
          <td>{d.title}</td>
          <td>{d.titleEn}</td>
          <td>{d.name}</td>
          <td>{d.url}</td>
          <td style={{ textAlign: 'center' }}>{d.format || '-'}</td>
          <td style={{ textAlign: 'center' }}>{d.tilegridMaxZoom || '-'}</td>
          <td>
            <div>
              { d.sort > 1 ?
                <ActionButton id={'backgroundlayerUp' + d.id} icon={arrowUp} size={'18px'} color={'#fda823'} tooltip={t('backgroundlayers.up')} onClick={this.move.bind(this, 'up', d)} />
              : null }
              { d.sort < this.getMaxSort() ?
                <ActionButton id={'backgroundlayerDown' + d.id} icon={arrowDown} size={'18px'} color={'#fda823'} tooltip={t('backgroundlayers.down')} onClick={this.move.bind(this, 'down', d)} />
              : null }
              <ActionButton id={'backgroundlayerEdit' + d.id} icon={pencil} size={'18px'} color={'#fda823'} tooltip={t('backgroundlayers.edit')} onClick={this.handleOpen.bind(this, d)} />
              <ActionButton id={'backgroundlayerDelete' + d.id} icon={close} size={'18px'} color={'#ff0000'} tooltip={t('backgroundlayers.remove')} onClick={this.remove.bind(this, d)} />
            </div>
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <div className='admin-content'>
          <p className='admin-content-title'>{t('backgroundlayers.backgroundlayers')}</p>
          { data.length > 0 ?
            <div className='admin-backgroundlayers'>
              <div>
                <table>
                  <thead>
                    <tr>
                      <td>{t('backgroundlayers.thumbnail')}</td>
                      <td>{t('backgroundlayers.type')}</td>
                      <td style={{ minWidth: '75px'}}>{t('backgroundlayers.title')}</td>
                      <td style={{ minWidth: '75px'}}>{t('backgroundlayers.titleEn')}</td>
                      <td>{t('backgroundlayers.name')}</td>
                      <td>URL</td>
                      <td>{t('backgroundlayers.imageFormat')}</td>
                      <td>{t('backgroundlayers.maxZoom')}</td>
                      <td>{t('backgroundlayers.actions')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    { rows }
                  </tbody>
                </table>
              </div>
            </div>
          : null }
          <div className='admin-menu-button-container2'>
            <Button text={t('backgroundlayers.addLayer')} active={true} onClick={this.handleOpen.bind(this, undefined)} />
          </div>
        </div>
        <CustomModal open={opened} onClose={this.handleClose} title={t('backgroundlayers.layerData')}>
          <BackgroundLayerForm data={selected} saveData={this.saveData} />
        </CustomModal>
      </React.Fragment>
    );
  }
}

export default translate()(BackgroundLayers);
