export default class Utils {

  static sortArrayByProperty(array, property, asc, property2) {
    let res = array.sort((a, b) => {
      const aValue = (a[property] !== null && a[property] !== undefined) ? a[property] : a[property2];
      const bValue = (b[property] !== null && b[property] !== undefined) ? b[property] : b[property2];
      return this.compareStrings(aValue, bValue, property2 !== undefined);
    });

    if (!asc) {
      res = res.reverse();
    }

    return res;
  }

  static sortArrayByPropertyInsideObject(array, object, property, asc) {
    const _this = this;
    let listElements = array;
    if (array && array.list) {
      listElements = array.list;
    }

    let res = listElements.sort((a, b) => {
      let compA = a[object] !== undefined ? a[object][property] : '-';
      let compB = b[object] !== undefined ? b[object][property] : '-';
      return _this.compareStrings(compA, compB) !== 0 ?
        _this.compareStrings(compA, compB) : _this.compareStrings(a[property], b[property]);
    });

    if (!asc) {
      res = res.reverse();
    }

    return res;
  }

  static arrayRemoveObjectWithProperty(array, property, value) {
    let res = [];
    if (array !== undefined) {
      res = array.filter(item => item[property] !== value);
    }

    return res;
  }

  static arrayContainsObjectWithProperty(array, property, value) {
    let res = false;
    if (array !== undefined) {
      res = array.filter(item => item[property] === value).length > 0;
    }

    return res;
  }

  static arrayReturnObjectWithProperty(array, property, value) {
    let listElements = array;
    if (array && array.hasOwnProperty('list')) {
      listElements = array.list;
    }

    return listElements.filter(item => item[property] === value)[0];
  }

  static simplifyArrayByProperty(array, property) {
    let res = [];
    if (array !== undefined) {
      for (let elem of array) {
        res.push(elem[property]);
      }
    }

    return res;
  }

  static simplifyArrayByPropertyInObject(array, object, property) {
    let res = [];
    let listElements = [];
    if (array && array.list) {
      listElements = array.list;
    } else if (array && array.length) {
      listElements = array;
    }

    for (let elem of listElements) {
      res.push(elem[object][property]);
    }

    return res;
  }

  static isEmpty(string) {
    let res = string === undefined || string === '';
    if (!res) {
      string = '' + string;
      res = res || string.trim().length === 0;
    }

    return res;
  }

  static compareStrings(a, b, checkNumbers) {
    if (!checkNumbers && (isNaN(a) || isNaN(b))) {
      a = this.normalize(a);
      b = this.normalize(b);
    }

    if (checkNumbers && !isNaN(parseInt(a, 10)) && !isNaN(parseInt(b, 10))) {
      a = parseInt(a, 10);
      b = parseInt(b, 10);
    }

    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  static stringContainsString(a, b) {
    if (isNaN(a) || isNaN(b)) {
      a = this.normalize(a);
      b = this.normalize(b);
    }

    return a.indexOf(b) > -1;
  }

  static normalize(a) {
    return (a + '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  static formatDate(date) {
    const text = date.toLocaleDateString('es-ES').split('/');
    const res = `${text[0].length > 1 ? text[0] : '0' + text[0]}/${text[1].length > 1 ? text[1] : '0' + text[1]}/${text[2]}`;
    return `${res} ${('' + date.getHours()).length > 1 ? date.getHours() : '0' + date.getHours()}:${('' + date.getMinutes()).length > 1 ? date.getMinutes() : '0' + date.getMinutes()}`;
  }

  static formatDatePrint(date) {
    const text = date.toLocaleDateString('es-ES').split('/');
    return `${text[0].length > 1 ? text[0] : '0' + text[0]}/${text[1].length > 1 ? text[1] : '0' + text[1]}/${text[2]}`;
  }

  static arrayContainsString(array, text) {
    const contains = array.filter((elem) => {
      return this.stringContainsString(elem, text);
    });

    return contains.length > 0;
  }

  static getFile(uri, not_headers) {
    if (not_headers === false) {
      return fetch(uri).then((response) => {
        return response.json();
      });
    } else {
      return fetch(uri, {
        headers: {
          //'Content-Encoding': 'gzip',
          'Accept-Encoding': 'gzip, deflate',
        },
      }).then((response) => {
        return response.json();
      });
    }
  }

  static fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  static checkFileExtension(file, extension) {
    return file.name.indexOf(extension) !== -1;
  }

  static copyObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static getFormattedNumber(number, digits) {
    let res = '' + number;
    while (res.length < digits) {
      res = '0' + res;
    }

    return res;
  }

}
