import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { store } from 'react-notifications-component';

import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';

import ApiService from 'services/ApiService';

import './Others.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class Others extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      hasChanges: false,
    };
  }

  componentWillMount() {
    const _this = this;
    _this.props.block();
    ApiService.getRequest(API_REST + 'api/configurations')
    .then((response) => {
      _this.setState({ data: response }, () => {
        _this.props.unblock();
      });
    }).catch((error) => {
      _this.props.unblock();
      console.error(error);
    });
  }

  changeValue = (attribute) => {
    const newData = this.state.data;
    newData[attribute] = !newData[attribute];
    this.setState({ data: newData, hasChanges: true });
  }

  save = () => {
    const _this = this;
    const { block, unblock, t } = this.props;
    const obj = this.state.data;
    block();
    ApiService.postRequest(API_REST + 'api/configurations', obj)
    .then((response) => {
      _this.setState({ hasChanges: false });
      unblock();
      store.addNotification({
        title: t('others.saved'),
        message: t('others.changes_saved'),
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 1500,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }).catch((error) => {
      unblock();
      store.addNotification({
        title: t('others.error'),
        message: t('others.save_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  render() {
    const { t } = this.props;
    const { data, hasChanges } = this.state;

    return (
      <div className='admin-content'>
        <p className='admin-content-title'>{t('others.others')}</p>
        { data !== undefined ?
          <React.Fragment>
            <div className='admin-others-container'>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={2}>{t('others.searchTools')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t('others.searchByView')}</td>
                      <td>
                        <CheckBox id={'searchByView'} onClick={this.changeValue.bind(this, 'searchByView')} isSelected={data.searchByView} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.searchByDraw')}</td>
                      <td>
                        <CheckBox id={'searchByDraw'} onClick={this.changeValue.bind(this, 'searchByDraw')} isSelected={data.searchByDraw} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.searchByMTN')}</td>
                      <td>
                        <CheckBox id={'searchByMTN'} onClick={this.changeValue.bind(this, 'searchByMtn')} isSelected={data.searchByMtn} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.searchByFile')}</td>
                      <td>
                        <CheckBox id={'searchByFile'} onClick={this.changeValue.bind(this, 'searchByFile')} isSelected={data.searchByFile} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={2}>{t('others.photogramActions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t('others.photogramViewHide')}</td>
                      <td>
                        <CheckBox id={'photogramViewHide'} onClick={this.changeValue.bind(this, 'photogramViewHide')} isSelected={data.photogramViewHide} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.photogramInfo')}</td>
                      <td>
                        <CheckBox id={'photogramInfo'} onClick={this.changeValue.bind(this, 'photogramInfo')} isSelected={data.photogramInfo} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.photogramZoom')}</td>
                      <td>
                        <CheckBox id={'photogramZoom'} onClick={this.changeValue.bind(this, 'photogramZoom')} isSelected={data.photogramZoom} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.photogramCertify')}</td>
                      <td>
                        <CheckBox id={'photogramCertify'} onClick={this.changeValue.bind(this, 'photogramCertify')} isSelected={data.photogramCertify} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='admin-others-container'>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={4}>{t('others.mapTools')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t('others.mousesrs')}</td>
                      <td>
                        <CheckBox id={'mousesrs'} onClick={this.changeValue.bind(this, 'mousesrs')} isSelected={data.mousesrs} />
                      </td>
                      <td>{t('others.infocoordinates')}</td>
                      <td>
                        <CheckBox id={'infocoordinates'} onClick={this.changeValue.bind(this, 'infocoordinates')} isSelected={data.infocoordinates} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.scale')}</td>
                      <td>
                        <CheckBox id={'scale'} onClick={this.changeValue.bind(this, 'scale')} isSelected={data.scale} />
                      </td>
                      <td>{t('others.printermap')}</td>
                      <td>
                        <CheckBox id={'printermap'} onClick={this.changeValue.bind(this, 'printermap')} isSelected={data.printermap} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.sharemap')}</td>
                      <td>
                        <CheckBox id={'sharemap'} onClick={this.changeValue.bind(this, 'sharemap')} isSelected={data.sharemap} />
                      </td>
                      <td>{t('others.measurebar')}</td>
                      <td>
                        <CheckBox id={'measurebar'} onClick={this.changeValue.bind(this, 'measurebar')} isSelected={data.measurebar} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.calendar')}</td>
                      <td>
                        <CheckBox id={'calendar'} onClick={this.changeValue.bind(this, 'calendar')} isSelected={data.calendar} />
                      </td>
                      <td>{t('others.zoompanel')}</td>
                      <td>
                        <CheckBox id={'zoompanel'} onClick={this.changeValue.bind(this, 'zoompanel')} isSelected={data.zoompanel} />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('others.help')}</td>
                      <td>
                        <CheckBox id={'help'} onClick={this.changeValue.bind(this, 'help')} isSelected={data.help} />
                      </td>
                      <td>{t('others.ignsearch')}</td>
                      <td>
                        <CheckBox id={'ignsearch'} onClick={this.changeValue.bind(this, 'ignsearch')} isSelected={data.ignsearch} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='admin-menu-button-container2'>
              <Button text={t('others.saveChanges')} active={hasChanges} onClick={this.save} />
            </div>
          </React.Fragment>
        : null }
      </div>
    );
  }
}

export default translate()(Others);
