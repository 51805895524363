import React, { Component } from 'react';
import Draggabilly from 'draggabilly';
import { translate } from 'react-i18next';
import { store } from 'react-notifications-component';
import { close } from 'react-icons-kit/fa/close';
import { pencil } from 'react-icons-kit/fa/pencil';

import Button from 'components/Button/Button';
import CustomModal from 'components/CustomModal/CustomModal';
import ActionButton from 'components/ActionButton/ActionButton';
import RSSConfigForm from 'components/RSSConfigForm/RSSConfigForm';

import Utils from 'utils/Utils';
import ApiService from 'services/ApiService';

import './RSSConfig.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class RSSConfig extends Component {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      selected: undefined,
      data: [],
    };
  }

  componentWillMount() {
    this.loadRSS(true);
  }

  loadRSS(startBlock) {
    const { t } = this.props;
    const _this = this;
    if (startBlock === true) {
      _this.props.block();
    }

    ApiService.getRequest(API_REST + 'api/new')
    .then((response) => {
      _this.setState({ data: response }, () => {
        _this.props.unblock();
      });
    }).catch((error) => {
      _this.props.unblock();
      store.addNotification({
        title: t('rssconfig.error'),
        message: t('rssconfig.load_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  handleOpen = (selected) => {
    this.setState({ opened: true, selected: selected }, () => {
      setTimeout(() => {
        if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
          document.querySelector('#rssConfigFormTitle').focus();
          const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
            containment: 'body',
            handle: '.customModalTitle',
          });

          draggable.enable();
        }
      }, 50);
    });
  }

  handleClose = () => {
    this.setState({ opened: false, selected: undefined });
  }

  saveData = (data) => {
    const { t } = this.props;
    const _this = this;
    const newData = JSON.parse(JSON.stringify(data));
    _this.props.block();
    if (newData.id !== undefined) {
      ApiService.putRequest(API_REST + 'api/new/' + newData.id, newData)
      .then((response) => {
        _this.handleClose();
        _this.loadRSS(false);
        store.addNotification({
          title: t('rssconfig.saved'),
          message: t('rssconfig.changes_saved'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('rssconfig.error'),
          message: t('rssconfig.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      ApiService.postRequest(API_REST + 'api/new/', newData)
      .then((response) => {
        _this.handleClose();
        _this.loadRSS(false);
        store.addNotification({
          title: t('rssconfig.saved'),
          message: t('rssconfig.created'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('rssconfig.error'),
          message: t('rssconfig.save_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  remove = (data) => {
    const { t } = this.props;
    const _this = this;
    _this.props.block();
    ApiService.deleteRequest(API_REST + 'api/new/' + data.id)
    .then((response) => {
      _this.loadRSS(false);
      store.addNotification({
        title: t('rssconfig.saved'),
        message: t('rssconfig.removed'),
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 1500,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }).catch((error) => {
      store.addNotification({
        title: t('rssconfig.error'),
        message: t('rssconfig.delete_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  render() {
    const { t } = this.props;
    const { opened, selected, data } = this.state;
    const rows = [];
    data.forEach((d) => {
      const rawDate = new Date(d.pubDate);
      rows.push(
        <tr key={'rssconfig' + d.id}>
          <td>{d.title}</td>
          <td><div dangerouslySetInnerHTML={{ __html: d.description }} style={{ display: 'block' }}/></td>
          <td style={{ minWidth: '115px', textAlign: 'center' }}>{Utils.formatDate(rawDate)}</td>
          <td>
            <div>
              <ActionButton id={'rssconfigEdit' + d.id} icon={pencil} size={'18px'} color={'#fda823'} tooltip={t('rssconfig.edit')} onClick={this.handleOpen.bind(this, d)} />
              <ActionButton id={'rssconfigDelete' + d.id} icon={close} size={'18px'} color={'#ff0000'} tooltip={t('rssconfig.remove')} onClick={this.remove.bind(this, d)} />
            </div>
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <div className='admin-content'>
          <p className='admin-content-title'>RSS</p>
          { data.length > 0 ?
            <div className='admin-rssconfig'>
              <div>
                <table>
                  <thead>
                    <tr>
                      <td>{t('rssconfig.title')}</td>
                      <td>{t('rssconfig.description')}</td>
                      <td>{t('rssconfig.pubDate')}</td>
                      <td>{t('rssconfig.actions')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    { rows }
                  </tbody>
                </table>
              </div>
            </div>
          : null }
          <div className='admin-menu-button-container2'>
            <Button text={t('rssconfig.addNew')} active={true} onClick={this.handleOpen.bind(this, undefined)} />
          </div>
        </div>
        <CustomModal open={opened} onClose={this.handleClose} title={t('rssconfig.newData')}>
          <RSSConfigForm data={selected} saveData={this.saveData} />
        </CustomModal>
      </React.Fragment>
    );
  }
}

export default translate()(RSSConfig);
