import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { CKEditor } from 'ckeditor4-react';
import { store } from 'react-notifications-component';

import Button from 'components/Button/Button';

import ApiService from 'services/ApiService';
import LocalStorage from 'utils/LocalStorage';

import './Help.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class Help extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fullConfig: undefined,
      spanish: '',
      english: '',
      hasChanges: false,
    };
  }

  componentWillMount() {
    const _this = this;
    _this.props.block();
    ApiService.getRequest(API_REST + 'api/help')
    .then((response) => {
      _this.setState({ fullConfig: response, spanish: response.text, english: response.textEn }, () => {
        _this.props.unblock();
      });
    }).catch((error) => {
      _this.props.unblock();
      console.error(error);
    });
  }

  setValue = (lang, evt) => {
    const newState = this.state;
    newState.hasChanges = true;
    newState[lang] = evt.editor.getData();
    this.setState(newState);
  }

  save = () => {
    const _this = this;
    const { block, unblock, t } = this.props;
    const obj = this.state.fullConfig;
    obj.text = this.state.spanish;
    obj.textEn = this.state.english;
    block();
    ApiService.postRequest(API_REST + 'api/help', obj)
    .then((response) => {
      _this.setState({ hasChanges: false });
      unblock();
      store.addNotification({
        title: t('help.saved'),
        message: t('help.changes_saved'),
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 1500,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }).catch((error) => {
      unblock();
      store.addNotification({
        title: t('help.error'),
        message: t('help.save_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  render() {
    const { t } = this.props;
    const { spanish, english, hasChanges } = this.state;
    const config = {
      allowedContent: true,
      language: LocalStorage.getString('language') || 'es',
      resize_enabled: false,
      scayt_sLang: 'es_ES',
      extraPlugins: 'colorbutton,colordialog,tabletools',
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'align', 'bidi', 'blocks', 'paragraph' ] },
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'others', groups: [ 'others' ] },
      ],
      removeButtons: 'Paste,PasteText,PasteFromWord,Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,HiddenField,Select,Textarea,TextField,Radio,Checkbox,Form,Superscript,Subscript,CopyFormatting,Outdent,Indent,Language,BidiRtl,BidiLtr,Anchor,Flash,Smiley,PageBreak,Iframe,About,Blockquote',
    };

    return (
      <div className='admin-content'>
        <p className='admin-content-title'>{t('help.help')}</p>
        { spanish !== '' ?
          <div className='help-editor-container'>
            <p>{t('help.textEs')}</p>
            <CKEditor config={config} initData={spanish} onChange={this.setValue.bind(this, 'spanish')} />
          </div>
        : null }
        { english !== '' ?
          <div className='help-editor-container'>
            <p>{t('help.textEn')}</p>
            <CKEditor config={config} initData={english} onChange={this.setValue.bind(this, 'english')} />
          </div>
        : null }
        <div className='admin-menu-button-container2 help'>
          <Button text={t('help.saveChanges')} active={hasChanges} onClick={this.save} />
        </div>
      </div>
    );
  }
}

export default translate()(Help);
