import React, { Component, Fragment } from 'react';
import Draggabilly from 'draggabilly';
import { translate } from 'react-i18next';
import { info } from 'react-icons-kit/fa/info';
import { map } from 'react-icons-kit/ionicons/map';
import { sort } from 'react-icons-kit/fa/sort';
import { Icon } from 'react-icons-kit';
import ReactTooltip from 'react-tooltip';

import Info from 'components/Info/Info';
import Pagination from 'components/Pagination/Pagination';
import CustomModal from 'components/CustomModal/CustomModal';
import ActionButton from 'components/ActionButton/ActionButton';

import ApiService from 'services/ApiService';
import Utils from 'utils/Utils';

import './Results.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      activePage: 0,
      rowLimit: 10,
      open: false,
      modalOption: undefined,
      selected: {},
      order: 'año',
      asc: true,
      searchCollapsed: false,
      mobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
      portrait: this.isPortrait(),
    };
  }

  componentWillMount = () => {
    this.sortResults(this.props.results, this.state.order);
  }

  sortResults = (results, order, order2) => {
    const asc = this.state.order === order ? !this.state.asc : true;
    const newResults = Utils.sortArrayByProperty(results, order, asc, order2);
    this.setState({ results: newResults, activePage: 0, order: order, asc: asc });
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.state.results !== nextProps.results) {
      this.setState({ asc: false }, () => {
        this.sortResults(nextProps.results, 'año');
      });
    }
  }

  componentDidMount() {
    if(this.state.mobile) {
      this.addOrientationEvent();
    }
  }

  toogleHiddenParent = () => {
    const filtersContainer = document.querySelector('.search-menu.nav-wrapper>.filtersContainer>.filtersTitleContainer');
    filtersContainer.click();
  }

  toogleCollapsedParent = () => {
    const inv = !this.state.searchCollapsed;
    this.setState({searchCollapsed: inv}, () => {
      const filtersCollapsedBtn = document.querySelector('#collapseFiltersBtn');
      filtersCollapsedBtn.click();
    });
  }

  addOrientationEvent = () => {
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    mediaQueryList.addEventListener('change', this.handleOrientationChange);
  }

  isPortrait = () => {
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    return mediaQueryList.matches
  }

  handleOrientationChange = (event) => {
    this.setState({portrait: event.matches}, () => {
      if (!event.matches && this.state.searchCollapsed) {
        this.toogleCollapsedParent();
      }
    });
  }

  changePage = (newPage) => {
    this.setState({ activePage: newPage });
  }

  onCloseModal = () => {
    this.setState({ open: false, modalOption: undefined, selected: {} });
  };

  showInfoPopup = (codVuelo, tabOption) => {
    const _this = this;
    const selected = this.state.results.filter(r => r.codVuelo === codVuelo);
    if (selected.length > 0 && selected[0].external) {
      this.setState({ open: true, modalOption: tabOption, selected: selected[0] }, () => {
        setTimeout(() => {
          if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
            const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
              containment: 'body',
              handle: '.customModalTitle',
            });

            draggable.enable();
          }
        }, 500);
      });
    } else {
      this.props.block();
      ApiService.getRequest(API_REST + 'api/flight/get/' + codVuelo)
      .then((response) => {
        _this.props.unblock();
        _this.setState({ open: true, modalOption: tabOption, selected: response }, () => {
          setTimeout(() => {
            if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
              const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
                containment: 'body',
                handle: '.customModalTitle',
              });

              draggable.enable();
            }
          }, 500);
        });
      }).catch((error) => {
        _this.props.unblock();
        console.error(error);
      });
    }
  }

  render() {
    const { t } = this.props;
    const { activePage, rowLimit, open, modalOption, results, mobile, portrait, searchCollapsed } = this.state;
    let renderResults = [];
    let limit = rowLimit + rowLimit * activePage;
    if (limit > results.length) {
      limit = results.length;
    }

    if (results.length > 0) {
      for (let i = (rowLimit * activePage); i < limit; i++) {
        const r = results[i];
        renderResults.push(
          <tr className='tableRow' key={'flight' + r.codVuelo}>
            <td className='tableRowCell center nomVuelo'>{r.codVuelo || '-'}</td>
            <td className='tableRowCell left nomVuelo' data-tip data-for={r.nomVuelo + 'tooltipName'}>
              {r.nomVuelo || '-'}
              <ReactTooltip id={r.nomVuelo + 'tooltipName'} effect='solid' place='right'>
                <div>
                  <b>{r.nomVuelo}</b>
                </div>
              </ReactTooltip>
            </td>
            <td className='tableRowCell center'>{r.tipo === 'digital' ? t('results.digital') : t('results.analogic')}</td>
            <td className='tableRowCell center year' data-tip data-for={r.nomVuelo + 'tooltipYear'}>
              {r['año'] || '-'}
              <ReactTooltip id={r.nomVuelo + 'tooltipYear'} effect='solid' place='right'>
                <div>
                  <b>{r['año']}</b>
                </div>
              </ReactTooltip>
            </td>
            <td className='tableRowCell'>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ActionButton id={'flightInfo' + r.codVuelo} icon={info} size={'18px'}
                  color={'#fda823'} tooltip={t('results.search_data')} onClick={this.showInfoPopup.bind(this, r.codVuelo, 'info')} />
                { !mobile && r.extension !== undefined ?
                  <ActionButton id={'flightExtension' + r.codVuelo} icon={map} size={'18px'}
                    color={'#fda823'} tooltip={t('info.coverage')} onClick={this.showInfoPopup.bind(this, r.codVuelo, 'extension')} />
                : null }
              </div>
            </td>
          </tr>
        );
      }
    }

    return (
      <Fragment>
        <div className='resultsContainer'>
          <div className='resultsTitleContainer row' onClick={mobile && !portrait ? this.toogleHiddenParent : null}>
            <span>{t('results.results') + ' '} ({results.length})</span>
            {mobile && portrait ? 
            <div id='collapseFiltersResultsBtn' className='collapse-button' onClick={this.toogleCollapsedParent} style={{cursor: 'pointer'}}>{searchCollapsed ? t('others.showfilters') : t('others.hidefilters')}</div>
            : null}
          </div>
          { results.length > 0 ?
            <Fragment>
              <p className='resultsCheckAvailability'>*{t('results.check_available')}</p>
              <div className='table row'>
                <div className='tableContainer'>
                  <table>
                    <thead>
                      <tr className='tableRowHeader'>
                        <th className='tableCell' onClick={this.sortResults.bind(null, this.state.results, 'codVuelo')}>
                          {t('info.code')}
                          <span className='sortButton'>
                            <Icon size={'8px'} icon={sort} />
                          </span>
                        </th>
                        <th className='tableCell' onClick={this.sortResults.bind(null, this.state.results, 'nomVuelo')}>
                          {t('results.name')}
                          <span className='sortButton'>
                            <Icon size={'8px'} icon={sort} />
                          </span>
                        </th>
                        <th className='tableCell' onClick={this.sortResults.bind(null, this.state.results, 'tipo')}>
                          {t('results.type')}
                          <span className='sortButton'>
                            <Icon size={'8px'} icon={sort} />
                          </span>
                        </th>
                        <th className='tableCell' onClick={this.sortResults.bind(null, this.state.results, 'año')}>
                          {t('results.year')}
                          <span className='sortButton'>
                            <Icon size={'8px'} icon={sort} />
                          </span>
                        </th>
                        <th className='tableCell'>{t('results.actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      { renderResults }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='paginationContainer row' style={{ marginBottom: '10px' }}>
                <Pagination activePage={activePage} total={results.length}
                  rows={rowLimit} handlePageChange={this.changePage} />
              </div>
            </Fragment>
          : null }
          { modalOption !== undefined ?
            <CustomModal open={open} onClose={this.onCloseModal} title={t('results.flight_info')}>
              <Info variant='flight' selected={this.state.selected} tabOption={modalOption} />
            </CustomModal>
          : null }
        </div>
      </Fragment>
    );
  }
}

export default translate()(Results);
