import React, { Component } from 'react';
import { translate } from 'react-i18next';

import Button from 'components/Button/Button';

import './MosaicForm.css';

class MosaicForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data !== undefined ? JSON.parse(JSON.stringify(this.props.data)) : { version: '1.3.0' },
      errorMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.data !== undefined) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 100);
    }
  }

  save = () => {
    let newData = this.state.data;
    let emptyFields = false;
    const category = document.querySelector('#mosaicformGroup');
    const title = document.querySelector('#mosaicformTitle');
    const titleEn = document.querySelector('#mosaicformTitleEn');
    const name = document.querySelector('#mosaicformName');
    const url = document.querySelector('#mosaicformUrl');
    const version = document.querySelector('#mosaicformVersion');
    const description = document.querySelector('#mosaicformDescription');
    const descriptionEn = document.querySelector('#mosaicformDescriptionEn');
    newData.title = title.value.trim();
    newData.titleEn = titleEn.value.trim();
    newData.name = name.value.trim();
    newData.url = url.value.trim();
    newData.version = version.value.trim();
    newData.description = description.value.trim();
    newData.descriptionEn = descriptionEn.value.trim();
    if (category !== null) {
      newData.category = parseInt(category.value);
    }

    if (newData.title === '') {
      emptyFields = true;
      title.style.border = '2px solid red';
    } else {
      title.style.border = '1px solid black';
    }

    if (newData.titleEn === '') {
      emptyFields = true;
      titleEn.style.border = '2px solid red';
    } else {
      titleEn.style.border = '1px solid black';
    }

    if (newData.name === '') {
      emptyFields = true;
      name.style.border = '2px solid red';
    } else {
      name.style.border = '1px solid black';
    }

    if (newData.url === '') {
      emptyFields = true;
      url.style.border = '2px solid red';
    } else {
      url.style.border = '1px solid black';
    }

    if (newData.description === '') {
      emptyFields = true;
      description.style.border = '2px solid red';
    } else {
      description.style.border = '1px solid black';
    }

    if (newData.descriptionEn === '') {
      emptyFields = true;
      descriptionEn.style.border = '2px solid red';
    } else {
      descriptionEn.style.border = '1px solid black';
    }

    if (!emptyFields) {
      this.props.groupsData.forEach((gd) => {
        if (gd.id === newData.category) {
          newData.category_name = gd.name;
        }
      });

      this.setState({ errorMessage: false }, () => {
        this.props.saveData(newData);
      });
    } else {
      this.setState({ errorMessage: true });
    }
  }

  render() {
    const { t, groupsData, lang } = this.props;
    const { data, errorMessage } = this.state;
    const groupRows = [];
    groupsData.forEach((gd) => {
      groupRows.push(<option key={'gd' + gd.id} value={gd.id}>{lang === 'en' ? gd.nameEn : gd.name}</option>)
    });

    return (
      <div className='mosaiclayers-form-container'>
        <div className='mosaiclayers-form'>
          <div>
            <label>{t('mosaicForm.group')}</label>
            <br/>
            { data.category_name !== undefined ?
              <input type='text' disabled defaultValue={data.category_name}/>
            : <select id='mosaicformGroup'>
                { groupRows }
            </select> }
          </div>
          <div>
            <label>{t('mosaicForm.legend') + ' (es)'}</label>
            <br/>
            <input type='text' id='mosaicformTitle' defaultValue={data.title !== undefined ? data.title : ''} />
          </div>
          <div>
            <label>{t('mosaicForm.legend') + ' (en)'}</label>
            <br/>
            <input type='text' id='mosaicformTitleEn' defaultValue={data.titleEn !== undefined ? data.titleEn : ''} />
          </div>
          <div>
            <label>{t('mosaicForm.name')}</label>
            <br/>
            <input type='text' id='mosaicformName' defaultValue={data.name !== undefined ? data.name : ''} />
          </div>
          <div>
            <label>URL</label>
            <br/>
            <input type='text' id='mosaicformUrl' defaultValue={data.url !== undefined ? data.url : ''} />
          </div>
          <div>
            <label>{t('mosaicForm.version')}</label>
            <br/>
              <select id='mosaicformVersion' defaultValue={data.version}>
                <option value='1.1.0'>1.1.0</option>
                <option value='1.1.1'>1.1.1</option>
                <option value='1.3.0'>1.3.0</option>
              </select>
          </div>
          <div>
            <label>{t('mosaicForm.descriptionEs')}</label>
            <br/>
            <textarea id='mosaicformDescription' defaultValue={data.description !== undefined ? data.description : ''}></textarea>
          </div>
          <div>
            <label>{t('mosaicForm.descriptionEn')}</label>
            <br/>
            <textarea id='mosaicformDescriptionEn' defaultValue={data.descriptionEn !== undefined ? data.descriptionEn : ''}></textarea>
          </div>
        </div>
        { errorMessage === true ?
          <div className='mosaiclayers-error'>{t('mosaicForm.error')}</div>
        : null }
        <div className='mosaiclayers-form-button-container'>
          <Button text={t('mosaicForm.save')} active={true} onClick={this.save} />
        </div>
      </div>
    );
  }
}

export default translate()(MosaicForm);
