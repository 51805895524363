import React, { Component } from 'react';
import { translate } from 'react-i18next';

import Button from 'components/Button/Button';

import './MTNSelector.css';

class MTNSelector extends Component {

  enterKey = (evt) => {
    const keyCode = evt.charCode || evt.which;
    if (keyCode === 13) {
      this.props.showSheet();
    }
  }

  render() {
    const { t, getSheet, getSheetValue, getQuarter, mtnSelected, mtnQuarter, showSheet, only50, mobile } = this.props;

    return (
      <React.Fragment>
        <div className='actionsModalTableContainer row'>
          <table>
            { only50 ?
              <tbody>
                <tr>
                  <th>{t('mtnSelector.number_50')}</th>
                </tr>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    <input type='text' id='sheetMTN50' name='sheetMTN50' maxLength={5} onChange={getSheetValue.bind(this)} onKeyPress={this.enterKey.bind(this)} disabled={false} />
                  </td>
                </tr>
              </tbody>
            : <tbody>
              <tr>
                <th>{t('mtnSelector.grid')}</th>
                <th>{t('mtnSelector.number')}</th>
                <th>{t('mtnSelector.quarter')}</th>
              </tr>
              <tr>
                <td><input type='radio' name='sheet' value='MTN50' onChange={getSheet.bind(this)} checked={mtnSelected === 'MTN50'} /> MTN50</td>
                <td><input type='text' id='sheetMTN50' name='sheetMTN50' maxLength={5} onChange={getSheetValue.bind(this)} onKeyPress={this.enterKey.bind(this)} disabled={mtnSelected === 'MTN25'} /></td>
                <td></td>
              </tr>
              <tr>
                <td><input type='radio' name='sheet' value='MTN25' onChange={getSheet.bind(this)} checked={mtnSelected === 'MTN25'} /> MTN25</td>
                <td><input type='text' id='sheetMTN25' name='sheetMTN25' maxLength={4} onChange={getSheetValue.bind(this)} onKeyPress={this.enterKey.bind(this)} disabled={mtnSelected === 'MTN50'} /></td>
                <td>
                  <select id='selectSheetQuarter' value={mtnQuarter} onChange={getQuarter} disabled={mtnSelected === 'MTN50'}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='1/2'>1/2</option>
                    <option value='3/4'>3/4</option>
                  </select>
                </td>
              </tr>
            </tbody> }
          </table>
        </div>
        <div className='actionsModalButtonsContainer row'>
          <Button text={t('mtnSelector.show_sheet')} active={true} onClick={() => showSheet(false)} />
          {mobile ? <Button text={t('filters.search')} active={true} onClick={() => showSheet(true)} /> : null}
        </div>
      </React.Fragment>
    );
  }
}

export default translate()(MTNSelector);
