import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Icon } from 'react-icons-kit';
import { ic_done } from 'react-icons-kit/md/ic_done';
import { ic_close } from 'react-icons-kit/md/ic_close';
import { ic_content_copy } from 'react-icons-kit/md/ic_content_copy';
import { initInfoMap } from 'utils/Visor';
import Utils from 'utils/Utils';

import './Info.css';
import 'react-tabs/style/react-tabs.css';

declare var CONFIGURATIONS;

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = { noExtension: false };
  }

  componentDidMount = () => {
    if (this.props.tabOption === 'extension') {
      this.loadMap(1, this.props.selected.extension);
    }
  }

  loadMap = (index, extension) => {
    if (this.props.variant === 'flight' && index === 1) {
      if (extension !== undefined) {
        setTimeout(() => {
          const geojson = { type: 'FeatureCollection', name: 'Extensión Vuelo' };
          if (typeof extension === 'object') {
            geojson.crs = { type: 'name', properties: { name:'urn:ogc:def:crs:EPSG::4326' }};
          } else {
            geojson.crs = { type: 'name', properties: { name:'urn:ogc:def:crs:EPSG::3857' }};
          }

          const geometry = typeof extension === 'object' ? extension : JSON.parse(extension);
          geojson.features = [{ type: 'Feature', properties: {}, geometry: geometry }];
          initInfoMap(geojson);
        }, 200);
      } else if (this.props.selected.extension_url !== undefined) {
        const url = this.props.selected.extension_url.replace('EPSG:4326', 'EPSG:3857');
        Utils.getFile(url).then((geojson) => {
          setTimeout(() => {
            initInfoMap(geojson);
          }, 200);
        });
      } else {
        this.setState({ noExtension: true });
      }
    }
  }

  copyData = () => {
    const data = this.props.selected || {};
    let text = 'Datos Orientación Externa Fotograma\n';
    text += `Nombre de fichero: ${data.nomFichero}\n`;
    text += `Código: ${data.codFotograma}\n`;
    text += `Fecha: ${data.fechaFotograma}\n`;
    text += `X: ${data.xFotocentroAt}\n`;
    text += `Y: ${data.yFotocentroAt}\n`;
    text += `Z: ${data.zFotocentroAt}\n`;
    text += `ω: ${data.giroWAt}\n`;
    text += `φ: ${data.giroPhiAt}\n`;
    text += `κ: ${data.giroKAt}\n`;
    navigator.clipboard.writeText(text);
  }

  render() {
    const { t, variant, tabOption } = this.props;
    const { noExtension } = this.state;
    const selected = this.props.selected || {};
    const iconSize = '24px';
    let activeTab = 0;

    if (variant === 'extension') {
      return (
        <div id='infoMap'></div>
      );
    } else {
      let tabList = <TabList>
        <Tab>{t('info.fotogram')}</Tab>
        <Tab>{t('info.flight')}</Tab>
        <Tab>{t('info.available_formats')}</Tab>
        { selected.xFotocentroAt !== undefined || selected.yFotocentroAt !== undefined || selected.zFotocentroAt !== undefined || selected.giroWAt !== undefined || selected.giroPhiAt !== undefined || selected.giroKAt !== undefined ?
          <Tab>{t('info.oe')}</Tab>
        : null }
      </TabList>;

      if (variant === 'fotogram') {
        if (selected.tipo !== undefined && (selected.tipo.toLowerCase() === 'analogico' || selected.tipo.toLowerCase() === 'analógico')) {
          tabList = <TabList>
            <Tab>{t('info.fotogram')}</Tab>
            <Tab>{t('info.flight')}</Tab>
          </TabList>;
        }
      } else {
        if (selected.tipo !== undefined && (selected.tipo.toLowerCase() === 'analogico' || selected.tipo.toLowerCase() === 'analógico')) {
          activeTab = tabOption === 'extension' ? 1 : 0;
          tabList = <TabList>
            <Tab>{t('info.flight')}</Tab>
            <Tab>{t('info.coverage')}</Tab>
          </TabList>;
        } else {
          activeTab = tabOption === 'extension' ? 1 : 0;
          tabList = <TabList>
            <Tab>{t('info.flight')}</Tab>
            <Tab>{t('info.coverage')}</Tab>
            <Tab>{t('info.available_formats')}</Tab>
          </TabList>;
        }
      }

      return (
        <div className='infoModalContainer'>
          <Tabs defaultIndex={activeTab} onSelect={index => this.loadMap(index, selected.extension)}>
            { tabList }
            { variant === 'fotogram' ?
              <TabPanel>
                <div className='infoFotogram'>
                  { !Utils.isEmpty(selected.nomFichero) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.file_name')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.nomFichero}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.formato) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.format')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.formato}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.codFotograma) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.code')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.codFotograma}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.pasada) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.past')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.pasada}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.fechaFotograma) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.date')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.fechaFotograma}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.xFotocentro) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.x_coord')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.xFotocentro}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.yFotocentro) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.y_coord')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.yFotocentro}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.epsg) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        EPSG
                      </div>
                      <div className='row attribute-value'>
                        {selected.epsg}
                      </div>
                    </div>
                  : null }

                  { selected.tipo === 'analogico' && !Utils.isEmpty(selected['tamañoPixel']) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.pixel_size')}
                      </div>
                      <div className='row attribute-value'>
                        {selected['tamañoPixel']} {t('info.microns')}
                      </div>
                    </div>
                  : null }
                </div>
              </TabPanel>
            : <TabPanel>
                <div className='infoFlight'>
                  { !Utils.isEmpty(selected.codVuelo) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.code')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.codVuelo}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.nomVuelo) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.name')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.nomVuelo}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.tipo) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.type')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.tipo === 'digital' ? 'Digital' : 'Analógico'}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.descripcion) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.description')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.descripcion}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected['año']) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.date_flight')}
                      </div>
                      <div className='row attribute-value'>
                        {selected['año']}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.observaciones) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.observations')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.observaciones}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.urlInfoAux) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.aux_info')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.urlInfoAux}
                      </div>
                    </div>
                  : null }

                  { selected.tipo === 'analogico' ?
                    <React.Fragment>
                      { !Utils.isEmpty(selected.escala) ?
                        <div className='attributeField'>
                          <div className='row attribute-title'>
                            {t('info.scale')}
                          </div>
                          <div className='row attribute-value'>
                            {selected.escala}
                          </div>
                        </div>
                      : null }

                      { !Utils.isEmpty(selected.recubLong) ?
                        <div className='attributeField'>
                          <div className='row attribute-title'>
                            {t('info.length_cover')}
                          </div>
                          <div className='row attribute-value'>
                            {selected.recubLong}%
                          </div>
                        </div>
                      : null }

                      { !Utils.isEmpty(selected.recubTrans) ?
                        <div className='attributeField'>
                          <div className='row attribute-title'>
                            {t('info.trans_cover')}
                          </div>
                          <div className='row attribute-value'>
                            {selected.recubTrans}%
                          </div>
                        </div>
                      : null }
                    </React.Fragment>
                  : !Utils.isEmpty(selected.gsd) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.pixel_size_terrain')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.gsd} {t('info.centimeters')}
                      </div>
                    </div>
                  : null }
                </div>
              </TabPanel> }
            { variant === 'fotogram' ?
              <TabPanel>
                <div className='infoFlight'>
                  { !Utils.isEmpty(selected.codVuelo) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.code')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.codVuelo}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.nomVuelo) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.name')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.nomVuelo}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.tipo) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.type')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.tipo === 'digital' ? 'Digital' : 'Analógico'}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.descripcion) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.description')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.descripcion}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.observaciones) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.observations')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.observaciones}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.urlInfoAux) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.aux_info')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.urlInfoAux}
                      </div>
                    </div>
                  : null }

                  { selected.tipo === 'analogico' ?
                    <React.Fragment>
                      { !Utils.isEmpty(selected.escala) ?
                        <div className='attributeField'>
                          <div className='row attribute-title'>
                            {t('info.scale')}
                          </div>
                          <div className='row attribute-value'>
                            {selected.escala}
                          </div>
                        </div>
                      : null }

                      { !Utils.isEmpty(selected.recubLong) ?
                        <div className='attributeField'>
                          <div className='row attribute-title'>
                            {t('info.length_cover')}
                          </div>
                          <div className='row attribute-value'>
                            {selected.recubLong}%
                          </div>
                        </div>
                      : null }

                      { !Utils.isEmpty(selected.recubTrans) ?
                        <div className='attributeField'>
                          <div className='row attribute-title'>
                            {t('info.trans_cover')}
                          </div>
                          <div className='row attribute-value'>
                            {selected.recubTrans}%
                          </div>
                        </div>
                      : null }

                    </React.Fragment>
                  : !Utils.isEmpty(selected.gsd) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        {t('info.pixel_size_terrain')}
                      </div>
                      <div className='row attribute-value'>
                        {selected.gsd} {t('info.centimeters')}
                      </div>
                    </div>
                  : null }
                </div>
              </TabPanel>
            : null }
            { variant === 'flight' ?
              <TabPanel>
                { noExtension === true ?
                  <div className='flight-no-extension'>
                    <p>{t('info.no_extension')}</p>
                  </div>
                : <div id='infoMap'></div> }
              </TabPanel>
            : null }
            { selected.tipo === 'digital' ?
              <TabPanel>
                <div className='infoFormats row'>
                  <table className='info-formats-table'>
                    <thead>
                      <tr>
                        <td></td>
                        <td>{t('info.photos')}</td>
                        <td>{t('info.ortophotos')}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{t('info.tiff_rgb')}</td>
                        <td>{selected.fotoTiffRgb === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoTiffRgb === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoTiffRgb === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoTiffRgb === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                      <tr>
                        <td>{t('info.tiff_rgbi')}</td>
                        <td>{selected.fotoTiffRgbi === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoTiffRgbi === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoTiffRgbi === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoTiffRgbi === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                      <tr>
                        <td>{t('info.tiff_ir')}</td>
                        <td>{selected.fotoTiffIr === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoTiffIr === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoTiffIr === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoTiffIr === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                      <tr>
                        <td>{t('info.tiff_irg')}</td>
                        <td>{selected.fotoTiffIrg === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoTiffIrg === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoTiffIrg === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoTiffIrg === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                      <tr>
                        <td>{t('info.tiff_pan')}</td>
                        <td>{selected.fotoTiffPan === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoTiffPan === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoTiffPan === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoTiffPan === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                      <tr>
                        <td>{t('info.comp_rgb')}</td>
                        <td>{selected.fotoCompRgb === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoCompRgb === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoCompRgb === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoCompRgb === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                      <tr>
                        <td>{t('info.comp_rgbi')}</td>
                        <td>{selected.fotoCompRgbi === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoCompRgbi === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoCompRgbi === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoCompRgbi === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                      <tr>
                        <td>{t('info.comp_ir')}</td>
                        <td>{selected.fotoCompIr === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoCompIr === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoCompIr === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoCompIr === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                      <tr>
                        <td>{t('info.comp_irg')}</td>
                        <td>{selected.fotoCompIrg === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoCompIrg === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoCompIrg === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoCompIrg === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                      <tr>
                        <td>{t('info.comp_pan')}</td>
                        <td>{selected.fotoCompPan === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.fotoCompPan === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                        <td>{selected.ortoCompPan === true ? <div style={{ color: '#fda823' }}><Icon size={iconSize} icon={ic_done} /></div> : selected.ortoCompPan === false ? <Icon size={iconSize} icon={ic_close} /> : '-'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='info-formats-text'>
                  <p dangerouslySetInnerHTML={{ __html: t('info.availability') }}></p>
                </div>
              </TabPanel>
            : null }
            { selected.tipo === 'digital' ?
              <TabPanel>
                <div className='infoFormats parameters'>
                  <br/>
                  <p className='oe-parameters'>{t('info.oe_parameters')}</p>
                  <span className='oe-copy-icon' title={t('info.copy')} onClick={this.copyData}>
                    <Icon size={'18px'} icon={ic_content_copy} />
                  </span>
                  { !Utils.isEmpty(selected.xFotocentroAt) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        X
                      </div>
                      <div className='row attribute-value'>
                        {selected.xFotocentroAt}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.yFotocentroAt) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        Y
                      </div>
                      <div className='row attribute-value'>
                        {selected.yFotocentroAt}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.zFotocentroAt) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        Z
                      </div>
                      <div className='row attribute-value'>
                        {selected.zFotocentroAt}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.giroWAt) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        &#x03C9;
                      </div>
                      <div className='row attribute-value'>
                        {selected.giroWAt}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.giroPhiAt) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        &#x03C6;
                      </div>
                      <div className='row attribute-value'>
                        {selected.giroPhiAt}
                      </div>
                    </div>
                  : null }

                  { !Utils.isEmpty(selected.giroKAt) ?
                    <div className='attributeField'>
                      <div className='row attribute-title'>
                        &#x03BA;
                      </div>
                      <div className='row attribute-value'>
                        {selected.giroKAt}
                      </div>
                    </div>
                  : null }
                  <div className='info-formats-text'>
                    <br/>
                    <p dangerouslySetInnerHTML={{ __html: t('info.oe_text').replace('*', CONFIGURATIONS.oe_url.concat('processes/bsq-fotogramas')) }}></p>
                  </div>
                </div>
              </TabPanel>
            : null }
          </Tabs>
        </div>
      );
    }
  }
}

export default translate()(Info);
