import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import Marker from 'static/img/marker2.svg';
import MarkerSelected from 'static/img/marker_selected2.svg';

import './Mosaic.css';

class Mosaic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      mobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
    };
  }

  hover = () => {
    if (!this.state.mobile) {
      this.setState({ hovered: !this.state.hovered });
    }
  }

  render() {
    const { id, title, subtitle, text, selected, selectMosaic, position, last, collapsed } = this.props;
    const active = selected || this.state.hovered;
    const tooltipStyle = {
      padding: '5px',
      maxWidth: '350px',
      zIndex: 999999,
      whiteSpace: 'break-spaces',
    };

    let place = 'top';
    let offset = {};
    if (position === 0 || position === 1) {
      place = 'right';
      offset = { top: '40px', left: '10px' };
    } else if (position === last || position === last - 1) {
      place = 'left';
      offset = { top: '40px', right: '10px' };
    }

    return (
      <Fragment>
        <div id={'mosaicId' + id } className='mosaic' onClick={selectMosaic} style={{ display: collapsed ? 'none' : 'inline-block' }}
          onMouseOver={this.hover} onMouseOut={this.hover} data-tip data-for={'mosaicId' + id + 'tooltip'}>
          <div className='marker row'>
            <img src={active ? MarkerSelected : Marker} alt='Marker icon'/>
          </div>
          <div className='textContainer row'>
            <div style={{ justifyContent: 'center' }} className='row'>
              <div className='title' style={{ color: '#364B5F', width: '100%' }}>
                {title}
              </div>
              <div className='subtitle'>{subtitle.split('(')[0].trim()}</div>
            </div>
          </div>
        </div>
        { text !== '' && text !== null && text !== undefined && !this.state.mobile ?
          <ReactTooltip className={'onTop'} id={'mosaicId' + id + 'tooltip'} effect='solid' place={place} offset={offset}>
            <div style={tooltipStyle}>
              <span><b>{subtitle}:</b></span>
              <br/>
              <span>{text}</span>
            </div>
          </ReactTooltip>
        : null }
      </Fragment>
    );
  }
}

export default translate()(Mosaic);
