import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import Draggabilly from 'draggabilly';
import { translate } from 'react-i18next';
import { Icon } from 'react-icons-kit';
import { sort } from 'react-icons-kit/fa/sort';
import { saveAs } from 'file-saver';
import { store } from 'react-notifications-component';

import Info from 'components/Info/Info';
import Button from 'components/Button/Button';
import Warning from 'components/Warning/Warning';
import CheckBox from 'components/CheckBox/CheckBox';
import TableRow from 'components/TableRow/TableRow';
import CustomModal from 'components/CustomModal/CustomModal';
import PrintSelection from 'components/PrintSelection/PrintSelection';
import CompareSelector from 'components/CompareSelector/CompareSelector';
import Utils from 'utils/Utils';
import {
  setBbox, addCustomWMSLayer, removeCustomWMSLayer, removeAllCustomWMSLayer,
  bboxContainsGeometry, isCustomWMSLayerActive, setCustomWMSLayerOpacity, centerOnFotogram,
  selectPrintArea, extendExtent, centerOnMark, getExtentByCoordinates, clearPrintLayer,
  getFeatureFromFotogram, newGeoJSONLayer, initCompareMap,
} from 'utils/Visor';

import './List.css';

import ApiService from 'services/ApiService';
import ResourcesService from 'services/ResourcesService';

declare var ol;
declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fotograms: [],
      flights: [],
      flightSelected: undefined,
      selected: [],
      asc: true,
      open: false,
      modalOption: undefined,
      printSelected: {},
      selectedInfo: {},
      formats: [],
      collapsed: [],
      printCenter: [],
      modalMinimized: false,
      searchCollapsed: false,
      mobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
      portrait: this.isPortrait(),
    };

    this.draggable = undefined;
  }

  componentWillMount() {
    const _this = this;
    ApiService.getRequest(API_REST + 'api/virtual_shop/products')
      .then((response) => {
        _this.setState({ formats: response }, () => {
          _this.loadFotograms(_this.props.selectedFeatures, _this.state.asc);
        });
      }).catch((error) => {
        console.error(error);
        _this.loadFotograms(_this.props.selectedFeatures, _this.state.asc);
      });

    window.isMouseDown = false;
    document.addEventListener('mouseup', () => {
      window.isMouseDown = false;
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.loadFotograms(nextProps.selectedFeatures, this.state.asc);
    }
  }

  componentDidMount() {
    if(this.state.mobile) {
      this.addOrientationEvent();
    }
  }

  setPrintCenter = (center) => {
    this.setState({ printCenter: center });
  }

  loadFotograms = (fotograms, asc, callback) => {
    let res = fotograms.sort((a, b) => {
      const flight1 = this.getFlightOfFotogram(a);
      const flight2 = this.getFlightOfFotogram(b);
      const aux1 = flight1.title + ' ' + flight1.subtitle + ' ' + a.substring(a.lastIndexOf('/') + 1, a.lastIndexOf('.'));
      const aux2 = flight2.title + ' ' + flight2.subtitle + ' ' + b.substring(b.lastIndexOf('/') + 1, b.lastIndexOf('.'));
      return Utils.compareStrings(aux1, aux2);
    });

    let flights = [];
    let flightsIds = [];
    fotograms.forEach((f) => {
      const fotogramFlight = this.getFlightOfFotogram(f);
      if (flightsIds.indexOf(fotogramFlight.id) === -1) {
        flights.push(fotogramFlight);
        flightsIds.push(fotogramFlight.id);
      }
    });

    if (asc === false) {
      res = res.reverse();
    }

    this.setState({ fotograms: res, asc: asc, flights: flights }, () => {
      if (window.mapSelectedFlight !== undefined) {
        setTimeout(() => {
          const filtered = this.state.flights.filter((f) => {
            return f.id === window.mapSelectedFlight;
          });

          if (filtered.length > 0) {
            this.setState({ flightSelected: filtered[0] });
          }

          window.mapSelectedFlight = undefined;
        }, 1000);
      }

      if (callback) {
        callback();
      }
    });
  }

  select = (id) => {
    let newSelected = [];
    if (this.state.selected.indexOf(id) > -1) {
      newSelected = this.state.selected.filter(item => item !== id);
    } else {
      newSelected = this.state.selected;
      newSelected.push(id);
    }

    this.setState({ selected: newSelected });
  }

  selectAll = () => {
    const flightSelected = this.state.flightSelected || this.state.flights[0];
    const selectedFromFlight = this.state.selected.filter((s) => {
      return flightSelected.id === this.getFlightOfFotogram(s).id;
    });

    const totalFromFlight = this.state.fotograms.filter((f) => {
      return flightSelected.id === this.getFlightOfFotogram(f).id;
    });

    if (selectedFromFlight.length > 0 && selectedFromFlight.length === totalFromFlight.length) {
      let allIncluded = true;
      totalFromFlight.forEach((f) => {
        allIncluded = allIncluded && (selectedFromFlight.indexOf(f) > -1);
      });

      if (allIncluded) {
        this.setState({ selected: [] });
      } else {
        this.setState({ selected: totalFromFlight });
      }
    } else {
      this.setState({ selected: totalFromFlight });
    }
  }

  changeOpacity = (id, newOpacity) => {
    const fotograms = [].concat(this.state.fotograms);
    const fotogram = this.state.fotograms.indexOf(id) > -1 ? this.state.fotograms[this.state.fotograms.indexOf(id)] : undefined;
    if (fotogram !== undefined) {
      if (isCustomWMSLayerActive(fotogram)) {
        setCustomWMSLayerOpacity(fotogram, newOpacity);
      }
    }

    this.loadFotograms(fotograms, this.state.asc);
  }

  allFotogramsOpenedSameLayer = (fotogram) => {
    let res = false;
    let opened = this.state.fotograms.filter((f) => {
      return isCustomWMSLayerActive(f);
    });

    if (opened.length > 0) {
      let layer = '';
      this.props.flights.forEach((flight) => {
        flight.keys.forEach((k) => {
          if (opened[0].indexOf(k) > -1) {
            layer = k;
          }
        });
      });

      if (layer !== '') {
        let sameLayer = opened.filter((o) => {
          return o.indexOf(layer) > -1 && fotogram.indexOf(layer) > -1;
        });

        res = res || (opened.length === sameLayer.length);
      }
    } else {
      res = res || true;
    }

    return res;
  }

  changeOpened = (id, forceOpen, showAlert) => {
    clearPrintLayer();
    const _this = this;
    const fotograms = [].concat(this.state.fotograms);
    _this.setState({ open: true, modalOption: 'wait' }, () => {
      const fotogram = this.state.fotograms.indexOf(id) > -1 ? this.state.fotograms[this.state.fotograms.indexOf(id)] : undefined;
      if (fotogram !== undefined) {
        if (isCustomWMSLayerActive(fotogram) && !forceOpen) {
          removeCustomWMSLayer(fotogram);
        } else {
          _this.props.block();
          if (!_this.allFotogramsOpenedSameLayer(fotogram)) {
            removeAllCustomWMSLayer();
          }

          const flight = _this.getFlightOfFotogram(fotogram);
          if (flight.layer !== undefined) {
            let flightLayer = flight.layer;
            Promise.all([Utils.getFile(flight.centers), Utils.getFile(flight.marks)]).then((geojsons) => {
              const centers = geojsons[0];
              const marks = geojsons[1];
              let coord = [];
              let geomMark = null;
              for (let feature of centers.features) {
                if (Utils.stringContainsString(feature.properties[CONFIGURATIONS.fotogram_layer_attr], fotogram)) {
                  coord = feature.geometry.coordinates;
                  break;
                }
              }

              for (let feature of marks.features) {
                if (Utils.stringContainsString(feature.properties[CONFIGURATIONS.marks_layer_attr], fotogram)) {
                  geomMark = feature.geometry;
                  break;
                }
              }

              if (_this.props.flightLayer !== flightLayer) {
                _this.props.selectTempFlight(flightLayer);
              }
              let unblock = _this.props.unblock;
              if (this.state.mobile && showAlert) {
                const message = _this.props.t('list.show_fotogram');
                unblock = () => {
                  _this.props.unblock();
                  store.addNotification({
                    title: '',
                    message: message,
                    type: 'info',
                    insert: 'top',
                    container: 'top-center',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'zoomOut'],
                    dismiss: {
                      duration: 1000,
                      click: true,
                      touch: true,
                      showIcon: true,
                    },
                  });
                }
              }
              addCustomWMSLayer(CONFIGURATIONS.fotograms_layer_url, flightLayer, fotogram, unblock);
              if (!bboxContainsGeometry(geomMark)) {
                centerOnFotogram(fotogram, coord);
              }
            });
          }
        }

        _this.setState({ open: false, modalOption: undefined }, () => {
          _this.loadFotograms(fotograms, _this.state.asc);
        });
      }
    });
  }

  sort = () => {
    const newSort = !this.state.asc;
    this.loadFotograms(this.state.fotograms, newSort);
  }

  deleteFotogram = (id) => {
    clearPrintLayer();
    const _this = this;
    const fotogram = this.state.fotograms.indexOf(id) > -1 ? this.state.fotograms[this.state.fotograms.indexOf(id)] : undefined;
    if (fotogram !== undefined) {
      const flight = this.getFlightOfFotogram(fotogram);
      let newFotograms = this.state.fotograms.filter(item => item !== id);
      if (isCustomWMSLayerActive(fotogram)) {
        removeCustomWMSLayer(fotogram);
      }

      const newSelected = this.state.selected.filter((s) => {
        return s !== id;
      });

      this.setState({ selected: newSelected }, () => {
        _this.loadFotograms(newFotograms, this.state.asc, () => {
          _this.props.selectFeatures(newFotograms);
          const num = newFotograms.filter((f) => {
            return flight.id === this.getFlightOfFotogram(f).id;
          }).length;

          if (num === 0 && newFotograms.length > 0) {
            const flights = this.state.flights.filter((f) => {
              return f.id === this.getFlightOfFotogram(newFotograms[0]).id;
            });

            if (flights.length > 0) {
              _this.setState({ flightSelected: flights[0] }, () => {
                if (flights[0].layer !== undefined) {
                  let flightLayer = flights[0].layer;
                  if (_this.props.flightLayer !== flightLayer) {
                    _this.props.selectTempFlight(flightLayer);
                  }
                }
              });
            } else {
              _this.setState({ flightSelected: undefined });
            }
          } else if (num === 0 && newFotograms.length === 0) {
            _this.setState({ flightSelected: undefined });
          } else {
            const flights = this.state.flights.filter((f) => {
              return f.id === flight.id;
            });

            if (flights.length > 0) {
              _this.setState({ flightSelected: flights[0] });
            }
          }
        });
      });
    }
  }

  enterKeyDeleteAll = (evt) => {
    const keyCode = evt.charCode || evt.which;
    if (keyCode === 13) {
      this.deleteAll();
    }
  }

  checkDeleteAll = () => {
    this.setState({ open: true, modalOption: 'sure-delete' }, () => {
      document.addEventListener('keyup', this.enterKeyDeleteAll);
    });
  }

  deleteSelected = () => {
    const _this = this;
    clearPrintLayer();
    let ids = [];
    let newFotograms = this.state.fotograms.filter((item) => {
      if (this.state.selected.indexOf(item) > -1) {
        ids.push(item);
      }

      return this.state.selected.indexOf(item) < 0
    });

    newFotograms = Utils.sortArrayByProperty(newFotograms, 'name', this.state.asc);
    ids.forEach((fId) => {
      const fotogram = this.state.fotograms.indexOf(fId) > -1 ? this.state.fotograms[this.state.fotograms.indexOf(fId)] : undefined;
      if (fotogram !== undefined) {
        if (isCustomWMSLayerActive(fotogram)) {
          removeCustomWMSLayer(fotogram);
        }
      }
    });

    const flightSelected = this.state.flightSelected || this.state.flights[0];
    const isFlightEmpty = newFotograms.filter((f) => {
      return flightSelected.id === this.getFlightOfFotogram(f).id;
    }).length === 0;

    this.setState({ selected: [], collapsed: [], flightSelected: isFlightEmpty ? undefined : flightSelected, open: false, modalOption: undefined }, () => {
      _this.loadFotograms(newFotograms, this.state.asc, () => {
        _this.props.selectFeatures(newFotograms);
      });
    });
  }

  deleteAll = () => {
    const _this = this;
    clearPrintLayer();
    removeAllCustomWMSLayer();
    this.setState({ fotograms: [], selected: [], collapsed: [], open: false, modalOption: undefined }, () => {
      _this.props.selectFeatures([]);
      document.removeEventListener('keyup', this.enterKeyDeleteAll);
    });
  }

  onCloseModal = () => {
    clearPrintLayer();
    this.setState({ open: false, modalMinimized: false, modalOption: undefined, printSelected: {}, printCenter: [], selectedInfo: {} }, () => {
      document.removeEventListener('keyup', this.checkEscKey.bind(this));
      const toRemove = document.querySelector('style[title$="overrideCSS"]');
      if (toRemove !== null) {
        toRemove.remove();
      }
      if (this.compareMaps) {
        this.compareMaps = null;
      }
    });
  };

  print = (id) => {
    clearPrintLayer();
    this.props.block();
    const _this = this;
    const fotograms = [].concat(this.state.fotograms);
    const fotogram = this.state.fotograms.indexOf(id) > -1 ? this.state.fotograms[this.state.fotograms.indexOf(id)] : undefined;
    let notActive = false;
    if (fotogram !== undefined) {
      if (this.state.mobile) {
        this.toogleHiddenParent();
      }
      const flight = _this.getFlightOfFotogram(fotogram);
      if (flight.layer !== undefined) {
        let flightLayer = flight.layer;
        Utils.getFile(flight.centers).then((geojson) => {
          let coord = [];
          for (let feature of geojson.features) {
            if (Utils.stringContainsString(feature.properties[CONFIGURATIONS.fotogram_layer_attr], fotogram)) {
              coord = feature.geometry.coordinates;
              break;
            }
          }

          Utils.getFile(flight.marks).then((geojson) => {
            let extent = [];
            for (let feature of geojson.features) {
              if (Utils.stringContainsString(feature.properties[CONFIGURATIONS.marks_layer_attr], fotogram)) {
                extent = getExtentByCoordinates(feature.geometry);
                break;
              }
            }

            if (_this.props.flightLayer !== flightLayer) {
              _this.props.selectTempFlight(flightLayer);
            }

            if (!isCustomWMSLayerActive(fotogram)) {
              addCustomWMSLayer(CONFIGURATIONS.fotograms_layer_url, flightLayer, fotogram, _this.props.unblock);
              centerOnFotogram(fotogram, coord);
              setBbox(extent);
              notActive = true;
            } else {
              _this.props.unblock();
            }

            const printSelected = {
              fotogram: fotogram,
              extent: extent,
              center: coord,
              layer: flight.layer,
              scales: flight.scales,
              certifiable: flight.certifiable,
              printPaper: flight.printPaper,
              printSizes: flight.printSizes,
            };

            _this.setState({ open: true, modalOption: 'print', printSelected: printSelected }, () => {
              _this.loadFotograms(fotograms, _this.state.asc);
              setTimeout(() => {
                if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
                  const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement, {
                    containment: '#root',
                    handle: '.customModalTitle',
                  });

                  draggable.enable();
                  this.draggable = draggable;
                }
              }, notActive ? 3000 : 800);
            });
          });
        });
      }
    }
  }

  getFlightOfFotogram = (fotogram) => {
    let flightRes = {};
    for (let flight of this.props.flights) {
      if (fotogram !== undefined) {
        // eslint-disable-next-line
        flight.keys.forEach((k) => {
          if (fotogram.indexOf(k) > -1) {
            flightRes = flight;
          }
        });
      }
    }

    return flightRes;
  }

  obtainList = () => {
    let text = 'VUELO\tFOTOGRAMA\r\n';
    let flightName = this.props.flightName;
    for (let f of this.state.fotograms) {
      const flight = this.getFlightOfFotogram(f);
      flightName = flight.title + ' ' + flight.subtitle;
      if (this.state.selected.length > 0) {
        if (this.state.selected.indexOf(f) > -1) {
          text += `${flightName}\t${f.substring(f.lastIndexOf('/') + 1, f.lastIndexOf('.'))}\r\n`;
        }
      } else {
        text += `${flightName}\t${f.substring(f.lastIndexOf('/') + 1, f.lastIndexOf('.'))}\r\n`;
      }
    }

    const file = new File([text], CONFIGURATIONS.download_fotograms_list_file, { type: 'text/csv;charset=utf-8' });
    saveAs(file);
  }

  obtainListWithOE = () => {
    const { t } = this.props;
    const url = CONFIGURATIONS.oe_url.concat('processes/bsq-fotogramas/execution');
    const fotogramas = [];
    const flights = [];
    for (let f of this.state.fotograms) {
      if (this.state.selected.length > 0) {
        if (this.state.selected.indexOf(f) > -1) {
          fotogramas.push(f.substring(f.lastIndexOf('/') + 1, f.lastIndexOf('.')));
          flights.push(this.getFlightOfFotogram(f));
        }
      }
    }

    ApiService.postRequest(url, { inputs: { completo: true, fotogramas: fotogramas }}).then((response) => {
      if (response.data.fotogramas !== undefined) {
        if (response.data.fotogramas.length > 0) {
          let text = 'VUELO\tFOTOGRAMA\tFECHA\tX FOTOCENTRO\tY FOTOCENTRO\tZ FOTOCENTRO\tGIRO W\tGIRO PHI\tGIRO K\r\n';
          response.data.fotogramas.forEach((r) => {
            const flight = flights[fotogramas.indexOf(r.nom_fichero)];
            const flightName = flight.title + ' ' + flight.subtitle;
            text += `${flightName}\t${r.nom_fichero}\t${r.fecha_fotograma}\t${r.x_fotocentro_at}\t${r.y_fotocentro_at}\t${r.z_fotocentro_at}\t${r.giro_w_at}\t${r.giro_phi_at}\t${r.giro_k_at}\r\n`;
          });

          const file = new File([text], CONFIGURATIONS.download_fotograms_list_file, { type: 'text/csv;charset=utf-8' });
          saveAs(file);
          if (response.data.fotogramas.length < fotogramas.length) {
            store.addNotification({
              title: t('list.warning'),
              message: t('list.some_downloaded'),
              type: 'warning',
              insert: 'top',
              container: 'top-center',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'zoomOut'],
              dismiss: {
                duration: 5000,
                click: true,
                touch: true,
                showIcon: true,
              },
            });
          }
        } else {
          store.addNotification({
            title: t('list.warning'),
            message: t('list.none_downloaded'),
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 5000,
              click: true,
              touch: true,
              showIcon: true,
            },
          });
        }
      } else {
        store.addNotification({
          title: t('list.warning'),
          message: t('list.oe_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }
    }).catch((err) => {
      console.error(err);
      store.addNotification({
        title: t('list.warning'),
        message: t('list.oe_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  downloadList = () => {
    let text = '';
    for (let f of this.state.fotograms) {
      let path = f.replace(/\\/g, '/');
      if (path.indexOf(':') > -1) {
        path = path.split(':')[1];
      }

      path = path.replace('/var/www/apps/fototeca/data', '');
      if (this.state.selected.length > 0) {
        if (this.state.selected.indexOf(f) > -1) {
          text += path + ',';
        }
      } else {
        text += path + ',';
      }
    }

    if (text.endsWith(',')) {
      text = text.slice(0, -1);
    }

    const url = API_REST + 'api/download/url';
    ApiService.postRequest(url, text.split(',')).then((response) => {
      const url = response.data;
      window.open(url);
    });
  }

  getDownloadables = () => {
    let res = [];
    for (let f of this.state.selected) {
      const flight = this.getFlightOfFotogram(f);
      if (!Utils.stringContainsString(flight.subtitle, 'american')) {
        res.push(flight);
      }
    }

    return res;
  }

  acceptPrint = (scale, template) => {
    const _this = this;
    let printSelected = this.state.printSelected;
    let size = [0, 0];
    if (Utils.stringContainsString(template, 'a3 horizontal')) {
      size = [42.0, 29.7];
    } else if (Utils.stringContainsString(template, 'a3 vertical')) {
      size = [29.7, 42.0];
    } else if (Utils.stringContainsString(template, 'a4 horizontal')) {
      size = [29.7, 21.0];
    } else if (Utils.stringContainsString(template, 'a4 vertical')) {
      size = [21.0, 29.7];
    } else {
      const sizes = template.split('x');
      size = [parseInt(sizes[0], 10), parseInt(sizes[1], 10)];
    }

    printSelected.template = template;
    printSelected.scale = scale;
    if (printSelected.extent.length > 0) {
      selectPrintArea(scale, printSelected.center, size, printSelected.extent, this.onCloseModal, this.setPrintCenter);
      this.setState({ printSelected: printSelected });
    } else {
      this.setState({ open: false, modalOption: undefined, printSelected: {} }, () => {
        _this.setState({ open: true, modalOption: 'error-printing2' });
      });
    }
  }

  sendToPrint = (paper_cert, digital_cert) => {
    const { fotograms, printCenter, printSelected } = this.state;
    const _this = this;
    printSelected.paperCert = paper_cert;
    printSelected.digitalCert = digital_cert;
    if (printCenter.length > 0) {
      _this.props.block();
      const fotogram = fotograms.indexOf(printSelected.fotogram) > -1 ? fotograms[fotograms.indexOf(printSelected.fotogram)] : undefined;
      const obj = getFeatureFromFotogram(fotogram, _this.props.centersLayer).properties;
      ApiService.getRequest(API_REST + 'api/flight/get/' + obj.cod_vuelo)
        .then((response) => {
          const flight = response;
          const name = printSelected.fotogram.substring(printSelected.fotogram.lastIndexOf('/') + 1, printSelected.fotogram.length);
          const scaleAux = flight.escala !== undefined && flight.escala !== '' && flight.escala !== null ? `1/${flight.escala}` : (flight.gsd / 100);
          const request = ResourcesService.getPrintRequest(name, printCenter, printSelected.layer, printSelected.fotogram, printSelected.template, printSelected.scale, obj.fecha_fotograma || '-', scaleAux);
          ApiService.postRequest(API_REST + 'api/pdf/print', request)
            .then((response) => {
              if (response.data.error === false) {
                const newUrl = API_REST + 'api/pdf/get/' + response.data.code;
                const link = document.createElement('a');
                link.href = newUrl;
                document.body.appendChild(link);
                let added = false;
                if (printSelected.digitalCert === true || printSelected.paperCert === true) {
                  link.click();
                  document.body.removeChild(link);
                  _this.props.unblock();
                  if (printSelected.digitalCert === true) {
                    _this.addToShoppingCart(printSelected, newUrl, true);
                    added = true;
                  }

                  if (printSelected.paperCert === true) {
                    _this.addToShoppingCart(printSelected, newUrl, false, printSelected.template);
                    added = true;
                  }

                  if (added) {
                    _this.addShoppingCartMessage();
                  }

                  _this.onCloseModal();
                } else {
                  link.click();
                  document.body.removeChild(link);
                  _this.props.unblock();
                  _this.onCloseModal();
                  setTimeout(() => {
                    ApiService.getRequest(API_REST + 'api/pdf/delete/' + response.data.code);
                  }, CONFIGURATIONS.delete_print_timeout);
                }
              } else {
                _this.props.unblock();
                _this.setState({ printSelected: {}, printCenter: [], open: true, modalOption: 'error-printing' });
              }
            }).catch((error) => {
              console.error(error);
              _this.props.unblock();
              _this.setState({ printSelected: {}, printCenter: [], open: true, modalOption: 'error-printing' });
            });
        }).catch((error) => {
          console.error(error);
          _this.props.unblock();
          _this.setState({ printSelected: {}, printCenter: [], open: true, modalOption: 'error-printing' });
        });
    } else {
      _this.setState({ printSelected: {}, printCenter: [], open: true, modalOption: 'error-area-selected' });
    }
  }

  showInfoFotogram = (id) => {
    this.props.block();
    const _this = this;
    const fotogram = this.state.fotograms.indexOf(id) > -1 ? this.state.fotograms[this.state.fotograms.indexOf(id)] : undefined;
    if (fotogram !== undefined) {
      if (_this.props.centersLayer && getFeatureFromFotogram(fotogram, _this.props.centersLayer) !== null) {
        const obj = getFeatureFromFotogram(fotogram, _this.props.centersLayer).properties;
        const code = (obj.cod_fotograma_a !== undefined && obj.cod_fotograma_a !== '' && obj.cod_fotograma_a !== null) ? obj.cod_fotograma_a : obj.cod_fotograma_d;
        ApiService.getRequest(API_REST + 'api/photogram/get/' + code)
          .then((response) => {
            _this.props.unblock();
            _this.setState({ open: true, modalOption: 'info', selectedInfo: response }, () => {
              setTimeout(() => {
                if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
                  const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
                    containment: 'body',
                    handle: '.customModalTitle',
                  });

                  draggable.enable();
                  this.draggable = draggable;
                }
              }, 500);
            });
          }).catch((error) => {
            _this.props.unblock();
            console.error(error);
          });
      } else {
        const flight = _this.getFlightOfFotogram(fotogram);
        if (flight.layer !== undefined) {
          Utils.getFile(flight.centers).then((geojson) => {
            const centers = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.centers_name }, { displayInLayerSwitcher: false });
            const obj = getFeatureFromFotogram(fotogram, centers).properties;
            const code = (obj.cod_fotograma_a !== undefined && obj.cod_fotograma_a !== '' && obj.cod_fotograma_a !== null) ? obj.cod_fotograma_a : obj.cod_fotograma_d;
            ApiService.getRequest(API_REST + 'api/photogram/get/' + code)
              .then((response) => {
                _this.props.unblock();
                _this.setState({ open: true, modalOption: 'info', selectedInfo: response }, () => {
                  setTimeout(() => {
                    if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
                      const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
                        containment: 'body',
                        handle: '.customModalTitle',
                      });

                      draggable.enable();
                      this.draggable = draggable;
                    }
                  }, 500);
                });
              }).catch((error) => {
                _this.props.unblock();
                console.error(error);
              });
          });
        } else {
          _this.props.unblock();
        }
      }
    } else {
      _this.props.unblock();
    }
  }

  zoomFotogram = (id) => {
    this.changeOpened(id, true, false);
    const _this = this;
    const fotogram = this.state.fotograms.indexOf(id) > -1 ? this.state.fotograms[this.state.fotograms.indexOf(id)] : undefined;
    if (fotogram !== undefined) {
      _this.props.block();
      const flight = _this.getFlightOfFotogram(fotogram);
      if (flight.layer !== undefined) {
        Promise.all([Utils.getFile(flight.marks)]).then((geojsons) => {
          const marks = geojsons[0];
          let geomMark = null;
          for (let feature of marks.features) {
            if (Utils.stringContainsString(feature.properties[CONFIGURATIONS.marks_layer_attr], fotogram)) {
              geomMark = feature.geometry;
              break;
            }
          }
          if (this.state.mobile) {
            _this.toogleHiddenParent();
          }
          if (geomMark !== null) {
            centerOnMark(geomMark);
            _this.props.unblock();
          } else {
            _this.props.unblock();
          }
        });
      }
    }
  }

  minimizePrint = () => {
    const contentElem = document.querySelector('.customModalContent');
    if (contentElem !== null) {
      if (contentElem.classList.contains('hidden')) {
        contentElem.classList.remove('hidden');
        contentElem.parentElement.style.top = '0';
        contentElem.parentElement.style.left = '0';
        this.setState({ modalMinimized: false });
      } else {
        contentElem.classList.add('hidden');
        contentElem.parentElement.style.top = null;
        contentElem.parentElement.style.left = null;
        this.setState({ modalMinimized: true });
      }
    }
  }

  addToShoppingCart = (printSelected, pdfUrl, isDigital, template) => {
    let code = undefined;
    let name = undefined;
    if (isDigital === true) {
      const filtered = this.state.formats.filter((item) => {
        return Utils.stringContainsString(item.nomarticulo, 'digital');
      })[0];

      code = filtered.codinterno;
      name = filtered.nomarticulo;
    } else {
      let realTemplate = '' + template;
      if (Utils.stringContainsString(template, 'A4')) {
        realTemplate = 'A4';
      } else if (Utils.stringContainsString(template, 'A3')) {
        realTemplate = 'A3';
      }

      const filtered = this.state.formats.filter((item) => {
        const inverseTemplate = realTemplate.indexOf('x') > -1 ? realTemplate.split('x')[1] + 'x' + realTemplate.split('x')[0] : realTemplate;
        return Utils.stringContainsString(item.nomarticulo, realTemplate) || Utils.stringContainsString(item.nomarticulo, inverseTemplate);
      })[0];

      code = filtered.codinterno;
      name = filtered.nomarticulo;
    }

    const item = {
      identifier: new Date().getTime(),
      codinterno: code,
      cantidad: 1,
      url: pdfUrl,
      fotogram: printSelected.fotogram,
      product: name,
    };

    let newShoppingCart = [].concat(this.props.shoppingCart);
    newShoppingCart.push(item);
    this.props.updateShoppingCart(newShoppingCart);
  }

  addShoppingCartMessage = () => {
    const { t } = this.props;
    store.addNotification({
      title: t('list.warning'),
      message: t('list.products_added'),
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'zoomOut'],
      dismiss: {
        duration: 5000,
        click: true,
        touch: true,
        showIcon: true,
      },
    });
  }

  collapse = (flightId) => {
    let newCollapsed = [];
    if (this.state.collapsed.indexOf(flightId) > -1) {
      newCollapsed = this.state.collapsed.filter((id) => id !== flightId);
    } else {
      newCollapsed.push(flightId);
      newCollapsed = newCollapsed.concat(this.state.collapsed);
    }

    this.setState({ collapsed: newCollapsed });
  }

  compare = () => {
    this.setState({ open: true, modalOption: 'compare-selector' }, () => {
      setTimeout(() => {
        if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
          const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
            containment: 'body',
            handle: '.customModalTitle',
          });

          draggable.enable();
          this.draggable = draggable;
        }
      }, 50);
    });
  }

  changeSelectFlight = (evt) => {
    const _this = this;
    _this.props.block();
    const id = parseInt(evt.target.value, 10);
    const filtered = this.state.flights.filter((f) => {
      return f.id === id;
    });

    if (filtered.length > 0) {
      this.setState({ flightSelected: filtered[0] }, () => {
        if (_this.props.flightLayer !== filtered[0].layer) {
          _this.props.selectTempFlight(filtered[0].layer);
        }

        _this.props.unblock();
      });
    } else {
      _this.props.unblock();
    }
  }

  openComparator = (data) => {
    const { t } = this.props;
    if (this.draggable !== undefined) {
      this.draggable.destroy();
    }

    this.setState({ open: false, modalOption: '' }, () => {
      this.setState({ open: true, modalOption: 'compare' }, () => {
        document.addEventListener('keyup', this.checkEscKey.bind(this));
        document.querySelector('.modalOverlay').style.padding = '0px';
        document.querySelector('.modalOverlay > div').style.minWidth = '100vw';
        document.querySelector('.modalOverlay > div').style.minHeight = '100vh';
        const maps = [];
        if (data !== undefined && data.screen1 !== undefined) {
          maps.push(
            <div className='compare-map' key={'map1'} id={'map1'}>
              <p className='compare-flight-title'><b>{data.screen1.flight.title + ' ' + data.screen1.flight.subtitle}</b></p>
            </div>
          );

          maps.push(
            <div className='compare-map' key={'map2'} id={'map2'}>
              <p className='compare-flight-title'><b>{data.screen2.flight.title + ' ' + data.screen2.flight.subtitle}</b></p>
            </div>
          );

          const content = <React.Fragment>{maps}</React.Fragment>;
          ReactDOM.render(content, document.getElementById('compareContainer'));
          setTimeout(() => {
            const overrideCSS = document.createElement('style');
            overrideCSS.title = 'overrideCSS';
            overrideCSS.innerHTML = '.m-dialog {z-index: 999999999999999 !important;}';
            document.body.appendChild(overrideCSS);
            Promise.all([Utils.getFile(data.screen1.flight.marks), Utils.getFile(data.screen2.flight.marks)]).then((geojsons) => {
              let extent = null;
              for (let f of geojsons[0].features) {
                if (Utils.arrayContainsString(data.screen1.fotograms, f.properties[CONFIGURATIONS.marks_layer_attr])) {
                  if (extent !== null) {
                    extent = extendExtent(extent, getExtentByCoordinates(f.geometry));
                  } else {
                    extent = getExtentByCoordinates(f.geometry);
                  }
                }
              }

              for (let f of geojsons[1].features) {
                if (Utils.arrayContainsString(data.screen2.fotograms, f.properties[CONFIGURATIONS.marks_layer_attr])) {
                  if (extent !== null) {
                    extent = extendExtent(extent, getExtentByCoordinates(f.geometry));
                  } else {
                    extent = getExtentByCoordinates(f.geometry);
                  }
                }
              }

              const maps = [
                initCompareMap('map1', CONFIGURATIONS.fotograms_layer_url, data.screen1.flight.layer, extent, data.screen1.fotograms, t),
                initCompareMap('map2', CONFIGURATIONS.fotograms_layer_url, data.screen2.flight.layer, extent, data.screen2.fotograms, t),
              ];
              this.compareMaps = maps;
              maps.forEach((m) => {
                const associated = maps.filter((fm) => {
                  return fm.getTarget() !== m.getTarget();
                });

                m.addInteraction(new ol.interaction.Synchronize({ maps: associated }));
              });
              document.querySelector('#map1').addEventListener('synchronize', this.refreshSynchronize.bind(this));
              document.querySelector('#map2').addEventListener('synchronize', this.refreshSynchronize.bind(this));
            });
          }, 1000);
        } else {
          maps.push(
            <div className='compare-map' key={'map1'} id={'map1'}>
              <p className='compare-flight-title'><b>{t('compareSelector.screen1')}</b></p>
            </div>
          );

          maps.push(
            <div className='compare-map' key={'map2'} id={'map2'}>
              <p className='compare-flight-title'><b>{t('compareSelector.screen2')}</b></p>
            </div>
          );

          const content = <React.Fragment>{maps}</React.Fragment>;
          ReactDOM.render(content, document.getElementById('compareContainer'));
          setTimeout(() => {
            const overrideCSS = document.createElement('style');
            overrideCSS.title = 'overrideCSS';
            overrideCSS.innerHTML = '.m-dialog {z-index: 999999999999999 !important;}';
            document.body.appendChild(overrideCSS);
            const maps = [
              initCompareMap('map1', null, null, null, null, t),
              initCompareMap('map2', null, null, null, null, t),
            ];
            this.compareMaps = maps;
            maps.forEach((m) => {
              const associated = maps.filter((fm) => {
                return fm.getTarget() !== m.getTarget();
              });

              m.addInteraction(new ol.interaction.Synchronize({ maps: associated }));
            });
            document.querySelector('#map1').addEventListener('synchronize', this.refreshSynchronize.bind(this));
            document.querySelector('#map2').addEventListener('synchronize', this.refreshSynchronize.bind(this));
          }, 1000);
        }
      });
    });
  }

  refreshSynchronize = (evt) => {
    const idMap = evt.target.id;
    if (idMap === 'map1') {
      this.compareMaps[0].setCenter(this.compareMaps[1]);
    } else if (idMap === 'map2') {
      this.compareMaps[1].setCenter(this.compareMaps[0]);
    }
  }

  checkEscKey = (evt) => {
    if (evt.key === 'Escape') {
      document.removeEventListener('keyup', this.checkEscKey.bind(this));
    }
  }

  toogleHiddenParent = () => {
    const actionsContainer = document.querySelector('.tools-menu.nav-wrapper>.actionsContainer>.actionsTitleContainer');
    actionsContainer.click();
  }

  toogleCollapsedParent = () => {
    const inv = !this.state.searchCollapsed;
    this.setState({searchCollapsed: inv}, () => {
      const actionsCollapsedBtn = document.querySelector('#collapseActionsBtn');
      actionsCollapsedBtn.click();
    });
  }

  addOrientationEvent = () => {
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    mediaQueryList.addEventListener('change', this.handleOrientationChange);
  }

  isPortrait = () => {
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    return mediaQueryList.matches
  }

  handleOrientationChange = (event) => {
    this.setState({portrait: event.matches}, () => {
      const compareElem = document.querySelector('.compare-container');
      if (compareElem) {
        compareElem.style.height = 'calc('+(window.innerHeight-50)+'px - 2.4rem)';
      }

      const resultTable = document.querySelector('.table.row');
      if(resultTable){
        let height = window.innerHeight;
        if (event.matches) {
          height -= 535;
        } else {
          height -= 240;
        }
        resultTable.firstChild.style.height = height+'px';
      }
      if (this.compareMaps) {
        this.compareMaps.forEach(m => m.updateSize());
      }
      if (!event.matches && this.state.searchCollapsed) {
        this.toogleCollapsedParent();
      }
    });
  }

  render() {
    const { t, configurations, flightLayer } = this.props;
    const { open, modalOption, printSelected, flights, selected, modalMinimized, portrait, mobile, searchCollapsed } = this.state;
    let flightSelected = this.state.flightSelected || flights[0];
    const firstFlight = this.getFlightOfFotogram(this.state.fotograms[0]);
    if (flightSelected !== undefined && flightLayer !== undefined && flightSelected.layer !== flightLayer) {
      const contains = flights.filter(item => item.layer === flightLayer).length > 0;
      if (contains) {
        flightSelected = flights.filter(item => item.layer === flightLayer)[0];
      } else {
        flightSelected = firstFlight;
      }
    }

    let fotograms = [];
    let options = [];
    let allFromFlightSelected = false;
    if (this.state.fotograms.length > 0) {
      flights.forEach((f) => {
        options.push(<option key={'optFlight' + f.id} value={f.id}>{f.title + ' ' + f.subtitle}</option>);
      });

      this.state.fotograms.filter((f) => {
        return flightSelected.id === this.getFlightOfFotogram(f).id;
      }).forEach((f, i) => {
        fotograms.push(<TableRow key={'f' + i} data={f} select={this.select.bind(null, f)} flight={flightSelected}
          isSelected={this.state.selected.indexOf(f) > -1} printFotogram={this.print.bind(null, f)}
          zoomFotogram={this.zoomFotogram.bind(null, f)} openHideFotogram={this.changeOpened.bind(null, f, false, true)}
          changeOpacity={this.changeOpacity.bind(null, f)} deleteFotogram={this.deleteFotogram.bind(null, f)}
          showInfoPopup={this.showInfoFotogram.bind(null, f)} configurations={configurations} />);
      });

      const selectedFromFlight = selected.filter((s) => {
        return flightSelected.id === this.getFlightOfFotogram(s).id;
      });

      const totalFromFlight = this.state.fotograms.filter((f) => {
        return flightSelected.id === this.getFlightOfFotogram(f).id;
      });

      if (selectedFromFlight.length > 0 && selectedFromFlight.length === totalFromFlight.length) {
        let allIncluded = true;
        totalFromFlight.forEach((f) => {
          allIncluded = allIncluded && (selectedFromFlight.indexOf(f) > -1);
        });

        allFromFlightSelected = allIncluded;
      }
    }

    let tableHeight = 'calc(100vh - 480px)';
    if (mobile) {
      if (portrait) {
        tableHeight = (window.innerHeight - 565) < 70 ? 70 : (window.innerHeight - 565) + 'px';
      } else {
        tableHeight = (window.innerHeight - 240) < 70 ? 70 : (window.innerHeight - 240) + 'px';
      }
    }

    return (
      <Fragment>
        <div className='listContainer'>
          <div className='listTitleContainer row' onClick={mobile && !portrait ? this.toogleHiddenParent : null}>
            <span>{t('list.my_list') + ' '} ({this.state.fotograms.length})</span>
            {mobile && portrait ? 
            <div id='collapseFiltersListBtn' className='collapse-button-frames' onClick={this.toogleCollapsedParent} style={{cursor: 'pointer'}}>{searchCollapsed ? t('others.showfilters') : t('others.hidefilters')}</div>
            : null}
          </div>
          {this.state.fotograms.length > 0 ?
            <Fragment>
              <div className='flightSelectorContainer'>
                {options.length > 1 ?
                  <Fragment>
                    <span>{t('list.available_flights') + ':'}</span>
                    <select className='flightSelector' value={flightSelected.id} onChange={this.changeSelectFlight}>
                      {options}
                    </select>
                  </Fragment>
                  : <p><b>{t('list.available_flights') + ':  '}</b>{flightSelected.title + ' ' + flightSelected.subtitle}</p>}
              </div>
              <div className='table row'>
                <div style={{ overflowY: 'auto', overflowX: 'hidden', height: tableHeight, width: '430px'}}>
                  <table style={{ borderCollapse: 'collapse', width: '420px' }}>
                    <thead>
                      <tr className='tableRowHeader'>
                        <th className='tableCell'><CheckBox onClick={this.selectAll} isSelected={allFromFlightSelected} all={true} /></th>
                        <th className='tableCell'>{t('list.fotograms')}
                          <span className='sortButton' onClick={this.sort}>
                            <Icon size={'12px'} icon={sort} />
                          </span>
                        </th>
                        <th className='tableCell'>{t('list.opacity')}</th>
                        <th className='tableCell'>{t('list.actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fotograms}
                    </tbody>
                  </table>
                </div>
              </div>
              {this.state.selected.length > 0 ?
                <div className='listSelectionContainer row'>
                  <p style={{ width: '100%', marginTop: '0rem', marginBottom: '-0.5rem', fontSize: '12px' }}>{t('list.selected') + ': '}<b>{this.state.selected.length}</b></p>
                  <p style={{ width: '100%', marginBottom: '10px', fontSize: '12px' }}>{t('list.downloadables') + ': '}<b>{this.getDownloadables().length}</b></p>
                </div>
                : null}
              <div className='listButtonsContainer row'>
                <Button text={t('list.remove')} active={this.state.selected.length > 0} onClick={this.deleteSelected} text1={t('list.remove_selection')} text2={t('list.remove_all')} onClick2={this.checkDeleteAll} />
                <Button text={t('list.download')} active={this.getDownloadables().length > 0} onClick={this.downloadList} />
                <Button text={t('list.obtain')} active={this.state.selected.length > 0} onClick={this.obtainList} text1={t('list.fotograms')} text2={t('list.oe')} onClick2={this.obtainListWithOE}
                  fullActive={true} tooltip1={t('list.obtain_fotograms')} tooltip2={t('list.obtain_oe')}/>
                <Button text={t('list.compare')} active={this.state.fotograms.length !== 1} onClick={this.compare} />
              </div>
            </Fragment>
            : <Fragment>
              <div className='noResultsContainer'>
                <p>{t('list.no_results')}</p>
              </div>
            </Fragment>
          }
        </div>
        {modalOption === 'print' ?
          <CustomModal open={open} onClose={this.onCloseModal} title={printSelected.certifiable ? t('list.print') : t('list.print_only')} printing={true} mobile={mobile} minimize={this.minimizePrint} minimized={modalMinimized}>
            <PrintSelection acceptPrint={this.acceptPrint} sendToPrint={this.sendToPrint} scales={printSelected.scales} certifiable={printSelected.certifiable} printPaper={printSelected.printPaper} printSizes={printSelected.printSizes} />
          </CustomModal>
          : modalOption === 'info' ?
            <CustomModal open={open} onClose={this.onCloseModal} title={t('list.info')}>
              <Info variant='fotogram' selected={this.state.selectedInfo} />
            </CustomModal>
            : modalOption === 'compare-selector' ?
              <CustomModal open={open} onClose={this.onCloseModal} title={t('list.compare_fotograms')}>
                <CompareSelector flights={flights} fotograms={this.state.fotograms} getFlightOfFotogram={this.getFlightOfFotogram} openComparator={this.openComparator} />
              </CustomModal>
              : modalOption === 'compare' ?
                <CustomModal open={open} onClose={this.onCloseModal} title={t('list.compare_fotograms')}>
                  <div id='compareContainer' className='compare-container' style={mobile ? {height: 'calc('+(window.innerHeight-50)+'px - 2.4rem)'} : {}}>
                  </div>
                </CustomModal>
                : <CustomModal open={open} onClose={this.onCloseModal}>
                  <Warning>
                    {modalOption === 'wait' ?
                      <p style={{ width: '160px', textAlign: 'center' }}>{t('list.please_wait')}</p>
                      : modalOption === 'sure-delete' ?
                        <div>
                          <p style={{ width: '260px', textAlign: 'center' }}>{flights.length > 1 ? t('list.sure_delete_1') : t('list.sure_delete_2')}</p>
                          <div className='listButtonsContainer row' style={{ marginBottom: '-1rem' }}>
                            <Button text={t('list.delete')} active={true} onClick={this.deleteAll} />
                          </div>
                        </div>
                        : modalOption === 'error-area-selected' ?
                          <p style={{ width: '160px', textAlign: 'center' }}>{t('list.not_area')}</p>
                          : modalOption === 'error-printing' ?
                            <p style={{ width: '160px', textAlign: 'center' }}>{t('list.error')}</p>
                            : modalOption === 'error-printing2' ?
                              <p style={{ width: '160px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: t('list.error2') }}></p>
                              : <p style={{ width: '160px', textAlign: 'center' }}>{t('list.under_develop')}</p>}
                  </Warning>
                </CustomModal>}
      </Fragment>
    );
  }
}

export default translate()(List);
