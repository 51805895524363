import React, { Component } from 'react';
import { translate } from 'react-i18next';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';

import './Button.css';

class Button extends Component {

  render() {
    const { text, active, onClick, onClick2, text1, text2, fullActive, tooltip1, tooltip2 } = this.props;

    if (onClick2 !== undefined && text1 !== undefined && text2 !== undefined) {
      if (fullActive === true) {
        return (
          <DropdownMenu trigger={text} testId='dropdown-button' zIndex={99999999}>
            <DropdownItemGroup>
              <DropdownItem onClick={onClick} isDisabled={!active} title={tooltip1}>{text1}</DropdownItem>
              <DropdownItem onClick={onClick2} isDisabled={!active} title={tooltip2}>{text2}</DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        );
      } else {
        return (
          <DropdownMenu trigger={text} testId='dropdown-button' zIndex={99999999}>
            <DropdownItemGroup>
              <DropdownItem onClick={onClick} isDisabled={!active}>{text1}</DropdownItem>
              <DropdownItem onClick={onClick2}>{text2}</DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        );
      }
    } else {
      let style = {
        color: 'white',
        backgroundColor: '#364B5F',
      };

      if (active === false) {
        style.color = '#364B5F';
        style.backgroundColor = '#e6e6e6';
      }

      return (
        <div className='underListButton' onClick={active !== false ? onClick : undefined} style={style}>
          {text}
        </div>
      );
    }
  }
}

export default translate()(Button);
