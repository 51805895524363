import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import Marker from 'static/img/marker.svg';
import MarkerSelected from 'static/img/marker_selected.svg';

import './Flight.css';

class Flight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      mobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
    };
  }

  hover = () => {
    if (!this.state.mobile) {
      this.setState({ hovered: !this.state.hovered });
    }
  }

  render() {
    const { id, title, subtitle, text, selected, selectFlight, position, last, collapsed } = this.props;
    const active = selected || this.state.hovered;
    const tooltipStyle = {
      padding: '5px',
      maxWidth: '350px',
      zIndex: 999999,
      whiteSpace: 'break-spaces',
    };

    let place = 'top';
    let offset = {};
    if (position === 0 || position === 1) {
      place = 'right';
      offset = { top: '40px', left: '10px' };
    } else if (position === last || position === last - 1) {
      place = 'left';
      offset = { top: '40px', right: '10px' };
    }

    return (
      <Fragment>
        <div id={'flightId' + id } className='flight' onClick={selectFlight} style={{ display: collapsed ? 'none' : 'inline-block' }}
          onMouseOver={this.hover} onMouseOut={this.hover} data-tip data-for={'flightId' + id + 'tooltip'}>
          <div className='marker row'>
            <img src={active ? MarkerSelected : Marker} alt='Marker icon'/>
          </div>
          <div className='textContainer row'>
            <div style={{ justifyContent: 'center' }} className='row'>
              <div className='title' style={{ color: active ? '#fff' : '#b9b9b9', width: '100%' }}>
                {title}</div>
              <div className='subtitle'>{subtitle}</div>
            </div>
          </div>
        </div>
        {!this.state.mobile ?
        <ReactTooltip className={'onTop'} id={'flightId' + id + 'tooltip'} effect='solid' place={place} offset={offset}>
          <div style={tooltipStyle}>
            <span><b>{title} {subtitle}:</b></span>
            <br/>
            <span>{text}</span>
          </div>
        </ReactTooltip>
        : null}
      </Fragment>
    );
  }
}

export default translate()(Flight);
