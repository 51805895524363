export default {
  actions: {
    max_size_file: 'The selected file exceeds the maximum size of * MB.',
    file_type: 'The type of file selected is not supported.',
    warning: 'Warning',
    search_fotograms: 'Photograph query',
    using_geometry: 'Search by local geometry file',
    allowed_formats: 'Supported formats: .shp compressed in .zip, .kml, .gpx and .geojson.',
    srs: 'The SRS will be adapted to the viewer.',
    tools: 'Search photographs',
    view_extend: 'Search in the current view',
    polygons: 'Search by polygon',
    mtn_sheet: 'Search by MTN Sheet',
    remove_criteria: 'Remove search criteria',
    load_selection: 'List photographs',
    available_scale_1: 'This tool is enabled on a scale',
    available_scale_2: 'or in greater detail (province level).',
    sheet_not_found: 'Sheet not found, check the query.',
    select_flight: 'This tool will be enabled when a flight is selected.',
    under_develop: 'In development...',
  },
  checkbox: {
    select: 'Select',
    quit: 'Remove selection',
  },
  filters: {
    search_flights: 'Search flights',
    text: 'Text to search',
    flight_type: 'Flight type',
    select: 'Select',
    analogic: 'Analog',
    digital: 'Digital',
    scale: 'Scale',
    date: 'Flight year',
    search: 'Search',
    clean: 'Clean',
    please: 'Please enter some search criteria.',
    check: 'Check that the flight scale and year are in the correct format.',
    gsd: 'GSD (Pixel ground size in cm)',
    export: 'Export',
    mtn_sheet: 'Search by MTN Sheet',
    view_extend: 'Search in the current view',
    on_view: 'On the view',
  },
  footer: {
    no_coverage: 'There is no coverage of this flight in the view',
    historical: 'HISTORICAL',
    pnoa: 'PNOA',
    photos: 'PHOTOGRAPHS',
    ortophotos: 'ORTHOPHOTOS',
  },
  header: {
    title: 'Digital Fototeca',
    subtitle: 'Aerial Photograph Viewer',
    change_language: 'Change language',
    accept: 'Accept',
    cart: 'Shopping cart',
    last_news: 'Digital Fototeca News',
    warning: 'Warning',
    empty_cart: 'No certifications have been added to the shopping cart',
  },
  info: {
    fotogram: 'Photograph',
    flight: 'Flight',
    available_formats: 'Formats Availability',
    oe: 'E. O.',
    extension: 'Extension',
    coverage: 'Coverage',
    file_name: 'File name',
    format: 'Format',
    code: 'Code',
    past: 'Strip',
    date: 'Date',
    x_coord: 'Center X-coordinate',
    y_coord: 'Center Y-coordinate',
    pixel_size: 'Image pixel size',
    name: 'Name',
    type: 'Type',
    description: 'Description',
    date_flight: 'Flight date',
    observations: 'Remarks',
    aux_info: 'Auxiliary information',
    scale: 'Scale',
    yes: 'Yes',
    no: 'No',
    length_cover: 'Longitudinal overlap',
    trans_cover: 'Transverse overlap',
    pixel_size_terrain: 'Pixel ground size',
    no_extension: 'This flight does not have an associated extension',
    tiff_rgb: 'RGB TIF',
    tiff_rgbi: 'RGBI TIF',
    tiff_ir: 'IR TIF',
    tiff_irg: 'IRG TIF',
    tiff_pan: 'Panchromatic TIF',
    comp_rgb: 'RGB compressed format',
    comp_rgbi: 'RGBI compressed format',
    comp_ir: 'IR compressed format',
    comp_irg: 'IRG compressed format',
    comp_pan: 'Panchromatic compressed format',
    photos: 'Aerial photographs',
    ortophotos: 'Orthophotos',
    centimeters: 'centimeters',
    microns: 'microns',
    copy: 'Copy',
    availability: 'The service offered by this viewer only allows downloading frames in <b>compressed format with RGB bands.</b> All other formats can be requested by e-mail to <a href="mailto:fototeca@cnig.es">fototeca@cnig.es</a>',
    oe_parameters: 'External Orientation Parameters (AT)',
    oe_text: 'Data extracted from orthophoto generation processes.<br/> Additionally, there is a <a href="*" target="_blank" rel="noopener noreferrer">OGC-API</a> service that allows to obtain the External Orientation data of a set of frames.',
  },
  list: {
    my_list: 'My photographs list',
    fotograms: 'Photographs',
    opacity: 'Opacity',
    actions: 'Actions',
    selected: 'Selected photographs',
    downloadables: 'Photographs to download',
    compare: 'Compare',
    compare_fotograms: 'Photographs Comparison',
    remove: 'Delete',
    download: 'Download',
    obtain: 'Obtain list',
    print: 'Save to PDF and certify',
    print_only: 'Print',
    info: 'Photograph Information',
    please_wait: 'Please wait...',
    not_area: 'There is no photograph in the selected area',
    error: 'An error occurred while printing. Please try again in a few minutes and if the error persists contact us.',
    error2: 'It has not been possible to perform the certification. Please, contact us by e-mail at <a href="mailto:fototeca@cnig.es">fototeca@cnig.es</a>',
    under_develop: 'In development...',
    products_added: 'Some products have been added to the cart',
    warning: 'Warning',
    available_flights: 'Available flights',
    delete: 'Delete',
    sure_delete_1: 'You are about to delete all photographs from the list and there are photographs from several flights, are you sure you want to continue?',
    sure_delete_2: 'You are about to delete all photographs from the list, are you sure you want to continue?',
    remove_selection: 'Delete selection',
    remove_all: 'Delete all',
    some_downloaded: 'Some of the photographs consulted do not have external orientation parameters',
    none_downloaded: 'None of the consulted photographs has external orientation parameters',
    oe_error: 'The external orientation parameters could not be consulted',
    oe: 'External Orientation',
    obtain_fotograms: 'Obtain the list of photographs',
    obtain_oe: 'Obtain the list of EO parameters of the photographs',
    no_results: 'Your frame list is empty. Use the search tools.',
    show_fotogram: 'The fotogram is visible',
  },
  menu: {
    search_on_view: 'Search photographs',
    close: 'Close',
  },
  mtnSelector: {
    grid: 'Grid',
    number: 'Number',
    quarter: 'Quarter',
    show_sheet: 'Show Sheet',
    number_50: 'MTN50 Sheet Number',
  },
  pagination: {
    of: ' of ',
  },
  printSelection: {
    size: 'Size',
    info: 'Information',
    step1: '1. Click on the box.',
    step2: '2. Drag the box to the area.',
    step3: '3. Drop the box.',
    paper: 'Paper',
    scale: 'Scale',
    custom: 'Custom',
    orientation: 'Orientation',
    vertical: 'Portrait',
    horizontal: 'Landscape',
    request_certificate: 'Request Certificate',
    digital: 'Digital',
    accept: 'Accept',
    certificate_text: 'This service is optional and has an <a target="_blank" rel="noopener noreferrer" href="https://www.ign.es/web/resources/fototeca/precios-fototeca.pdf">additional cost</a>. To certify the document choose Paper or Digital.<br/><br/>Once the certificate has been requested, select the shopping cart icon to view your order in our Virtual Store.',
  },
  results: {
    analogic: 'Analog',
    digital: 'Digital',
    search_data: 'Flight Information',
    results: 'Results',
    name: 'Name',
    type: 'Type',
    actions: 'Actions',
    flight_info: 'Flight Information',
    year: 'Year',
    scale: 'Scale / GSD',
    check_available: 'Check flight observations to check if it is available',
  },
  searchMenu: {
    available_flights: 'Search for flights in the Fototeca Archive',
    close: 'Close',
    other_flights: 'Other flights',
  },
  tableRow: {
    fotogram: 'Photograph',
    flight: 'Flight',
    show: 'Show',
    hide: 'Hide',
    info: 'Consult data',
    award: 'Print / Certify',
    delete: 'Delete',
    zoom: 'Zoom to image',
  },
  compareSelector: {
    compare: 'Open comparator',
    text_description: 'Select the photographs of a flight on each screen for comparison',
    screen1: 'Screen 1',
    screen2: 'Screen 2',
    show: 'Show',
    hide: 'Hide',
  },
  visor: {
    coordinates: 'Coordinates',
    zoom_extent: 'Zoom to the map extension',
    share_url: 'Share URL',
    copied: 'Copied',
    accept: 'Accept',
    copy: 'Copy',
    wrong_format: 'You have entered a file with an invalid format.',
    no_geom: 'No geometries detected in this file.',
    max_allowed1: 'Files are not supported if they have more than',
    max_allowed2: 'different geometries.',
    area_limit: 'The query area exceeds the allowed limit.',
    error_loading: 'Error loading file. Check that this is the correct file.',
    file: 'File',
    aprox_position: 'approximate position',
    photo: 'Photo',
    american_flight: 'American Flight',
    national_flight: 'National Flight',
    interministerial_flight: 'Interministerial Flight',
    cadastre: 'Cadastre',
    mtn25: 'MTN25 cartographic grid',
    mtn50: 'MTN50 cartographic grid',
    administrative_units: 'Administrative Units',
    printed: 'Print generated from Digital Fototeca https://fototeca.cnig.es/',
    coverage: "Checking coverage in the area...",
    no_coverage: "This layer has no coverage in this area",
    warning: "WARNING",
  },
  signin: {
    admin_access: 'ADMINISTRATION ACCESS',
    user: 'User',
    password: 'Password',
    access: 'Sign in',
    login_success_title: 'Correct',
    login_success: 'Successfully logged in',
    error: 'Error',
    login_error: 'Failed to log in, check login details',
    empty_fields: 'Fill in the fields to log in',
  },
  administration: {
    dashboard: 'DASHBOARD',
    flights: 'FLIGHTS',
    mosaics: 'ORTHOPHOTOS',
    backgroundlayers: 'BACKGROUND LAYERS',
    additionallayers: 'ADDITIONAL LAYERS',
    help: 'HELP',
    others: 'TOOLS',
    logout: 'LOGOUT',
  },
  backgroundlayerForm: {
    type: 'Type',
    name: 'Name',
    title: 'Title (es)',
    titleEn: 'Title (en)',
    maxZoom: 'Maximum zoom for tile loading',
    imageFormat: 'Image format',
    thumbnail: 'Thumbnail URL',
    save: 'SAVE',
    error: '*There are unfilled fields',
  },
  backgroundlayers: {
    edit: 'Edit',
    remove: 'Remove',
    backgroundlayers: 'BACKGROUND LAYERS',
    thumbnail: 'Thumbnail',
    type: 'Type',
    title: 'Title (es)',
    titleEn: 'Title (en)',
    name: 'Name',
    imageFormat: 'Image format',
    maxZoom: 'Max. zoom for tile loading',
    actions: 'Actions',
    addLayer: 'ADD BACKGROUND LAYER',
    layerData: 'BACKGROUND LAYER DATA',
    up: 'Move up',
    down: 'Move down',
    error: 'Error',
    load_error: 'Unable to load data',
    edit_error: 'Modifications could not be made',
    save_error: 'The base layer data could not be saved.',
    delete_error: 'Unable to remove the base layer',
    saved: 'Saved',
    changes_saved: 'Modifications successfully completed',
    created: 'The new base layer has been saved',
    removed: 'The base layer has been removed correctly',
  },
  flightForm: {
    historic: 'Historical',
    group: 'Group',
    title: 'Title',
    subtitle: 'Subtitle',
    descriptionEs: 'Description (es)',
    descriptionEn: 'Description (en)',
    wmsName: 'Layer name in WMS server',
    fileName: 'GEOJSON file name',
    certifiable: 'Is it certifiable?',
    printable: 'Is it printable on paper?',
    addValue: 'Add value',
    keys: 'Keywords to locate flight photograph',
    scales: 'Available print scales',
    sizes: 'Available print sizes',
    save: 'SAVE',
    error: '*There are unfilled fields',
  },
  flightsConfig: {
    historic: 'HISTORICAL',
    up: 'Move up',
    down: 'Move down',
    seeCoverage: 'See coverage',
    edit: 'Edit',
    remove: 'Remove',
    flights: 'FLIGHTS',
    title: 'Title',
    subtitle: 'Subtitle',
    descriptionEs: 'Description (es)',
    descriptionEn: 'Description (en)',
    certifiable: 'Certifiable',
    scales: 'Print scales',
    sizes: 'Print sizes',
    printable: 'Printable on paper',
    actions: 'Actions',
    addFlight: 'ADD FLIGHT',
    flightCoverage: 'FLIGHT COVERAGE',
    flightData: 'FLIGHT DATA',
    groupData: 'GROUP DATA',
    groups: 'GROUPS',
    addGroup: 'ADD GROUP',
    error: 'Error',
    load_error: 'Unable to load data',
    edit_error: 'Modifications could not be made',
    save_error: 'Data could not be saved.',
    delete_error: 'Unable to remove data',
    saved: 'Saved',
    changes_saved: 'Modifications successfully completed',
    created: 'The data has been saved',
    removed: 'The data has been removed correctly',
    warning: 'Warning',
    group_not_empty: 'The group cannot be deleted, it has associated flights',
    name: 'Name',
    show: 'Show',
    hide: 'Hide',
  },
  help: {
    help: 'HELP',
    textEs: 'Text in Spanish',
    textEn: 'Text in English',
    saveChanges: 'SAVE CHANGES',
    saved: 'Saved',
    changes_saved: 'Modifications successfully completed',
    error: 'Error',
    save_error: 'Unable to save modifications',
  },
  mosaicForm: {
    historic: 'Historical',
    group: 'Group',
    legend: 'Legend',
    name: 'Name',
    version: 'Version',
    descriptionEs: 'Description (es)',
    descriptionEn: 'Description (en)',
    save: 'SAVE',
    error: '*There are unfilled fields',
  },
  mosaicsConfig: {
    historic: 'HISTORICAL',
    up: 'Move up',
    down: 'Move down',
    edit: 'Edit',
    remove: 'Remove',
    mosaics: 'ORTHOPHOTOS',
    legend: 'Legend',
    name: 'Name',
    version: 'Version',
    descriptionEs: 'Description (es)',
    descriptionEn: 'Description (en)',
    order: 'Order',
    actions: 'Actions',
    addMosaic: 'ADD ORTHOPHOTO',
    mosaicData: 'ORTHOPHOTO DATA',
    groupData: 'GROUP DATA',
    groups: 'GROUPS',
    addGroup: 'ADD GROUP',
    error: 'Error',
    load_error: 'Unable to load data',
    edit_error: 'Modifications could not be made',
    save_error: 'Data could not be saved.',
    delete_error: 'Unable to remove data',
    saved: 'Saved',
    changes_saved: 'Modifications successfully completed',
    created: 'The data has been saved',
    removed: 'The data has been removed correctly',
    warning: 'Warning',
    group_not_empty: 'The group cannot be deleted, it has associated orthophotos',
    show: 'Show',
    hide: 'Hide',
  },
  others: {
    others: 'TOOLS',
    saveChanges: 'SAVE CHANGES',
    searchTools: 'Photogram search tools',
    searchByView: 'Search in the view',
    searchByDraw: 'Search by polygon',
    searchByMTN: 'Search by MTN sheet',
    searchByFile: 'Search by local file',
    photogramActions: 'Photogram options',
    photogramViewHide: 'Show / Hide',
    photogramInfo: 'Consult data',
    photogramZoom: 'Zoom',
    photogramCertify: 'Print / Certify',
    mapTools: 'Map tools',
    mousesrs: 'Mouse pointer coordinates',
    scale: 'Scale control',
    sharemap: 'Share',
    calendar: 'Request an appointment',
    help: 'More information',
    infocoordinates: 'Coordinate information',
    printermap: 'Print',
    measurebar: 'Measurements',
    zoompanel: 'Zoom panel',
    ignsearch: 'Search',
    saved: 'Saved',
    changes_saved: 'Modifications successfully completed',
    error: 'Error',
    save_error: 'Unable to save modifications',
    showup: 'Show up',
    returnmap: 'Return to map',
    showfilters: 'Show filters',
    hidefilters: 'Hide filters',
  },
  rss: {
    subscribe: 'Subscribe',
    filter: 'Filter',
    search: 'Search for content',
    clear: 'Clear',
    from: 'From',
    to: 'To',
    no_results: 'No results found',
  },
  additionallayers: {
    edit: 'Edit',
    remove: 'Remove',
    additionallayers: 'ADDITIONAL LAYERS',
    title: 'Title (es)',
    titleEn: 'Title (en)',
    name: 'Name',
    version: 'Version',
    queryable: 'Queryable',
    tiled: 'Tiled',
    actions: 'Actions',
    addLayer: 'ADD ADDITIONAL LAYER',
    layerData: 'ADDITIONAL LAYER DATA',
    up: 'Move up',
    down: 'Move down',
    error: 'Error',
    load_error: 'Unable to load data',
    edit_error: 'Modifications could not be made',
    save_error: 'The additional layer data could not be saved.',
    delete_error: 'Unable to remove the additional layer',
    saved: 'Saved',
    changes_saved: 'Modifications successfully completed',
    created: 'The new additional layer has been saved',
    removed: 'The additional layer has been removed correctly',
  },
  additionallayerForm: {
    name: 'Name',
    title: 'Title (es)',
    titleEn: 'Title (en)',
    version: 'Version',
    queryable: 'Is it queryable?',
    tiled: 'Should it be loaded with tiles?',
    save: 'SAVE',
    error: '*There are unfilled fields',
  },
  rssconfig: {
    edit: 'Edit',
    remove: 'Remove',
    title: 'Title',
    description: 'Description',
    pubDate: 'Publication date',
    addNew: 'ADD NEW',
    newData: 'NEW DATA',
    actions: 'Actions',
    error: 'Error',
    load_error: 'Unable to load data',
    edit_error: 'Modifications could not be made',
    save_error: 'The news item could not be saved.',
    delete_error: 'Unable to remove the news item',
    saved: 'Saved',
    changes_saved: 'Modifications successfully completed',
    created: 'The news item has been saved',
    removed: 'The news item has been successfully removed',
  },
  rssconfigform: {
    title: 'Title',
    description: 'Description',
    save: 'SAVE',
    error: '*There are unfilled fields',
  },
  groupform: {
    name: 'Name',
    save: 'SAVE',
    error: '*There are unfilled fields',
  },
  shoppingCart: {
    delete: 'Delete',
    product: 'Product',
    fotogram: 'Photograph',
    actions: 'Actions',
    buy: 'Process purchase',
    show: 'View',
  },
};
