import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { info } from 'react-icons-kit/fa/info';
import { close } from 'react-icons-kit/fa/close';
import { award } from 'react-icons-kit/feather/award';
import { ic_fit_screen } from 'react-icons-kit/md/ic_fit_screen';
import { ic_remove_red_eye } from 'react-icons-kit/md/ic_remove_red_eye';
import { ic_visibility_off } from 'react-icons-kit/md/ic_visibility_off';
import Slider from 'react-rangeslider';
import ReactTooltip from 'react-tooltip';

import CheckBox from 'components/CheckBox/CheckBox';
import ActionButton from 'components/ActionButton/ActionButton';
import { isCustomWMSLayerActive, getCustomWMSLayerOpacity, } from 'utils/Visor';

import './TableRow.css';

declare var CONFIGURATIONS;

class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: getCustomWMSLayerOpacity(this.props.data)*10,
      mobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ value: getCustomWMSLayerOpacity(nextProps.data)*10 });
    }
  }

  handleChange = (newValue) => {
    this.props.changeOpacity(newValue/10);
    this.setState({ value: newValue });
  }

  handleMouseDown = () => {
    window.isMouseDown = true;
    if (window.event.shiftKey) {
      this.props.select();
    }

    return false;
  }

  handleMouseOver = () => {
    if (window.isMouseDown && window.event.shiftKey) {
      this.props.select();
    }
  }

  render() {
    const { t, data, isSelected, select, showInfoPopup, deleteFotogram, openHideFotogram,
      printFotogram, zoomFotogram, flight, configurations, } = this.props;
    const opened = isCustomWMSLayerActive(data);
    const name = data !== undefined ? data.substring(data.lastIndexOf('/') + 1, data.lastIndexOf('.')) : '-';

    return (
      <tr className='tableRow'>
        <td className='tableRowCell cellCheckbox'>
          <CheckBox id={'tableRowCB' + data} onClick={select} isSelected={isSelected} />
        </td>
        <td className='tableRowCell tableRowFotogramName' data-tip data-for={name + 'tooltip'} onMouseDown={this.handleMouseDown} onMouseOver={this.handleMouseOver}>
          {name}
          {!this.state.mobile ?
          <ReactTooltip id={name + 'tooltip'} effect='solid' place='right'>
            <div>
              <p>{t('tableRow.fotogram')}: <b>{name}</b></p>
              <p>{t('tableRow.flight')}: <b>{flight.title + ' ' + flight.subtitle}</b></p>
            </div>
          </ReactTooltip>
          : null}
        </td>
        <td className='tableRowCell opacity'>
          { opened ?
            <Slider min={0} max={10} step={1} orientation='horizontal'
              value={this.state.value} tooltip={false} onChange={this.handleChange} />
          : <span>-</span>}
        </td>
        <td className='tableRowCell buttons'>
          <div className='buttonsContainerTableRow'>
            { configurations.photogramViewHide === true ?
              <ActionButton id={'tableRowShow' + data} icon={opened === false ? ic_visibility_off : ic_remove_red_eye} size={'18px'} color={'#fda823'} tooltip={opened === false ? t('tableRow.show') : t('tableRow.hide')} onClick={openHideFotogram} />
            : null }
            { configurations.photogramInfo === true ?
              <ActionButton id={'tableRowInfo' + data} icon={info} size={'18px'} color={'#fda823'} tooltip={t('tableRow.info')} onClick={showInfoPopup} />
            : null }
            { configurations.photogramZoom === true ?
              <ActionButton id={'tableRowZoom' + data} icon={ic_fit_screen} size={'18px'} color={'#fda823'} tooltip={t('tableRow.zoom')} innerStyle={{ marginRight: '4px', marginBottom: '4px' }} onClick={zoomFotogram} />
            : null }
            { configurations.photogramCertify === true ?
              <ActionButton id={'tableRowPrint' + data} icon={award} size={'18px'} color={'#198d18'} tooltip={t('tableRow.award')} onClick={printFotogram} />
            : null }
            <ActionButton id={'tableRowDelete' + data} icon={close} size={'18px'} color={'#ff0000'} tooltip={t('tableRow.delete')} onClick={deleteFotogram} />
          </div>
        </td>
      </tr>
    );
  }
}

export default translate()(TableRow);
