import Utils from 'utils/Utils';
import { mapjs } from 'utils/Visor';

declare var CONFIGURATIONS;

export default class ApiService {

  static getPrintRequest = (title, center, layer, fotogram, template, scale, flightDate, flightScale) => {
    let desc = 'Este documento carece de validez legal sin la certificación del O.A. Centro Nacional de Información Geográfica';
    if (fotogram.indexOf('ruiz_alda') > -1 || fotogram.indexOf('vuelo_americano_serie_a_ecw') > -1 || fotogram.indexOf('FOTOGRAMAS_vuelo_ecw_fechas_fotocentros_grafico') > -1 || fotogram.indexOf('VUELO_AMERICANO_SERIE_C') > -1) {
      desc = 'Este documento carece de validez legal al no pertenecer el fotograma al O. A. Centro Nacional de Información Geográfica.';
    }

    const request = {
      layout: template,
      // eslint-disable-next-line
      outputFilename: 'print_${yyyy-MM-dd_hhmmss}',
      attributes: {
        title: title,
        description: desc,
        printDate: Utils.formatDatePrint(new Date()),
        flightDate: flightDate,
        flightScale: flightScale,
        logoCNIG: 'file://logo_ign.jpg',
        norte: 'file://north.png',
        map: {
          dpi: 120,
          projection: mapjs.getProjection().code,
          useAdjustBounds: true,
          center: center,
          scale: parseInt(scale, 10),
          layers: [
            {
              baseURL: CONFIGURATIONS.fotograms_layer_url,
              opacity: 1,
              type: 'WMS',
              layers: [`imagen${layer}`],
              imageFormat: 'image/png',
              styles: [''],
              customParams: {
                TRANSPARENT: 'true',
                IMAGEN: fotogram,
              }
            },
          ],
        },
      },
    };

    return request;
  }

}
