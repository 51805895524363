import React, { Component } from 'react';
import { feed } from 'react-icons-kit/fa/feed'
import { Icon } from 'react-icons-kit';
import { translate } from 'react-i18next';
import DatePicker from 'react-date-picker';

import Button from 'components/Button/Button';

import Utils from 'utils/Utils';
import LocalStorage from 'utils/LocalStorage';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './RSS.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class RSS extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: LocalStorage.getString('language') || 'es',
      allData: this.props.data,
      entries: this.props.data.entries,
      startDate: null,
      endDate: null,
      text: '',
      filterActive: false,
      filtered: false,
    };
  }

  onChangeStartDate = (value) => {
    const { endDate, text } = this.state;
    this.setState({ startDate: value, filterActive: value !== null || endDate !== null || text !== '' });
  }

  onChangeEndDate = (value) => {
    const { startDate, text } = this.state;
    this.setState({ endDate: value, filterActive: value !== null || startDate !== null || text !== '' });
  }

  onChangeInput = (evt) => {
    const { startDate, endDate } = this.state;
    const text = evt.target.value;
    if (text.trim().length >= 3) {
      this.setState({ text, filterActive: true });
    } else if (startDate === null && endDate === null) {
      this.setState({ text: '', filterActive: false });
    }
  }

  filter = () => {
    const { allData, startDate, endDate, text } = this.state;
    const newEntries = allData.entries.filter((e) => {
      let res = false;
      const entryDate = new Date(e.fullDate).getTime();
      const content = e.content;
      if (text !== '' && (Utils.stringContainsString(e.title, text) || Utils.stringContainsString(content, text))) {
        res = true;
      } else if (text === '') {
        res = true;
      }

      if (startDate !== null) {
        const start = new Date(startDate).getTime();
        res = res && entryDate >= start;
      }

      if (endDate !== null) {
        const end = new Date(endDate);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        res = res && entryDate <= end.getTime();
      }

      return res;
    });

    this.setState({ entries: newEntries, filtered: true });
  }

  clean = () => {
    document.querySelector('.rss-filters-container .text-filter').value = '';
    document.querySelectorAll('.react-date-picker__clear-button.react-date-picker__button').forEach((elem) => {
      elem.click();
    });

    this.setState({ text: '', filtered: false, entries: this.state.allData.entries, filterActive: false });
  }

  render() {
    const { t } = this.props;
    const { lang, allData, entries, startDate, endDate, filterActive, filtered } = this.state;
    const entriesRows = [];
    entries.forEach((e, index) => {
      entriesRows.push(
        <React.Fragment key={`entry${index}`}>
          <div className='entry'>
            <p className='entry-title'>{e.title}</p>
            <p className='entry-date'>{e.date}</p>
            <div className='entry-content'dangerouslySetInnerHTML={{ __html: `<div>${e.content}</div>` }}></div>
          </div>
          <br/>
          { index !== entries.length - 1 ?
            <hr className='divider'/>
          : null }
          <br/>
        </React.Fragment>
      );
    });

    return (
      <div className='rss-content'>
        <p className='title'>{allData.title}</p>
        <div className='link'>
          <a target='_blank' rel='noopener noreferrer' href={API_REST + 'api/new/rss'}>{t('rss.subscribe')} <span style={{ color: '#fda823' }}><Icon size={'18px'} icon={feed} /></span></a>
        </div>
        <div className='rss-filters-container'>
          <input className='text-filter' type='text' onKeyUp={this.onChangeInput} placeholder={t('rss.search')} />
          <div className='rss-from-container'>
          <span>{t('rss.from')}</span>
          <DatePicker onChange={this.onChangeStartDate} value={startDate} format='dd-MM-y' locale={lang} minDetail='decade' calendarIcon={null} />
          </div>
          <div className='rss-to-container'>
          <span>{t('rss.to')}</span>
          <DatePicker onChange={this.onChangeEndDate} value={endDate} format='dd-MM-y' locale={lang} minDetail='decade' calendarIcon={null} />
          </div>
          <Button text={t('rss.filter')} active={filterActive} onClick={this.filter} />
          { filtered ?
            <Button text={t('rss.clear')} active={true} onClick={this.clean} />
          : null }
        </div>
        <div className='rss-entries-container'>
          {entries.length > 0 ?
            entriesRows
          : <p>{t('rss.no_results')}</p> }
        </div>
      </div>
    );
  }
}

export default translate()(RSS);
