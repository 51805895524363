import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Icon } from 'react-icons-kit';
import { ic_check_box } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank } from 'react-icons-kit/md/ic_check_box_outline_blank';
import ReactTooltip from 'react-tooltip';

import './CheckBox.css';

class CheckBox extends Component {

  render() {
    const { t, isSelected, onClick, id, all } = this.props;

    if (id !== undefined) {
      return (
        <React.Fragment>
          <span data-tip data-for={id + 'tooltip'} onClick={onClick} className={ isSelected ? 'checked' : 'notChecked' }>
            <Icon size={'24px'} icon={ isSelected ? ic_check_box : ic_check_box_outline_blank }/>
          </span>
          <ReactTooltip className='check-tooltip' id={id + 'tooltip'} effect='solid' place='right'>
            <span>{isSelected ? t('checkbox.quit') : t('checkbox.select')}</span>
          </ReactTooltip>
        </React.Fragment>
      );
    } else if (all === true) {
      return (
        <React.Fragment>
          <span onClick={onClick} className={ isSelected ? 'checked' : 'notChecked' }>
            <Icon size={'24px'} icon={ isSelected ? ic_check_box : ic_check_box_outline_blank }/>
          </span>
        </React.Fragment>
      );
    } else {
      return (
        <span onClick={onClick} className={ isSelected ? 'checked' : 'notChecked' }>
          <Icon size={'24px'} icon={ isSelected ? ic_check_box : ic_check_box_outline_blank }/>
        </span>
      );
    }
  }
}

export default translate()(CheckBox);
