import axios from 'axios';

declare var CONFIGURATIONS;

const checkStatus_ = (response) => {
  // raises an error in case response status is not a success
  if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export default class ApiService {

  static getRequest = (url) => {
    const headers = {};
    headers['Accept-Language'] = 'es';

    return axios({
      method: 'get',
      url,
      headers,
    }).then(checkStatus_).then(response => response.data);
  };

  static postRequest = (url, data) => {
    const headers = {};
    headers['Accept-Language'] = 'es';

    return axios({
      method: 'post',
      url,
      headers,
      data: data,
    }).then(checkStatus_);
  };

  static putRequest = (url, data) => {
    const headers = {};
    headers['Accept-Language'] = 'es';

    return axios({
      method: 'put',
      url,
      headers,
      data: data,
    }).then(checkStatus_);
  };

  static deleteRequest = (url) => {
    const headers = {};
    headers['Accept-Language'] = 'es';

    return axios({
      method: 'delete',
      url,
      headers,
    }).then(checkStatus_);
  };

  static downloadRequest = (url, responseType) => {
    const headers = {};
    headers['Accept-Language'] = 'es-ES,es;q=0en';
    headers['Accept'] = 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8';

    return axios({
      method: 'get',
      url,
      headers,
      responseType: responseType || 'stream'
    }).then(checkStatus_);
  };

}
