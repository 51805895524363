import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { trashO } from 'react-icons-kit/fa/trashO';
import { download } from 'react-icons-kit/fa/download';

import Button from 'components/Button/Button';
import ActionButton from 'components/ActionButton/ActionButton';

import Utils from 'utils/Utils';

import './ShoppingCart.css';

class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shoppingCart: this.props.shoppingCart || [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ shoppingCart: nextProps.shoppingCart });
    }
  }

  removeFromCart = (item) => {
    const newCart = this.state.shoppingCart.filter((sc) => {
      return !(sc.fotogram === item.fotogram && sc.identifier === item.identifier);
    });

    this.props.updateShoppingCart(newCart);
    if (newCart.length === 0) {
      this.props.closeModal();
    }
  }

  openPDF = (item) => {
    window.open(item.url);
  }

  render() {
    const { t, buyCart } = this.props;
    const { shoppingCart } = this.state;
    const renderResults = [];
    shoppingCart.forEach((item, index) => {
      renderResults.push(
        <tr className='tableRow' key={'scp' + index}>
          <td className='tableRowCell'>{Utils.getFormattedNumber(index + 1, 3)} - {item.product || '-'}</td>
          <td className='tableRowCell'>{item.fotogram.split('/').slice(-1)[0].split('.')[0] || '-'}</td>
          <td className='tableRowCell'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ActionButton id={'scpOpen' + index} icon={download} size={'18px'} color={'#fda823'} tooltip={t('shoppingCart.show')} onClick={this.openPDF.bind(this, item)} />
              <ActionButton id={'scpDelete' + index} icon={trashO} size={'18px'} color={'#ff0000'} tooltip={t('shoppingCart.delete')} onClick={this.removeFromCart.bind(this, item)} />
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div className='cartContainer'>
        <div className='table'>
          <div className='tableContainer'>
            <table>
              <thead>
                <tr>
                  <th>{t('shoppingCart.product')}</th>
                  <th>{t('shoppingCart.fotogram')}</th>
                  <th>{t('shoppingCart.actions')}</th>
                </tr>
              </thead>
              <tbody>
                { renderResults }
              </tbody>
            </table>
          </div>
          <div className='cartButtonContainer'>
            <Button text={t('shoppingCart.buy')} active={true} onClick={buyCart} />
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ShoppingCart);
