import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { angleLeft } from 'react-icons-kit/fa/angleLeft';
import { angleRight } from 'react-icons-kit/fa/angleRight';
import { angleDoubleLeft } from 'react-icons-kit/fa/angleDoubleLeft';
import { angleDoubleRight } from 'react-icons-kit/fa/angleDoubleRight';


import ActionButton from 'components/ActionButton/ActionButton';

import './Pagination.css';

class Pagination extends Component {

  render() {
    const { t, activePage, total, handlePageChange, rows } = this.props;
    const totalPages = Math.ceil(total / rows);

    return (
      <table>
        <tbody>
          <tr>
            <td>
              <ActionButton icon={angleDoubleLeft} size={'24px'} color={'#364B5F'}
                disabled={activePage === 0} onClick={activePage > 0 ? handlePageChange.bind(null, 0) : undefined} />
            </td>
            <td>
              <ActionButton icon={angleLeft} size={'24px'} color={'#364B5F'} disabled={activePage - 1 < 0}
                onClick={activePage - 1 >= 0 ? handlePageChange.bind(null, activePage - 1) : undefined} />
            </td>
            <td className='paginationCell'>{(activePage + 1) + t('pagination.of') + (totalPages)}</td>
            <td>
              <ActionButton icon={angleRight} size={'24px'} color={'#364B5F'} disabled={activePage + 1 > totalPages - 1}
                onClick={activePage + 1 <= totalPages - 1 ? handlePageChange.bind(null, activePage + 1) : undefined} />
            </td>
            <td>
              <ActionButton icon={angleDoubleRight} size={'24px'} color={'#364B5F'} disabled={activePage === totalPages - 1}
                onClick={activePage < totalPages - 1 ? handlePageChange.bind(null, totalPages - 1) : undefined} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default translate()(Pagination);
