import React, { Component } from 'react';
import cookie from 'react-cookies';
import { translate } from 'react-i18next';
import { store } from 'react-notifications-component';

import Button from 'components/Button/Button';

import ApiService from 'services/ApiService';

import './Signin.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class Signin extends Component {

  login = () => {
    const { t } = this.props;
    const username = document.querySelector('#signinUsername');
    const password = document.querySelector('#signinPassword');
    if (username.value.trim() !== '' && password.value.trim() !== '') {
      const data = {
        username: username.value.trim(),
        password: password.value.trim(),
      };

      ApiService.postRequest(API_REST + 'api/login/', data)
      .then((response) => {
        const data = response.data;
        cookie.save('fototecaLogin', btoa(`${username.value.trim()}:${data.expiration}`), {
          path: '/',
          expires: new Date(data.expiration),
        });

        store.addNotification({
          title: t('signin.login_success_title'),
          message: t('signin.login_success'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });

        setTimeout(() => {
          window.location.href = '/fototeca/admin';
        }, 1000);
      }).catch((error) => {
        store.addNotification({
          title: t('signin.error'),
          message: t('signin.login_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      store.addNotification({
        title: t('signin.error'),
        message: t('signin.empty_fields'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }
  }

  enterKey = (evt) => {
    const keyCode = evt.charCode || evt.which;
    if (keyCode === 13) {
      this.login();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className='login-container'>
        <p>{t('signin.admin_access')}</p>
        <div className='login-input'>
          <label>{t('signin.user')}</label>
          <input type='text' id='signinUsername' />
        </div>
        <div className='login-input'>
          <label>{t('signin.password')}</label>
          <input type='password' id='signinPassword' onKeyPress={this.enterKey}/>
        </div>
        <div className='login-button-container'>
          <Button text={t('signin.access')} active={true} onClick={this.login} />
        </div>
      </div>
    );
  }
}

export default translate()(Signin);
