import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import Flight from 'components/Flight/Flight';
import Mosaic from 'components/Mosaic/Mosaic';
import Warning from 'components/Warning/Warning';
import CustomModal from 'components/CustomModal/CustomModal';
import LocalStorage from 'utils/LocalStorage';
import Utils from 'utils/Utils';
import {
  addFlightLayers, removeAllFlightLayers, newGeoJSONLayer, getBbox,
  isLayerIntersectingExtent, toogleVisibilityLayer, isVisibleLayer,
} from 'utils/Visor';

import './Footer.css';

declare var CONFIGURATIONS;

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selectedFlight,
      open: false,
      flights: this.props.flights,
      modalMessage: '',
      lang: LocalStorage.getString('language') || 'es',
      mode: 'flights',
      reload: false,
      collapsed: [],
      mobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ selected: nextProps.selectedFlight });
    }
  }

  selectFlight = (id) => {
    const { t } = this.props;
    const _this = this;
    const flight = Utils.arrayReturnObjectWithProperty(_this.state.flights, 'id', id);
    removeAllFlightLayers();
    if (flight.coverage !== undefined && _this.state.selected !== id) {
      this.props.block();
      Utils.getFile(flight.coverage).then((geojson) => {
        const coverage = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.coverage_name + ' ' + flight.title }, { displayInLayerSwitcher: false });
        Utils.getFile(flight.inverse_coverage).then((inverse_geojson) => {
          const inverseCoverage = newGeoJSONLayer({ source: inverse_geojson, extract: false, name: CONFIGURATIONS.coverage_name + ' ' + flight.title + ' Reverse' }, { displayInLayerSwitcher: false });
          if (isLayerIntersectingExtent(geojson, getBbox())) {
            _this.props.selectFlight(id, flight.layer, flight.title + ' ' + flight.subtitle);
            Utils.getFile(flight.centers).then((geojson) => {
              const centers = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.centers_name }, { displayInLayerSwitcher: false });
              Utils.getFile(flight.marks).then((geojson) => {
                const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
                addFlightLayers(coverage, centers, marks, _this.props.getSelectedFeatures, _this.props.unblock, inverseCoverage);
                _this.props.addCentersLayer(centers);
                _this.props.addMarksLayer(marks);
              });
            });
          } else {
            _this.props.unblock();
            _this.props.selectFlight(undefined, undefined, undefined);
            _this.onOpenModal(t('footer.no_coverage'));
          }
        });
      });
    } else {
      _this.props.selectFlight(undefined, undefined, undefined);
    }
  }

  selectMosaic = (nameLayer) => {
    toogleVisibilityLayer(nameLayer, this.props.t, () => {
      this.setState({ reload: true });
    });
  }

  onCloseModal = () => {
    this.setState({ open: false, modalMessage: '' });
  };

  onOpenModal = (message) => {
    this.setState({ open: true, modalMessage: message });
  }

  changeMode = (newMode) => {
    this.setState({ mode: newMode, collapsed: [] });
  }

  collapse = (group) => {
    const newCollapsed = this.state.collapsed.filter(c => c !== group);
    if (newCollapsed.length === this.state.collapsed.length) {
      newCollapsed.push(group);
    }

    this.setState({ collapsed: newCollapsed });
  }

  render() {
    const { t } = this.props;
    const { open, modalMessage, mode, lang, reload, collapsed, flights } = this.state;
    const footerElements = [];
    if (mode === 'flights') {
      // const allCollapsed = collapsed.length === flights.length;
      const flightsGroup = [];
      const flightsFullData = {};
      flights.forEach((f) => {
        if (flightsGroup.indexOf(f.category_name) === -1) {
          flightsGroup.push(f.category_name);
        }
      });

      flightsGroup.forEach((fg) => {
        const fgRows = [];
        flights.forEach((f) => {
          if (fg === f.category_name) {
            fgRows.push(f);
          }
        });

        if (fgRows.length > 0) {
          flightsFullData[fg] = fgRows;
        }
      });

      let counter = 0;
      Object.keys(flightsFullData).forEach((fg, index) => {
        const name = lang === 'en' ? flightsFullData[fg][0].category_name_en : fg;
        footerElements.push(
          <div key={fg + '-separator'} className={'footer-separator fs' + (index === 0 ? '1' : '2')} onClick={this.collapse.bind(this, fg)} style={{ backgroundColor: '#b9b9b9', color: 'black', marginTop: '32px' }}>
            <span>{name}</span>
          </div>
        );

        flightsFullData[fg].forEach((f, index) => {
          footerElements.push(<Flight key={'f' + fg + index} text={lang === 'en' ? f.text_en : f.text}
            selectFlight={this.selectFlight.bind(null, f.id)} title={f.title} subtitle={lang === 'en' ? f.subtitleEn : f.subtitle}
            id={f.id} selected={this.state.selected === f.id} position={counter} collapsed={collapsed.indexOf(fg) > -1}
            last={flights.length - 1} />);

          counter += 1;
        });
      });
    } else {
      const mosaicsData = LocalStorage.get('mosaicsData');
      // const allCollapsed = collapsed.length === Object.keys(mosaicsData).length;
      let counter = 0;
      Object.keys(mosaicsData).forEach((mg, index) => {
        const name = lang === 'en' ? mosaicsData[mg][0].category_name_en : mg;
        footerElements.push(
          <div key={mg + '-separator'} className={'footer-separator fs' + (index === 0 ? '1' : '2')} onClick={this.collapse.bind(this, mg)} style={{ backgroundColor: '#364B5F', color: 'white', marginTop: '32px' }}>
            <span>{name}</span>
          </div>
        );

        mosaicsData[mg].forEach((m, index) => {
          const year = lang === 'en' ? m.titleEn.split(' ').pop().replaceAll('(', '').replaceAll(')', '') : m.title.split(' ').pop().replaceAll('(', '').replaceAll(')', '');
          footerElements.push(<Mosaic key={'m' + mg + index} text={lang === 'en' ? m.descriptionEn : m.description} collapsed={collapsed.indexOf(mg) > -1}
            selectMosaic={this.selectMosaic.bind(null, m.name)} title={year} subtitle={lang === 'en' ? m.titleEn : m.title} id={counter + 1}
            selected={isVisibleLayer(m.name)} reload={reload} position={counter} last={parseInt(LocalStorage.getString('mosaicsTotal')) - 1} />);

          counter += 1;
        });
      });
    }

    return (
      <Fragment>
        <div className='footer-tab-button ftb1' onClick={this.changeMode.bind(this, 'flights')}>
          <span>{t('footer.photos')}</span>
        </div>
        <div className='footer-tab-button ftb2' onClick={this.changeMode.bind(this, 'mosaic')}>
          <span>{t('footer.ortophotos')}</span>
        </div>
        <div className='footer' style={{ backgroundColor: mode === 'flights' ? '#364B5F' : '#A5CAE8' }}>
          <div className='timeline' style={{ backgroundColor: mode === 'flights' ? '#b9b9b9' : '#364B5F' }}></div>
          <div className='footerElements-container'>
            {footerElements}
          </div>
        </div>
        <CustomModal open={open} onClose={this.onCloseModal}>
          <Warning>
            <p style={{ width: '160px', textAlign: 'center' }}>{modalMessage}</p>
          </Warning>
        </CustomModal>
      </Fragment>
    );
  }
}

export default translate()(Footer);
