import React, { Component } from 'react';
import { translate } from 'react-i18next';
import ReactNotification from 'react-notifications-component';

import Administration  from 'components/Administration/Administration';
import LayoutAdmin from 'components/LayoutAdmin/LayoutAdmin';

import 'react-notifications-component/dist/theme.css';

class Admin extends Component {

  render() {
    return (
      <div className='app-container'>
        <ReactNotification />
        <LayoutAdmin>
          <Administration />
        </LayoutAdmin>
      </div>
    );
  }
}

export default translate()(Admin);
