import React, { Component } from 'react';
import { translate } from 'react-i18next';

import './Warning.css';

class Warning extends Component {

  render() {
    const { children } = this.props;

    return (
      <React.Fragment>
        <div className='warningContent row'>
          {children}
        </div>
      </React.Fragment>
    );
  }
}

export default translate()(Warning);
