import React, { Component, Fragment } from 'react';
import Draggabilly from 'draggabilly';
import { translate } from 'react-i18next';
import { landscape } from 'react-icons-kit/entypo/landscape';
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit';
import { ic_file_upload } from 'react-icons-kit/md/ic_file_upload';
import { trash } from 'react-icons-kit/fa/trash';
import { chevronUp } from 'react-icons-kit/fa/chevronUp';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import { Icon } from 'react-icons-kit';

import Button from 'components/Button/Button';
import Warning from 'components/Warning/Warning';
import CustomModal from 'components/CustomModal/CustomModal';
import MTNSelector from 'components/MTNSelector/MTNSelector';
import ActionButton from 'components/ActionButton/ActionButton';
import Utils from 'utils/Utils';
import {
  getScale, addLayerFile, addGeoJSONLayer, drawPolygon, clearDrawLayer, getBbox,
  removeUploadedLayers, getDrawnFeatures, removeDrawInteraction, openInfoDialog, getUploadedLayer,
  getFeaturesIntersectingExtent, getFeaturesIntersectingGeometry, newGeoJSONLayer,
} from 'utils/Visor';

import MTN25 from 'static/mtn/mtn25.geojson';
import MTN50 from 'static/mtn/mtn50.geojson';
import MTNIcon from 'static/img/mtnIcon.svg';
import MTNIconSelected from 'static/img/mtnIconSelected.svg';

import './Actions.css';
import { mapjs } from 'utils/Visor';

declare var CONFIGURATIONS;

class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: undefined,
      open: false,
      modalOption: undefined,
      mtnSelected: 'MTN50',
      mtnValue: '',
      mtnQuarter: 1,
      mtnLayer: undefined,
      selectedFeatures: this.props.selectedFeatures,
      centersLayer: this.props.centersLayer,
      collapsed: false,
      hidden: false || (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
      mobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ||
      ((window.innerHeight <= 1124 || window.innerWidth <= 540) && window.innerHeight > window.innerWidth) ||
      ((window.innerHeight <= 540 || window.innerWidth <= 1124) && window.innerHeight < window.innerWidth),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        selectedFeatures: nextProps.selectedFeatures,
        centersLayer: nextProps.centersLayer,
      });
    }
  }

  selectOption = (option) => {
    const _this = this;
    if (_this.state.selected === option) {
      removeDrawInteraction();
      if (option === 'mtn') {
        removeUploadedLayers();
      }

      _this.setState({ selected: undefined });
    } else {
      if (option === 'upload') {
        document.getElementById('fileUploader').click();
      } else if (option === 'mtn') {
        this.setState({ selected: option, modalOption: option }, this.onOpenModal);
      } else if (option === 'edit') {
        if (getScale() <= CONFIGURATIONS.tools_min_scale) {
          this.setState({ selected: option }, () => {
            if (this.state.mobile) {
              this.toogleHidden();
              drawPolygon(this.toogleHidden);
            } else {
              drawPolygon();
            }
          });
        } else {
          this.setState({ modalOption: 'big-scale' }, this.onOpenModal);
        }
      } else if (option === 'select') {
        if (getScale() <= CONFIGURATIONS.tools_min_scale) {
          if (_this.state.centersLayer !== undefined) {
            this.props.block();
            let selectedFeatures = this.state.selectedFeatures;
            const flight = this.getFlightOfCenters();
            Utils.getFile(flight.marks).then((geojson) => {
              const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
              const inside = getFeaturesIntersectingExtent(marks.source.features, getBbox());
              inside.forEach((i) => {
                if (!Utils.arrayContainsString(selectedFeatures, i.properties[CONFIGURATIONS.marks_layer_attr])) {
                  selectedFeatures.push(i.properties[CONFIGURATIONS.marks_layer_attr]);
                }
              });

              _this.setState({ selectedFeatures: selectedFeatures }, () => {
                _this.props.unblock();
                _this.props.selectFeatures(selectedFeatures);
                if (_this.props.flightLayer !== flight.layer) {
                  _this.props.selectTempFlight(flight.layer);
                }
                if (!this.state.mobile) {
                  this.toogleHidden();
                }
              });
            });
          } else {
            let selectedFeatures = this.state.selectedFeatures || [];
            this.props.block();
            const promises = [];
            for (let item = 0; item < _this.props.flights.length; item++) {
              const flight = _this.props.flights[item];
              promises.push(Utils.getFile(flight.marks));
            }

            Promise.all(promises).then((geojsons) => {
              geojsons.forEach((geojson) => {
                const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
                const inside = getFeaturesIntersectingExtent(marks.source.features, getBbox());
                inside.forEach((i) => {
                  if (!Utils.arrayContainsString(selectedFeatures, i.properties[CONFIGURATIONS.marks_layer_attr])) {
                    selectedFeatures.push(i.properties[CONFIGURATIONS.marks_layer_attr]);
                  }
                });
              });

              _this.setState({ selectedFeatures: selectedFeatures, selected: undefined }, () => {
                _this.props.unblock();
                _this.props.selectFeatures(selectedFeatures);
                if (!this.state.mobile) {
                  this.toogleHidden();
                }
              });
            });
          }
        } else {
          this.setState({ modalOption: 'big-scale' }, this.onOpenModal);
        }
      }
    }
  }

  uploadFile = (evt) => {
    const { t } = this.props;
    const file = document.getElementById('fileUploader').files[0];
    const fileName = file !== undefined ? file.name.split('.').pop() : '';
    if (file !== undefined && file.size > CONFIGURATIONS.max_file_size) {
      const text = t('actions.max_size_file').replace('*', (CONFIGURATIONS.max_file_size / Math.pow(1024, 2)));
      openInfoDialog(text);
    } else if (file !== undefined && CONFIGURATIONS.upload_extensions.indexOf(fileName) > -1) {
      addLayerFile(file, this.props.block, this.unblockUpload, t);
      if (this.state.mobile) {
        this.toogleHidden();
      }
    } else if (file !== undefined) {
      openInfoDialog(t('actions.file_type'));
    }
  }

  unblockUpload = (unselect) => {
    this.props.unblock();
    if (unselect) {
      this.setState({ selected: undefined });
    } else {
      this.setState({ selected: 'upload' });
    }
  }

  onOpenModal = () => {
    this.setState({ open: true }, () => {
      setTimeout(() => {
        if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
          if (document.querySelector('#sheetMTN50') !== null) {
            document.querySelector('#sheetMTN50').focus();
          }

          const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
            containment: 'body',
            handle: '.customModalTitle',
          });

          draggable.enable();
        }
      }, 50);
    });
  };

  onCloseModal = () => {
    this.setState({ open: false, selected: undefined, modalOption: undefined });
  };

  getSheet = (evt) => {
    this.setState({ mtnSelected: evt.target.value }, () => {
      document.getElementById('sheetMTN50').value = '';
      document.getElementById('sheetMTN25').value = '';
    });
  }

  getSheetValue = (evt) => {
    let value = evt.target.value;
    if (value.length < 4) {
      while (value.length < 4) {
        value = '0' + value;
      }
    }

    this.setState({ mtnValue: value });
  }

  getQuarter = () => {
    const quarter = document.getElementById('selectSheetQuarter').value;
    this.setState({ mtnQuarter: quarter });
  }

  showSheet = (search) => {
    const _this = this;
    const { mtnSelected, mtnQuarter, mtnValue } = this.state;
    let file = MTN50;
    let property = 'MTN50_CLAS';
    let value = mtnValue;
    if (mtnSelected === 'MTN25') {
      file = MTN25;
      property = 'MTN25_CLAS';
      value = mtnValue + '-' + mtnQuarter;
    }

    Utils.getFile(file, false).then((geojson) => {
      const features = [].concat(geojson.features);
      geojson.features = features.filter((item) => {
        return item.properties[property] === value || item.properties[property].indexOf(value) > -1;
      });

      if (geojson.features.length > 1) {
        geojson.features = features.filter((item) => {
          return item.properties[property] === value;
        });
      }

      if (geojson.features.length === 1) {
        removeUploadedLayers();
        const mtnLayer = addGeoJSONLayer({ source: geojson, extract: false, name: 'Hoja ' + value }, {
          displayInLayerSwitcher: false,
        }, 'Polygon', true, CONFIGURATIONS.fotograms_zoom, CONFIGURATIONS.max_zoom, true);
        if (this.state.mobile && !search) {
          this.toogleHidden();
        }
        _this.setState({ open: false, modalOption: undefined, mtnLayer: mtnLayer });
        if (search) {
          this.loadFotograms();
        }
      } else {
        _this.setState({ modalOption: 'not-found-mtn' }, _this.onOpenModal);
      }
    });
  }

  loadFotograms = () => {
    const _this = this;
    this.props.block();
    setTimeout(() => {
      let selectedFeatures = this.state.selectedFeatures;
      if (_this.state.selected === 'edit') {
        const drawnFeatures = getDrawnFeatures();
        if (_this.state.centersLayer !== undefined) {
          const flight = this.getFlightOfCenters();
          Utils.getFile(flight.marks).then((geojson) => {
            const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
            drawnFeatures.forEach((f) => {
              const inside = getFeaturesIntersectingGeometry(marks.source.features, f);
              inside.forEach((i) => {
                if (!Utils.arrayContainsString(selectedFeatures, i.properties[CONFIGURATIONS.marks_layer_attr])) {
                  selectedFeatures.push(i.properties[CONFIGURATIONS.marks_layer_attr]);
                }
              });
            });

            _this.setState({ selectedFeatures: selectedFeatures }, () => {
              //clearDrawLayer();
              _this.props.unblock();
              _this.props.selectFeatures(selectedFeatures);
              if (_this.props.flightLayer !== flight.layer) {
                _this.props.selectTempFlight(flight.layer);
              }
              if (!_this.state.mobile) {
                _this.toogleHidden();
              } else {
                const btn = document.querySelector('#collapseFiltersListBtn');
                if (btn) {
                  btn.click();
                }
              }
            });
          });
        } else {
          const promises = [];
          for (let item = 0; item < _this.props.flights.length; item++) {
            const flight = _this.props.flights[item];
            promises.push(Utils.getFile(flight.marks));
          }

          Promise.all(promises).then((geojsons) => {
            geojsons.forEach((geojson) => {
              const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
              drawnFeatures.forEach((f) => {
                const inside = getFeaturesIntersectingGeometry(marks.source.features, f);
                inside.forEach((i) => {
                  if (!Utils.arrayContainsString(selectedFeatures, i.properties[CONFIGURATIONS.marks_layer_attr])) {
                    selectedFeatures.push(i.properties[CONFIGURATIONS.marks_layer_attr]);
                  }
                });
              });
            });

            _this.setState({ selectedFeatures: selectedFeatures, selected: undefined }, () => {
              //clearDrawLayer();
              _this.props.unblock();
              _this.props.selectFeatures(selectedFeatures);
              if (!_this.state.mobile) {
                _this.toogleHidden();
              } else {
                const btn = document.querySelector('#collapseFiltersListBtn');
                if (btn) {
                  btn.click();
                }
              }
            });
          });
        }
      } else if (_this.state.selected === 'mtn') {
        const mtnLayer = _this.state.mtnLayer;
        const extent = mtnLayer.getImpl().getOL3Layer().getSource().getFeatures()[0].getGeometry().getExtent();
        if (_this.state.centersLayer !== undefined) {
          const flight = this.getFlightOfCenters();
          Utils.getFile(flight.marks).then((geojson) => {
            const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
            const inside = getFeaturesIntersectingExtent(marks.source.features, extent);
            inside.forEach((i) => {
              if (!Utils.arrayContainsString(selectedFeatures, i.properties[CONFIGURATIONS.marks_layer_attr])) {
                selectedFeatures.push(i.properties[CONFIGURATIONS.marks_layer_attr]);
              }
            });

            _this.setState({ selectedFeatures: selectedFeatures }, () => {
              _this.props.unblock();
              _this.props.selectFeatures(selectedFeatures);
              if (_this.props.flightLayer !== flight.layer) {
                _this.props.selectTempFlight(flight.layer);
              }
              if (!_this.state.mobile) {
                _this.toogleHidden();
              } else {
                const btn = document.querySelector('#collapseFiltersListBtn');
                if (btn) {
                  btn.click();
                }
              }
            });
          });
        } else {
          const promises = [];
          for (let item = 0; item < _this.props.flights.length; item++) {
            const flight = _this.props.flights[item];
            promises.push(Utils.getFile(flight.marks));
          }

          Promise.all(promises).then((geojsons) => {
            geojsons.forEach((geojson) => {
              const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
              const inside = getFeaturesIntersectingExtent(marks.source.features, extent);
              inside.forEach((i) => {
                if (!Utils.arrayContainsString(selectedFeatures, i.properties[CONFIGURATIONS.marks_layer_attr])) {
                  selectedFeatures.push(i.properties[CONFIGURATIONS.marks_layer_attr]);
                }
              });
            });

            _this.setState({ selectedFeatures: selectedFeatures, selected: undefined }, () => {
              _this.props.unblock();
              _this.props.selectFeatures(selectedFeatures);
              if (!_this.state.mobile) {
                _this.toogleHidden();
              } else {
                const btn = document.querySelector('#collapseFiltersListBtn');
                if (btn) {
                  btn.click();
                }
              }
            });
          });
        }
      } else if (_this.state.selected === 'upload') {
        const uploadedLayer = getUploadedLayer();
        if (_this.state.centersLayer !== undefined) {
          const flight = this.getFlightOfCenters();
          Utils.getFile(flight.marks).then((geojson) => {
            const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
            uploadedLayer.getImpl().getOL3Layer().getSource().getFeatures().forEach((f) => {
              const inside = getFeaturesIntersectingGeometry(marks.source.features, f);
              inside.forEach((i) => {
                if (!Utils.arrayContainsString(selectedFeatures, i.properties[CONFIGURATIONS.marks_layer_attr])) {
                  selectedFeatures.push(i.properties[CONFIGURATIONS.marks_layer_attr]);
                }
              });
            });

            _this.setState({ selectedFeatures: selectedFeatures }, () => {
              _this.props.unblock();
              _this.props.selectFeatures(selectedFeatures);
              if (_this.props.flightLayer !== flight.layer) {
                _this.props.selectTempFlight(flight.layer);
              }
              //removeUploadedLayers();
              if (!_this.state.mobile) {
                _this.toogleHidden();
              } else {
                const btn = document.querySelector('#collapseFiltersListBtn');
                if (btn) {
                  btn.click();
                }
              }
            });
          });
        } else {
          const promises = [];
          for (let item = 0; item < _this.props.flights.length; item++) {
            const flight = _this.props.flights[item];
            promises.push(Utils.getFile(flight.marks));
          }

          Promise.all(promises).then((geojsons) => {
            geojsons.forEach((geojson) => {
              const marks = newGeoJSONLayer({ source: geojson, extract: false, name: CONFIGURATIONS.marks_name }, { displayInLayerSwitcher: false });
              uploadedLayer.getImpl().getOL3Layer().getSource().getFeatures().forEach((f) => {
                const inside = getFeaturesIntersectingGeometry(marks.source.features, f);
                inside.forEach((i) => {
                  if (!Utils.arrayContainsString(selectedFeatures, i.properties[CONFIGURATIONS.marks_layer_attr])) {
                    selectedFeatures.push(i.properties[CONFIGURATIONS.marks_layer_attr]);
                  }
                });
              });
            });

            _this.setState({ selectedFeatures: selectedFeatures, selected: undefined }, () => {
              _this.props.unblock();
              _this.props.selectFeatures(selectedFeatures);
              //removeUploadedLayers();
              if (!_this.state.mobile) {
                _this.toogleHidden();
              } else {
                const btn = document.querySelector('#collapseFiltersListBtn');
                if (btn) {
                  btn.click();
                }
              }
            });
          });
        }
      } else {
        this.props.unblock();
      }
    }, 1000);
  }

  getFlightOfCenters = () => {
    let flightRes = {};
    if (this.state.centersLayer !== undefined && this.state.centersLayer.source !== undefined) {
      const feature = this.state.centersLayer.source.features[0];
      const fotogram = feature.properties[CONFIGURATIONS.fotogram_layer_attr];
      for (let flight of this.props.flights) {
        // eslint-disable-next-line
        flight.keys.forEach((k) => {
          if (fotogram.indexOf(k) > -1) {
            flightRes = flight;
          }
        });
      }
    }

    return flightRes;
  }

  clear = () => {
    clearDrawLayer();
    removeUploadedLayers();
    document.querySelector('#fileUploader').value = '';
    if (this.state.selected === 'upload' || this.state.selected === 'mtn' || this.state.selected === 'edit') {
      this.setState({ selected: undefined });
    }
  }

  toogleHidden = () => {
    const inv = !this.state.hidden;
    this.setState({ hidden: inv }, () => {
      if (this.state.mobile) {
        this.toogleHiddenMobile(inv);
      } else {
        this.toogleHiddenWeb(inv);
      }
    });
  }

  toogleHiddenWeb = (inv) => {
    const elem = document.querySelector('nav:nth-child(1) > div.listContainer > div.table.row > div');
    if (inv && elem !== null) {
      elem.style.height = this.state.mobile ? 'calc(95vh - 380px)' : 'calc(100vh - 380px)';
    } else if (elem !== null) {
      elem.style.height = 'calc(100vh - 480px)';
    }
  }

  toogleHiddenMobile = (inv) => {
    const navElem = document.querySelector('.tools-menu.nav-wrapper');
    if (inv && navElem !== null) {
      navElem.classList.remove('up');
      // Se espera para que se complete la animación de ocultación
      setTimeout(() => {navElem.classList.remove('show');}, 500);
      setTimeout(() => {
        mapjs.refresh();
      }, 500);
    } else if (navElem !== null) {
      navElem.classList.add('show');
      // Se espera para que la animación de despligue se vea correctamente
      setTimeout(() => {navElem.classList.add('up');}, 100);
    }
    const elemList = document.querySelector('nav:nth-child(1) > div.listContainer');
    if (inv && elemList !== null) {
      elemList.style.display = 'none';
    } else if (elemList !== null) {
      elemList.style.display = 'block';
    }
    const navElem2 = document.querySelector('.search-menu.nav-wrapper');
    if (inv && navElem2 !== null) {
      navElem2.classList.remove('bottom-wrapper');
    } else if (navElem2 !== null) {
      navElem2.classList.add('bottom-wrapper');
    }
  }

  toogleCollapsedMobile = () => {
    const inv = !this.state.collapsed;
    this.setState({ collapsed: inv }, () => {
        this.toogleHiddenWeb(inv);
    });
  }

  render() {
    const { t, menuOpened, configurations } = this.props;
    const { selected, open, mtnQuarter, mtnSelected, modalOption, collapsed, hidden, mobile } = this.state;
    let titleModal = t('actions.warning');
    if (modalOption === 'mtn') {
      titleModal = t('actions.mtn_sheet');
    }

    const tooltipFile = <div>
      <p>{t('actions.using_geometry')}</p>
      <p style={{ textAlign: 'left', fontSize: '12px' }}><i>*{t('actions.allowed_formats')}<br /></i></p>
    </div>;

    return (
      <Fragment>
        <div className='actionsContainer'>
          <div className='actionsTitleContainer row' onClick={mobile ? this.toogleHidden : null}>
            <span>{t('actions.tools')} {mobile && this.state.selectedFeatures.length > 0 ? '(' + this.state.selectedFeatures.length +')' : ''}</span>
            {mobile ?
            (hidden ?
            <span className='collapse-icon-frames'><Icon size={'1.2rem'} icon={chevronDown} /></span>
            : <div className='collapse-button-frames'>{t('others.returnmap')}</div>)
            :
            (menuOpened ?
              (hidden ?
              <div className='collapse-button-frames' onClick={this.toogleHidden}>{t('others.showfilters')}</div>
              : <span className='collapse-icon-frames' onClick={this.toogleHidden}><Icon size={'1.2rem'} icon={hidden ? chevronDown : chevronUp} /></span>)
              : null)}
          </div>
          <div id='collapseActionsBtn' onClick={this.toogleCollapsedMobile} style={{display: 'none'}}>c</div>
          {!hidden && !collapsed ?
            <div>
              <div className='buttonsContainer row'>
                {configurations.searchByView === true ?
                  <ActionButton id='selectExtent' icon={landscape} size={'20px'} rounded={true} tooltip={t('actions.view_extend')}
                    selected={selected === 'select'} onClick={this.selectOption.bind(null, 'select')} />
                  : null}
                {configurations.searchByDraw === true ?
                  <ActionButton id='drawPolygon' icon={ic_mode_edit} size={'24px'} rounded={true} tooltip={t('actions.polygons')}
                    selected={selected === 'edit'} onClick={this.selectOption.bind(null, 'edit')} />
                  : null}
                {configurations.searchByMtn === true ?
                  <ActionButton id='selectMTN' icon={selected === 'mtn' ? MTNIconSelected : MTNIcon} size={'24px'} rounded={true}
                    onClick={this.selectOption.bind(null, 'mtn')} selected={selected === 'mtn'}
                    notInside={true} imageStyle={{ width: '24px', marginLeft: '2px' }} tooltip={t('actions.mtn_sheet')} />
                  : null}

                {configurations.searchByFile === true ?
                  <React.Fragment>
                    <ActionButton id='selectFile' icon={ic_file_upload} size={'24px'} rounded={true} tooltip={tooltipFile}
                      selected={selected === 'upload'} onClick={this.selectOption.bind(null, 'upload')} />
                    <input type='file' id='fileUploader' name='fileUploader' style={{ display: 'none' }} onChange={this.uploadFile.bind(this)} />
                  </React.Fragment>
                  : null}
                {configurations.searchByView === true || configurations.searchByDraw === true || configurations.searchByMtn === true || configurations.searchByFile === true ?
                  <ActionButton id='clearFigures' icon={trash} size={'24px'} rounded={true} tooltip={t('actions.remove_criteria')}
                    selected={false} onClick={this.clear} />
                  : null}
              </div>
              {(selected === 'upload' || selected === 'mtn' || selected === 'edit') && (configurations.searchByDraw === true || configurations.searchByMtn === true || configurations.searchByFile === true) ?
                <div className='buttonsContainer row'>
                  <Button text={t('actions.load_selection')} active={true} onClick={this.loadFotograms} />
                </div>
                : null}
              <CustomModal open={open} onClose={this.onCloseModal} title={titleModal} modalClass={modalOption + '-modalBox'}>
                {modalOption === 'mtn' ?
                  <MTNSelector getSheet={this.getSheet} getSheetValue={this.getSheetValue}
                    getQuarter={this.getQuarter} mtnSelected={mtnSelected} mtnQuarter={mtnQuarter}
                    showSheet={this.showSheet} mobile={this.state.mobile}/>
                  : modalOption === 'big-scale' ?
                    <Warning>
                      <p>{t('actions.available_scale_1') + ' '} <b>1/{CONFIGURATIONS.tools_min_scale}</b> {' ' + t('actions.available_scale_2')}</p>
                    </Warning>
                    : modalOption === 'not-found-mtn' ?
                      <Warning>
                        <p>{t('actions.sheet_not_found')}</p>
                      </Warning>
                      : modalOption === 'unavailable' ?
                        <Warning>
                          <p>{t('actions.select_flight')}</p>
                        </Warning>
                        : modalOption === 'development' ?
                          <Warning>
                            <p style={{ width: '160px', textAlign: 'center' }}>{t('actions.under_develop')}</p>
                          </Warning>
                          : null}
              </CustomModal>
            </div>
            : null}
        </div>
      </Fragment>
    );
  }
}

export default translate()(Actions);
