import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { ic_file_upload } from 'react-icons-kit/md/ic_file_upload';

import Button from 'components/Button/Button';
import ActionButton from 'components/ActionButton/ActionButton';

import Utils from 'utils/Utils';

import './BackgroundLayerForm.css';

class BackgroundLayerForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data !== undefined ? JSON.parse(JSON.stringify(this.props.data)) : { type: 'WMTS' },
      creating: this.props.data === undefined,
      errorMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.data !== undefined) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 100);
    }

    setTimeout(() => {
      document.querySelector('#thumbnail-upload').addEventListener('change', this.changeImage);
    }, 100);
  }

  changeType = (evt) => {
    const newType = evt.target.value;
    const newData = this.state.data;
    newData.type = newType;
    this.setState({ data: newData });
  }

  openChangeImage = () => {
    document.querySelector('#thumbnail-upload').click();
  }

  changeImage = (evt) => {
    const _this = this;
    const file = evt.target.files[0];
    Utils.fileToBase64(file).then((data) => {
      const newData = _this.state.data;
      newData.image = data;
      _this.setState({ data: newData });
    });
  }

  save = () => {
    let newData = this.state.data;
    let emptyFields = false;
    const name = document.querySelector('#backgroundlayerName');
    const title = document.querySelector('#backgroundlayerTitle');
    const titleEn = document.querySelector('#backgroundlayerTitleEn');
    const url = document.querySelector('#backgroundlayerUrl');
    const image = document.querySelector('#thumbnail-upload-btn');
    if (newData.type === 'WMTS' && newData.format === undefined) {
      newData.format = document.querySelector('#backgroundlayerFormat').value;
    } else if (newData.type === 'XYZ' && newData.tilegridMaxZoom === undefined) {
      newData.tilegridMaxZoom = document.querySelector('#backgroundlayerTMZ').value;
    }

    newData.name = name.value.trim();
    newData.title = title.value.trim();
    newData.titleEn = titleEn.value.trim();
    newData.url = url.value.trim();
    if (newData.name === '') {
      emptyFields = true;
      name.style.border = '2px solid red';
    } else {
      name.style.border = '1px solid black';
    }

    if (newData.title === '') {
      emptyFields = true;
      title.style.border = '2px solid red';
    } else {
      title.style.border = '1px solid black';
    }

    if (newData.titleEn === '') {
      emptyFields = true;
      titleEn.style.border = '2px solid red';
    } else {
      titleEn.style.border = '1px solid black';
    }

    if (newData.url === '') {
      emptyFields = true;
      url.style.border = '2px solid red';
    } else {
      url.style.border = '1px solid black';
    }

    if (newData.image === undefined) {
      emptyFields = true;
      image.style.border = '2px solid red';
    }

    if (!emptyFields) {
      this.setState({ errorMessage: false }, () => {
        this.props.saveData(newData);
      });
    } else {
      this.setState({ errorMessage: true });
    }
  }

  render() {
    const { t } = this.props;
    const { data, creating, errorMessage } = this.state;

    return (
      <div className='backgroundlayers-form-container'>
        <div className='backgroundlayers-form' style={ data.type !== 'XYZ' ? { position: 'relative', left: '-7px' } : undefined }>
          <div>
            <label>{t('backgroundlayerForm.type')}</label>
            <br/>
            { !creating ?
              <input type='text' disabled defaultValue={data.type}/>
            : <select onChange={this.changeType}>
                <option value='WMTS'>WMTS</option>
                <option value='XYZ'>XYZ/TMS</option>
            </select> }
          </div>
          <div>
            <label>{t('backgroundlayerForm.name')}</label>
            <br/>
            <input type='text' id='backgroundlayerName' defaultValue={data !== undefined ? data.name : ''} />
          </div>
          <div>
            <label>{t('backgroundlayerForm.title')}</label>
            <br/>
            <input type='text' id='backgroundlayerTitle' defaultValue={data !== undefined ? data.title : ''} />
          </div>
          <div>
            <label>{t('backgroundlayerForm.titleEn')}</label>
            <br/>
            <input type='text' id='backgroundlayerTitleEn' defaultValue={data !== undefined ? data.titleEn : ''} />
          </div>
          <div>
            <label>URL</label>
            <br/>
            <input type='text' id='backgroundlayerUrl' defaultValue={data !== undefined ? data.url : ''} />
          </div>
          { data.type === 'XYZ' ?
            <div>
              <label>{t('backgroundlayerForm.maxZoom')}</label>
              <br/>
              <select id='backgroundlayerTMZ' defaultValue={data.tilegridMaxZoom !== undefined ? `${data.tilegridMaxZoom}` : '12'}>
                <option value='12'>12</option>
                <option value='13'>13</option>
                <option value='14'>14</option>
                <option value='15'>15</option>
                <option value='16'>16</option>
                <option value='17'>17</option>
                <option value='18'>18</option>
                <option value='19'>19</option>
              </select>
            </div>
          : <div>
            <label>{t('backgroundlayerForm.imageFormat')}</label>
            <br/>
            <select id='backgroundlayerFormat' defaultValue={data.format !== undefined ? `${data.format}` : 'image/png'}>
              <option value='image/png'>image/png</option>
              <option value='image/jpeg'>image/jpeg</option>
            </select>
          </div> }

          <div>
            <label>{t('backgroundlayerForm.thumbnail')}</label>
            <br/>
            {data.image !== undefined ?
              <img src={data.image} style={{ cursor: 'pointer', width: '150px', marginTop: '5px' }} alt='thumbnail' onClick={this.openChangeImage}/>
            : <div className='thumbnail-upload-btn-container'>
                <ActionButton id='thumbnail-upload-btn' icon={ic_file_upload} size={'24px'} rounded={true} selected={true} onClick={this.openChangeImage} />
            </div> }
            <input type='file' id='thumbnail-upload' style={{ display: 'none' }} accept='image/png, image/gif, image/jpeg, image/jpg' />
          </div>
        </div>
        { errorMessage === true ?
          <div className='backgroundlayers-form-error'>{t('backgroundlayerForm.error')}</div>
        : null }
        <div className='backgroundlayers-form-button-container'>
          <Button text={t('backgroundlayerForm.save')} active={true} onClick={this.save} />
        </div>
      </div>
    );
  }
}

export default translate()(BackgroundLayerForm);
