import React, { Component } from 'react';
import cookie from 'react-cookies';
import { Loader } from 'react-loaders';
import { translate } from 'react-i18next';
import { Icon } from 'react-icons-kit';
import { rss } from 'react-icons-kit/fa/rss';
import { plane } from 'react-icons-kit/fa/plane';
import { thLarge } from 'react-icons-kit/fa/thLarge';
import { ic_layers } from 'react-icons-kit/md/ic_layers';
import { questionCircle } from 'react-icons-kit/fa/questionCircle';
import { ic_map_twotone } from 'react-icons-kit/md/ic_map_twotone';
import { ic_settings_twotone } from 'react-icons-kit/md/ic_settings_twotone';
import { ic_computer_twotone } from 'react-icons-kit/md/ic_computer_twotone';

import Help from 'components/Help/Help';
import Button from 'components/Button/Button';
import Others from 'components/Others/Others';
import RSSConfig from 'components/RSSConfig/RSSConfig';
import CustomModal from 'components/CustomModal/CustomModal';
import FlightsConfig from 'components/FlightsConfig/FlightsConfig';
import MosaicsConfig from 'components/MosaicsConfig/MosaicsConfig';
import AdditionalLayers from 'components/AdditionalLayers/AdditionalLayers';
import BackgroundLayers from 'components/BackgroundLayers/BackgroundLayers';

import LocalStorage from 'utils/LocalStorage';

import './Administration.css';

class Administration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      option: 'flights',
      blocking: false,
      load: false,
    };
  }

  componentWillMount() {
    if (cookie.load('fototecaLogin') !== undefined) {
      const loginCookie = atob(cookie.load('fototecaLogin'));
      const expiration = parseInt(loginCookie.split(':')[1]);
      if (new Date().getTime() > expiration) {
        this.logout();
      } else {
        this.setState({ load: true });
      }
    } else {
      this.logout();
    }
    // this.setState({ load: true });
  }

  changeOption = (newOption) => {
    this.setState({ option: newOption });
  }

  logout = () => {
    cookie.remove('fototecaLogin', { path: '/' });
    window.location.href = '/fototeca/signin';
  }

  block = () => {
    this.setState({ blocking: true });
  }

  unblock = () => {
    this.setState({ blocking: false });
  }

  render() {
    const { t } = this.props;
    const { load, option, blocking } = this.state;
    const lang = LocalStorage.getString('language') || 'es';

    if (load === true) {
      return (
        <React.Fragment>
          <nav className='nav-wrapper-admin'>
            <div style={{ marginTop: '1rem' }}>
              <div style={{ color: '#364B5F' }}><Icon size={'68px'} icon={ic_computer_twotone}/></div>
              <p className='admin-menu-title'>{t('administration.dashboard')}</p>
            </div>
            <div className='admin-menu' style={{ fontSize: lang === 'es' ? '18px' : '14px' }}>
              <p className={option === 'flights' ? 'admin-menu-active' : ''} onClick={this.changeOption.bind(this, 'flights')}>
                <Icon size={'24px'} icon={plane}/>
                <span>{t('administration.flights')}</span>
              </p>
              <p className={option === 'mosaics' ? 'admin-menu-active' : ''} onClick={this.changeOption.bind(this, 'mosaics')}>
                <Icon size={'24px'} icon={ic_map_twotone}/>
                <span>{t('administration.mosaics')}</span>
              </p>
              <p className={option === 'backgroundlayers' ? 'admin-menu-active' : ''} onClick={this.changeOption.bind(this, 'backgroundlayers')}>
                <Icon size={'24px'} icon={thLarge}/>
                <span>{t('administration.backgroundlayers')}</span>
              </p>
              <p className={option === 'additionallayers' ? 'admin-menu-active' : ''} onClick={this.changeOption.bind(this, 'additionallayers')}>
                <Icon size={'24px'} icon={ic_layers}/>
                <span>{t('administration.additionallayers')}</span>
              </p>
              <p className={option === 'rss' ? 'admin-menu-active' : ''} onClick={this.changeOption.bind(this, 'rss')}>
                <Icon size={'24px'} icon={rss}/>
                <span>RSS</span>
              </p>
              <p className={option === 'help' ? 'admin-menu-active' : ''} onClick={this.changeOption.bind(this, 'help')}>
                <Icon size={'24px'} icon={questionCircle}/>
                <span>{t('administration.help')}</span>
              </p>
              <p className={option === 'others' ? 'admin-menu-active' : ''} onClick={this.changeOption.bind(this, 'others')}>
                <Icon size={'24px'} icon={ic_settings_twotone}/>
                <span>{t('administration.others')}</span>
              </p>
            </div>
            <div className='admin-menu-button-container'>
              <Button text={t('administration.logout')} active={true} onClick={this.logout} />
            </div>
          </nav>
          <div className='layout-admin-container'>
            { option === 'flights' ?
              <FlightsConfig block={this.block} unblock={this.unblock} />
            : option === 'mosaics' ?
              <MosaicsConfig block={this.block} unblock={this.unblock} />
            : option === 'backgroundlayers' ?
              <BackgroundLayers block={this.block} unblock={this.unblock} />
            : option === 'additionallayers' ?
              <AdditionalLayers block={this.block} unblock={this.unblock} />
            : option === 'rss' ?
              <RSSConfig block={this.block} unblock={this.unblock} />
            : option === 'help' ?
              <Help block={this.block} unblock={this.unblock} />
            : option === 'others' ?
              <Others block={this.block} unblock={this.unblock} />
            : null }
          </div>
          <CustomModal open={blocking} blocking={true} onClose={this.unblock}>
            <div className='block-loader-container'>
              <Loader active type={'ball-triangle-path'} color='#364B5F'/>
            </div>
          </CustomModal>
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}

export default translate()(Administration);
