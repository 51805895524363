import React, { Component } from 'react';
import { translate } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import Filters from 'components/Filters/Filters';
import Results from 'components/Results/Results';

import BlueIcon from 'static/img/blue_plane.svg';
import WhiteIcon from 'static/img/white_plane.svg';

import './SearchMenu.css';

class SearchMenu extends Component {
  constructor(props) {
    super(props);
    this.tooltipRef = null;
    this.state = {
      results: [],
      filters: {
        year: undefined,
        flightType: undefined,
        scale: undefined,
        gsd: undefined,
        query: '',
      },
    };
  }

  updateResults = (results) => {
    this.setState({ results });
  }

  updateFilters = (filters) => {
    this.setState({ filters });
  }

  handleClick = () => {
    this.props.openSearchMenuHandler();
    if (this.tooltipRef !== null) {
      ReactTooltip.hide(this.tooltipRef);
    }
  }

  render() {
    const { t, menuSearchOpened, onlyButton } = this.props;
    const { results, filters } = this.state;
    const mobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const style = {
      left: menuSearchOpened && !mobile ? '470px' : '0',
    };
    if (onlyButton) {
      return (
        <nav className={'search-menu nav-wrapper icon-w0'}>
          <div ref={ref => this.tooltipRef = ref} className='search-menu collapseButton' style={{ left: '470px' }} data-event="mouseover" data-event-off="mouseout"
            onClick={this.handleClick} data-tip data-for={'searchMenutooltip'}>
            <i style={{ display: 'inline-block' }}>
              <img style={{ height: '24px'}} src={WhiteIcon} alt='Search menu icon' />
            </i>
            {/*<span>{t('searchMenu.other_flights')}</span>*/}
          </div>
          <ReactTooltip id={'searchMenutooltip'} effect='solid' place='right'>
            {t('searchMenu.available_flights')}
          </ReactTooltip>
        </nav>
      );
    } else {
      return (
        <nav className={menuSearchOpened && !mobile ? 'search-menu nav-wrapper' : 'search-menu nav-wrapper icon-w0'}>
          <div ref={ref => this.tooltipRef = ref} className={'search-menu collapseButton' + (menuSearchOpened && !mobile ? ' opened' : '')} style={style} data-event="mouseover"
            data-event-off="mouseout" onClick={this.handleClick} data-tip data-for={'searchMenutooltip'}>
            <i style={{ display: 'inline-block' }}>
              <img style={{ height: '24px'}} src={menuSearchOpened && !mobile ? BlueIcon : WhiteIcon} alt='Search menu icon' />
            </i>
            {/*<span>{t('searchMenu.other_flights')}</span>*/}
          </div>
          <ReactTooltip id={'searchMenutooltip'} effect='solid' place='right'>
            {t('searchMenu.available_flights')}
          </ReactTooltip>
          <Filters filters={filters} results={results} updateFilters={this.updateFilters} updateResults={this.updateResults} block={this.props.block} unblock={this.props.unblock} />
          <Results results={results} block={this.props.block} unblock={this.props.unblock} />
        </nav>
      );
    }
  }
}

export default translate()(SearchMenu);
