import React, { Component } from 'react';
import { translate } from 'react-i18next';

import Header from 'components/Header/Header';

import './LayoutAdmin.css';

class LayoutAdmin extends Component {

  render() {
    const { children, option } = this.props;

    return (
      <div className='content-wrapper'>
        <Header />
        <section className={option === 'login' ? 'layout-admin-wrapper' : 'layout-cms-wrapper'}>
          { children }
        </section>
      </div>
    );
  }
}

export default translate()(LayoutAdmin);
