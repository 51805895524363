import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Icon } from 'react-icons-kit';
import ReactTooltip from 'react-tooltip';

import './ActionButton.css';

class ActionButton extends Component {

  render() {
    const { icon, size, color, rounded, onClick, notInside, imageStyle,
      selected, disabled, id, tooltip, innerStyle, } = this.props;
    const imageContainerStyle = {
      width: size,
      height: size,
      display: 'flex',
    };

    let style = {
      color: color,
      cursor: 'pointer',
      margin: '3px',
    };

    if (innerStyle !== undefined) {
      style = Object.assign(style, innerStyle);
    }

    if (rounded === true) {
      style.color = '#364B5F';
      style.padding = '7px';
      style.borderRadius = '50px';
      style.backgroundColor = '#e6e6e6';
      if (selected === true) {
        style.color = 'white';
        style.backgroundColor = '#364B5F';
      }
    } else if (disabled === true) {
      style.color = '#e6e6e6';
    }

    return (
      <React.Fragment>
        <div id={id} onClick={onClick} style={style} data-tip data-for={id + 'tooltip'}>
          { notInside === true ?
            <div style={imageContainerStyle}>
              <img src={icon} style={imageStyle} alt='Icon' />
            </div>
          : <Icon size={size} icon={icon}/> }
        </div>
        {tooltip !== undefined ?
          <ReactTooltip id={id + 'tooltip'} effect='solid'>
            { typeof tooltip !== 'string' ?
              <div style={{ maxWidth: '300px' }}>{tooltip}</div>
            : <span>{tooltip}</span> }
          </ReactTooltip>
        : null }
      </React.Fragment>
    );
  }
}

export default translate()(ActionButton);
