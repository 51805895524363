import React, { Component } from 'react';
import { translate } from 'react-i18next';

import Button from 'components/Button/Button';

import './AdditionalLayerForm.css';

class AdditionalLayerForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data !== undefined ? JSON.parse(JSON.stringify(this.props.data)) : { version: '1.3.0' },
      queryable: this.props.data !== undefined ? this.props.data.queryable : false,
      tiled: this.props.data !== undefined ? this.props.data.tiled : false,
      errorMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.data !== undefined) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 100);
    }
  }

  changeCheckbox = (name) => {
    let newState = this.state;
    newState[name] = !this.state[name];
    this.setState(newState);
  }

  save = () => {
    let newData = this.state.data;
    let emptyFields = false;
    const name = document.querySelector('#additionallayerName');
    const title = document.querySelector('#additionallayerTitle');
    const titleEn = document.querySelector('#additionallayerTitleEn');
    const url = document.querySelector('#additionallayerUrl');
    const version = document.querySelector('#additionallayerVersion');
    newData.name = name.value.trim();
    newData.title = title.value.trim();
    newData.titleEn = titleEn.value.trim();
    newData.url = url.value.trim();
    newData.version = version.value;
    newData.queryable = this.state.queryable;
    newData.tiled = this.state.tiled;
    if (newData.name === '') {
      emptyFields = true;
      name.style.border = '2px solid red';
    } else {
      name.style.border = '1px solid black';
    }

    if (newData.title === '') {
      emptyFields = true;
      title.style.border = '2px solid red';
    } else {
      title.style.border = '1px solid black';
    }

    if (newData.titleEn === '') {
      emptyFields = true;
      titleEn.style.border = '2px solid red';
    } else {
      titleEn.style.border = '1px solid black';
    }

    if (newData.url === '') {
      emptyFields = true;
      url.style.border = '2px solid red';
    } else {
      url.style.border = '1px solid black';
    }

    if (!emptyFields) {
      this.setState({ errorMessage: false }, () => {
        this.props.saveData(newData);
      });
    } else {
      this.setState({ errorMessage: true });
    }
  }

  render() {
    const { t } = this.props;
    const { data, queryable, tiled, errorMessage } = this.state;

    return (
      <div className='additionallayers-form-container'>
        <div className='additionallayers-form' style={{ position: 'relative', left: '-7px' }}>
          <div>
            <label>{t('additionallayerForm.name')}</label>
            <br/>
            <input type='text' id='additionallayerName' defaultValue={data.name !== undefined ? data.name : ''} />
          </div>
          <div>
            <label>{t('additionallayerForm.title')}</label>
            <br/>
            <input type='text' id='additionallayerTitle' defaultValue={data.title !== undefined ? data.title : ''} />
          </div>
          <div>
            <label>{t('additionallayerForm.titleEn')}</label>
            <br/>
            <input type='text' id='additionallayerTitleEn' defaultValue={data.titleEn !== undefined ? data.titleEn : ''} />
          </div>
          <div>
            <label>URL</label>
            <br/>
            <input type='text' id='additionallayerUrl' defaultValue={data.url !== undefined ? data.url : ''} />
          </div>
          <div>
            <label>{t('additionallayerForm.version')}</label>
            <br/>
            <select id='additionallayerVersion' defaultValue={data.version}>
              <option value='1.1.0'>1.1.0</option>
              <option value='1.1.1'>1.1.1</option>
              <option value='1.3.0'>1.3.0</option>
            </select>
          </div>
          <div>
            <label>{t('additionallayerForm.queryable')}</label>
            <input type='checkbox' name='queryable' onChange={this.changeCheckbox.bind(this, 'queryable')} checked={queryable} />
          </div>
          <div>
            <label>{t('additionallayerForm.tiled')}</label>
            <input type='checkbox' name='tiled' onChange={this.changeCheckbox.bind(this, 'tiled')} checked={tiled} />
          </div>
        </div>
        { errorMessage === true ?
          <div className='additionallayers-form-error'>{t('additionallayerForm.error')}</div>
        : null }
        <div className='additionallayers-form-button-container'>
          <Button text={t('additionallayerForm.save')} active={true} onClick={this.save} />
        </div>
      </div>
    );
  }
}

export default translate()(AdditionalLayerForm);
