import React, { Component } from 'react';
import Draggabilly from 'draggabilly';
import { translate } from 'react-i18next';
import { store } from 'react-notifications-component';
import { Icon } from 'react-icons-kit';
import { close } from 'react-icons-kit/fa/close';
import { pencil } from 'react-icons-kit/fa/pencil';
import { ic_done } from 'react-icons-kit/md/ic_done';
import { ic_close } from 'react-icons-kit/md/ic_close';
import { arrowUp } from 'react-icons-kit/fa/arrowUp';
import { arrowDown } from 'react-icons-kit/fa/arrowDown';

import Button from 'components/Button/Button';
import CustomModal from 'components/CustomModal/CustomModal';
import ActionButton from 'components/ActionButton/ActionButton';
import AdditionalLayerForm from 'components/AdditionalLayerForm/AdditionalLayerForm';

import ApiService from 'services/ApiService';

import './AdditionalLayers.css';

declare var CONFIGURATIONS;
const API_REST = window.location.protocol + '//' + CONFIGURATIONS.api_rest.split('//')[1];

class AdditionalLayers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      selected: undefined,
      data: [],
    };
  }

  componentWillMount() {
    this.loadAdditionalLayers(true);
  }

  loadAdditionalLayers(startBlock) {
    const { t } = this.props;
    const _this = this;
    if (startBlock === true) {
      _this.props.block();
    }

    ApiService.getRequest(API_REST + 'api/additionallayer')
    .then((response) => {
      _this.setState({ data: response }, () => {
        _this.props.unblock();
      });
    }).catch((error) => {
      _this.props.unblock();
      store.addNotification({
        title: t('additionallayers.error'),
        message: t('additionallayers.load_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  handleOpen = (selected) => {
    this.setState({ opened: true, selected: selected }, () => {
      setTimeout(() => {
        if (document.querySelector('.customModalContent') !== null && document.querySelector('.customModalTitle') !== null) {
          document.querySelector('#additionallayerName').focus();
          const draggable = new Draggabilly(document.querySelector('.customModalContent').parentElement.parentElement, {
            containment: 'body',
            handle: '.customModalTitle',
          });

          draggable.enable();
        }
      }, 50);
    });
  }

  handleClose = () => {
    this.setState({ opened: false, selected: undefined });
  }

  move = (direction, al) => {
    const _this = this;
    const { t } = _this.props;
    const { data } = _this.state;
    const modified = JSON.parse(JSON.stringify(al));
    let affected = undefined;
    if (direction === 'up') {
      const affectedFiltered = data.filter((b) => {
        return b.sort === (al.sort - 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort - 1;
        affected.sort = affected.sort + 1;
      }
    } else if (direction === 'down'){
      const affectedFiltered = data.filter((b) => {
        return b.sort === (al.sort + 1);
      });

      if (affectedFiltered.length > 0) {
        affected = JSON.parse(JSON.stringify(affectedFiltered[0]));
        modified.sort = modified.sort + 1;
        affected.sort = affected.sort - 1;
      }
    }

    if (affected !== undefined) {
      _this.props.block();
      Promise.all([
        ApiService.putRequest(API_REST + 'api/additionallayer/' + modified.id, modified),
        ApiService.putRequest(API_REST + 'api/additionallayer/' + affected.id, affected),
      ]).then((responses) => {
        _this.loadAdditionalLayers(false);
      }).catch((errors) => {
        store.addNotification({
          title: t('additionallayers.error'),
          message: t('additionallayers.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  saveData = (data) => {
    const { t } = this.props;
    const _this = this;
    const newData = JSON.parse(JSON.stringify(data));
    _this.props.block();
    if (newData.id !== undefined) {
      ApiService.putRequest(API_REST + 'api/additionallayer/' + newData.id, newData)
      .then((response) => {
        _this.handleClose();
        _this.loadAdditionalLayers(false);
        store.addNotification({
          title: t('additionallayers.saved'),
          message: t('additionallayers.changes_saved'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('additionallayers.error'),
          message: t('additionallayers.edit_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    } else {
      newData.sort = _this.getMaxSort() + 1;
      ApiService.postRequest(API_REST + 'api/additionallayer/', newData)
      .then((response) => {
        _this.handleClose();
        _this.loadAdditionalLayers(false);
        store.addNotification({
          title: t('additionallayers.saved'),
          message: t('additionallayers.created'),
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 1500,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      }).catch((error) => {
        _this.props.unblock();
        _this.handleClose();
        store.addNotification({
          title: t('additionallayers.error'),
          message: t('additionallayers.save_error'),
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 5000,
            click: true,
            touch: true,
            showIcon: true,
          },
        });
      });
    }
  }

  remove = (data) => {
    const { t } = this.props;
    const _this = this;
    _this.props.block();
    ApiService.deleteRequest(API_REST + 'api/additionallayer/' + data.id)
    .then((response) => {
      _this.loadAdditionalLayers(false);
      store.addNotification({
        title: t('additionallayers.saved'),
        message: t('additionallayers.removed'),
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 1500,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    }).catch((error) => {
      _this.props.unblock();
      store.addNotification({
        title: t('additionallayers.error'),
        message: t('additionallayers.delete_error'),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'zoomOut'],
        dismiss: {
          duration: 5000,
          click: true,
          touch: true,
          showIcon: true,
        },
      });
    });
  }

  getMaxSort = () => {
    let sort = 0;
    this.state.data.forEach((d) => {
      if (d.sort > sort) {
        sort = d.sort;
      }
    });

    return sort;
  }

  render() {
    const { t } = this.props;
    const { opened, selected, data } = this.state;
    const rows = [];
    data.forEach((d) => {
      rows.push(
        <tr key={'additionalLayer' + d.id}>
          <td>{d.title}</td>
          <td>{d.titleEn}</td>
          <td>{d.name}</td>
          <td>{d.url}</td>
          <td style={{ textAlign: 'center' }}>{d.version}</td>
          <td>{d.queryable === true ? <div style={{ color: '#fda823' }}><Icon size={'28px'} icon={ic_done} /></div> : d.queryable === false ? <div><Icon size={'28px'} icon={ic_close} /></div> : <div>-</div>}</td>
          <td>{d.tiled === true ? <div style={{ color: '#fda823' }}><Icon size={'28px'} icon={ic_done} /></div> : d.tiled === false ? <div><Icon size={'28px'} icon={ic_close} /></div> : <div>-</div>}</td>
          <td>
            <div>
              { d.sort > 1 ?
                <ActionButton id={'additionallayerUp' + d.id} icon={arrowUp} size={'18px'} color={'#fda823'} tooltip={t('additionallayers.up')} onClick={this.move.bind(this, 'up', d)} />
              : null }
              { d.sort < this.getMaxSort() ?
                <ActionButton id={'additionallayerDown' + d.id} icon={arrowDown} size={'18px'} color={'#fda823'} tooltip={t('additionallayers.down')} onClick={this.move.bind(this, 'down', d)} />
              : null }
              <ActionButton id={'additionallayerEdit' + d.id} icon={pencil} size={'18px'} color={'#fda823'} tooltip={t('additionallayers.edit')} onClick={this.handleOpen.bind(this, d)} />
              <ActionButton id={'additionallayerDelete' + d.id} icon={close} size={'18px'} color={'#ff0000'} tooltip={t('additionallayers.remove')} onClick={this.remove.bind(this, d)} />
            </div>
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <div className='admin-content'>
          <p className='admin-content-title'>{t('additionallayers.additionallayers')}</p>
          { data.length > 0 ?
            <div className='admin-additionallayers'>
              <div>
                <table>
                  <thead>
                    <tr>
                      <td>{t('additionallayers.title')}</td>
                      <td>{t('additionallayers.titleEn')}</td>
                      <td>{t('additionallayers.name')}</td>
                      <td>URL</td>
                      <td>{t('additionallayers.version')}</td>
                      <td>{t('additionallayers.queryable')}</td>
                      <td>{t('additionallayers.tiled')}</td>
                      <td>{t('additionallayers.actions')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    { rows }
                  </tbody>
                </table>
              </div>
            </div>
          : null }
          <div className='admin-menu-button-container2'>
            <Button text={t('additionallayers.addLayer')} active={true} onClick={this.handleOpen.bind(this, undefined)} />
          </div>
        </div>
        <CustomModal open={opened} onClose={this.handleClose} title={t('additionallayers.layerData')}>
          <AdditionalLayerForm data={selected} saveData={this.saveData} />
        </CustomModal>
      </React.Fragment>
    );
  }
}

export default translate()(AdditionalLayers);
