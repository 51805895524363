import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { CKEditor } from 'ckeditor4-react';

import Button from 'components/Button/Button';

import LocalStorage from 'utils/LocalStorage';

import './RSSConfigForm.css';

class RSSConfigForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data !== undefined ? JSON.parse(JSON.stringify(this.props.data)) : { title: '',  description: '' },
      errorMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.data !== undefined) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 100);
    }
  }

  setDescriptionValue = (evt) => {
    const newData = this.state.data;
    newData.description = evt.editor.getData();
    this.setState({ data: newData });
  }

  save = () => {
    let newData = this.state.data;
    let emptyFields = false;
    const title = document.querySelector('#rssConfigFormTitle');
    const description = document.querySelector('div.cke');
    newData.title = title.value.trim();
    if (newData.title === '') {
      emptyFields = true;
      title.style.border = '2px solid red';
    } else {
      title.style.border = '1px solid black';
    }

    if (newData.description === '') {
      emptyFields = true;
      description.style.border = '2px solid red';
    } else {
      description.style.border = '1px solid #d1d1d1';
    }

    if (!emptyFields) {
      this.setState({ errorMessage: false }, () => {
        this.props.saveData(newData);
      });
    } else {
      this.setState({ errorMessage: true });
    }
  }

  render() {
    const { t } = this.props;
    const { data, errorMessage } = this.state;
    const config = {
      allowedContent: true,
      language: LocalStorage.getString('language') || 'es',
      resize_enabled: false,
      scayt_sLang: 'es_ES',
      extraPlugins: 'colorbutton,colordialog,tabletools',
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'align', 'bidi', 'blocks', 'paragraph' ] },
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        '/',
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'others', groups: [ 'others' ] },
      ],
      removeButtons: 'Paste,PasteText,PasteFromWord,Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,HiddenField,Select,Textarea,TextField,Radio,Checkbox,Form,Superscript,Subscript,CopyFormatting,Outdent,Indent,Language,BidiRtl,BidiLtr,Anchor,Flash,Smiley,PageBreak,Iframe,About,Blockquote',
    };

    return (
      <div className='rssconfig-form-container'>
        <div className='rssconfig-form' style={{ position: 'relative', left: '-7px' }}>
          <div>
            <label>{t('rssconfigform.title')}</label>
            <br/>
            <input type='text' id='rssConfigFormTitle' defaultValue={data.title} />
          </div>
          <div>
            <label style={{ position: 'relative', top: '10px' }}>{t('rssconfigform.description')}</label>
            <br/><br/>
            <CKEditor config={config} initData={data.description} onChange={this.setDescriptionValue} />
          </div>
        </div>
        { errorMessage === true ?
          <div className='rssconfig-form-error'>{t('rssconfigform.error')}</div>
        : null }
        <div className='rssconfig-form-button-container'>
          <Button text={t('rssconfigform.save')} active={true} onClick={this.save} />
        </div>
      </div>
    );
  }
}

export default translate()(RSSConfigForm);
