import React, { Component } from 'react';
import { translate } from 'react-i18next';
import ReactNotification from 'react-notifications-component';

import Signin  from 'components/Signin/Signin';
import LayoutAdmin from 'components/LayoutAdmin/LayoutAdmin';

import 'react-notifications-component/dist/theme.css';

class Login extends Component {

  render() {
    return (
      <div className='app-container'>
        <ReactNotification />
        <LayoutAdmin option='login'>
          <Signin />
        </LayoutAdmin>
      </div>
    );
  }
}

export default translate()(Login);
