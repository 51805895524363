import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { store } from 'react-notifications-component';

import Button from 'components/Button/Button';
import LocalStorage from 'utils/LocalStorage';
import { getScale } from 'utils/Visor';

import './PrintSelection.css';

declare var CONFIGURATIONS;

class PrintSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateSelected: this.props.printSizes.indexOf('A4') > -1 ? '21.0x29.7' : '29.7x42.0',
      scale: this.selectScale(),
      scaleCustom: '',
      orientation: 'V',
      paper_cert: false,
      digital_cert: false,
      scales: this.props.scales,
    };
  }

  componentDidMount() {
    const { t } = this.props;
    this.accept();
    const component = <div className='notification-content notification-info'>
      <div className='notification-close'></div>
      <p className='notification-title'>{t('printSelection.info')}</p>
      <p className='notification-message'>
        <span>
          {t('printSelection.step1')}<br/>
          {t('printSelection.step2')}<br/>
          {t('printSelection.step3')}<br/>
        </span>
      </p>
    </div>;

    const notificationId = store.addNotification({
      id: 'print-notification',
      title: t('printSelection.info'),
      type: 'info',
      insert: 'top',
      content: component,
      container: 'top-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'zoomOut'],
      dismiss: {
        duration: 999999999,
        click: true,
        touch: true,
        showIcon: true,
      },
    });

    LocalStorage.putString('notificationId', notificationId);
    document.addEventListener('keyup', this.enterKeyPrint);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.enterKeyPrint);
  }

  selectScale = () => {
    const scales = this.props.scales;
    const num = getScale()/4;
    let res = scales[0];
    for (let i = 0; i < scales.length - 1; i++) {
      let l = scales[i];
      let u = scales[i + 1];
      if (l < num && u > num) {
        if (Math.abs(num - l) < Math.abs(num - u)) {
          res = l;
        } else {
          res = u;
        }
        break;
      }
    }

    return res;
  }

  changeProperty = (property, evt) => {
    let value = evt.target.value;
    let newState = this.state;
    let reloadFigure = true;

    if (property === 'scale') {
      value = parseInt(value, 10);
    } else if (property === 'scaleCustom') {
      value = parseInt(value, 10);
      if (isNaN(value)) {
        value = '';
      }
    } else if (property === 'paper_cert') {
      value = !this.state.paper_cert;
      reloadFigure = false;
    } else if (property === 'digital_cert') {
      value = !this.state.digital_cert;
      reloadFigure = false;
    }

    newState[property] = value;
    this.setState(newState, () => {
      if (reloadFigure === true) {
        this.accept();
      }
    });
  }

  accept = () => {
    const { templateSelected, scale, scaleCustom, orientation } = this.state;
    let scaleSelected = scale;
    if (scaleCustom !== '') {
      scaleSelected = scaleCustom;
    }

    let template = templateSelected;
    if (templateSelected.startsWith('21.0')) {
      if (orientation === 'V') {
        template = 'A4 Vertical';
      } else {
        template = 'A4 Horizontal';
      }
    } else if (templateSelected.startsWith('29.7')) {
      if (orientation === 'V') {
        template = 'A3 Vertical';
      } else {
        template = 'A3 Horizontal';
      }
    } else {
      if (orientation !== 'V') {
        const sizes = templateSelected.split('x').reverse();
        template = sizes[0] + 'x' + sizes[1];
      }
    }

    this.props.acceptPrint(scaleSelected, template);
  }

  print = () => {
    const { sendToPrint } = this.props;
    const { paper_cert, digital_cert } = this.state;
    document.removeEventListener('keyup', this.enterKeyPrint);
    sendToPrint(paper_cert, digital_cert);
  }

  enterKeyPrint = (evt) => {
    const keyCode = evt.charCode || evt.which;
    if (keyCode === 13) {
      this.print();
    }
  }

  render() {
    const { t, certifiable, printPaper, printSizes } = this.props;
    const { templateSelected, scale, paper_cert, digital_cert, scaleCustom, scales, orientation } = this.state;

    const optionScales = [];
    scales.forEach((s, index) => {
      optionScales.push(<option key={'scaleOption' + index} value={s}>{s}</option>);
    });

    return (
      <React.Fragment>
        <div className='row printSelectionRow'>
          <span>{t('printSelection.size')}</span>
          <select id='selectTemplate' value={templateSelected} onChange={this.changeProperty.bind(null, 'templateSelected')}>
            { printSizes.indexOf('A4') > -1 ? <option value='21.0x29.7'>A4: 21.0x29.7 cm</option> : null }
            { printSizes.indexOf('A3') > -1 ? <option value='29.7x42.0'>A3: 29.7x42.0 cm</option> : null }
            { printSizes.indexOf('25x25') > -1 ? <option value='25x25'>25x25 cm</option> : null }
            { printSizes.indexOf('25x30') > -1 ? <option value='25x30'>25x30 cm</option> : null }
            { printSizes.indexOf('30x40') > -1 ? <option value='30x40'>30x40 cm</option> : null }
            { printSizes.indexOf('40x50') > -1 ? <option value='40x50'>40x50 cm</option> : null }
            { printSizes.indexOf('50x50') > -1 ? <option value='50x50'>50x50 cm</option> : null }
            { printSizes.indexOf('50x60') > -1 ? <option value='50x60'>50x60 cm</option> : null }
          </select>
        </div>
        <div className='row printSelectionRow'>
          <span>{t('printSelection.scale')}</span>
            <select id='selectScale' value={scale} onChange={this.changeProperty.bind(null, 'scale')} disabled={scaleCustom !== ''}>
              {optionScales}
            </select>
          <input type='number' id='customScale' name='customScale' onChange={this.changeProperty.bind(null, 'scaleCustom')} placeholder={t('printSelection.custom')} defaultValue={scaleCustom} />
        </div>
        <div className='row printSelectionRow'>
          <span>{t('printSelection.orientation')}</span>
          <select id='selectOrientation' value={orientation} onChange={this.changeProperty.bind(null, 'orientation')}>
            <option value={'V'}>{t('printSelection.vertical')}</option>
            <option value={'H'}>{t('printSelection.horizontal')}</option>
          </select>
        </div>
        { certifiable ?
          <React.Fragment>
            <hr className='printDivision' />
            <p className='printSelection-title'>{t('printSelection.request_certificate')}</p>
            <p className='printMessage'><i dangerouslySetInnerHTML={{ __html: t('printSelection.certificate_text') }}></i></p>
              <div className='row printSelectionRow' style={{ justifyContent: 'center' }}>
                { printPaper ?
                  <React.Fragment>
                    <input type='checkbox' name='paperCert' value='paperCert' checked={paper_cert}
                      onChange={this.changeProperty.bind(null, 'paper_cert')} /> {t('printSelection.paper')}
                    <span className='optionsSeparation'></span>
                  </React.Fragment>
                : null }
                <input type='checkbox' name='digitalCert' value='digitalCert' checked={digital_cert}
                  onChange={this.changeProperty.bind(null, 'digital_cert')} /> {t('printSelection.digital')}
              </div>
          </React.Fragment>
        : null }
        <div className='row printSelectionButtonContainer'>
          <Button text={t('printSelection.accept')} active={true} onClick={this.print} />
        </div>
      </React.Fragment>
    );
  }
}

export default translate()(PrintSelection);
