import React, { Component } from 'react';
import { translate } from 'react-i18next';
import TagsInput from 'react-tagsinput';

import Button from 'components/Button/Button';

import './FlightForm.css';

class FlightForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data !== undefined ? JSON.parse(JSON.stringify(this.props.data)) : { certifiable: false, printable: false, keys: '', printScales: '', printSizes: 'A4, A3' },
      errorMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.data !== undefined) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 100);
    }
  }

  changeCheckbox = (name) => {
    let newData = this.state.data;
    newData[name] = !this.state.data[name];
    this.setState({ data: newData });
  }

  handleChangeTags = (name, tags) => {
    let newData = this.state.data;
    newData[name] = tags.join(', ');
    this.setState({ data: newData });
  }

  save = () => {
    let newData = this.state.data;
    let emptyFields = false;
    const category = document.querySelector('#flightformGroup');
    const title = document.querySelector('#flightformTitle');
    const subtitle = document.querySelector('#flightformSubtitle');
    const subtitleEn = document.querySelector('#flightformSubtitleEn');
    const description = document.querySelector('#flightformDescription');
    const descriptionEn = document.querySelector('#flightformDescriptionEn');
    const layer = document.querySelector('#flightformLayer');
    const filesName = document.querySelector('#flightformFilesName');
    const keys = document.querySelector('#flightformKeys');
    const printScales = document.querySelector('#flightformPrintScales');
    const printSizes = document.querySelector('#flightformPrintSizes');
    newData.title = title.value.trim();
    newData.subtitle = subtitle.value.trim();
    newData.subtitleEn = subtitleEn.value.trim();
    newData.description = description.value.trim();
    newData.descriptionEn = descriptionEn.value.trim();
    newData.layer = layer.value.trim();
    newData.filesName = filesName.value.trim();
    if (category !== null) {
      newData.category = parseInt(category.value);
    }

    if (newData.title === '') {
      emptyFields = true;
      title.style.border = '2px solid red';
    } else {
      title.style.border = '1px solid black';
    }

    if (newData.subtitle === '') {
      emptyFields = true;
      subtitle.style.border = '2px solid red';
    } else {
      subtitle.style.border = '1px solid black';
    }

    if (newData.subtitleEn === '') {
      emptyFields = true;
      subtitleEn.style.border = '2px solid red';
    } else {
      subtitleEn.style.border = '1px solid black';
    }

    if (newData.description === '') {
      emptyFields = true;
      description.style.border = '2px solid red';
    } else {
      description.style.border = '1px solid black';
    }

    if (newData.descriptionEn === '') {
      emptyFields = true;
      descriptionEn.style.border = '2px solid red';
    } else {
      descriptionEn.style.border = '1px solid black';
    }

    if (newData.layer === '') {
      emptyFields = true;
      layer.style.border = '2px solid red';
    } else {
      layer.style.border = '1px solid black';
    }

    if (newData.filesName === '') {
      emptyFields = true;
      filesName.style.border = '2px solid red';
    } else {
      filesName.style.border = '1px solid black';
    }

    if (newData.keys === '') {
      emptyFields = true;
      keys.parentElement.parentElement.style.border = '2px solid red';
    } else {
      keys.parentElement.parentElement.style.border = '1px solid black';
    }

    if (newData.printScales === '') {
      emptyFields = true;
      printScales.parentElement.parentElement.style.border = '2px solid red';
    } else {
      printScales.parentElement.parentElement.style.border = '1px solid black';
    }

    if (newData.printSizes === '') {
      emptyFields = true;
      printSizes.parentElement.parentElement.style.border = '2px solid red';
    } else {
      printSizes.parentElement.parentElement.style.border = '1px solid black';
    }

    if (!emptyFields) {
      this.props.groupsData.forEach((gd) => {
        if (gd.id === newData.category) {
          newData.category_name = gd.name;
        }
      });

      this.setState({ errorMessage: false }, () => {
        this.props.saveData(newData);
      });
    } else {
      this.setState({ errorMessage: true });
    }
  }

  render() {
    const { t, groupsData, lang } = this.props;
    const { data, errorMessage } = this.state;
    const keys = data.keys !== '' ? data.keys.split(',').map(k => k.trim()) : [];
    const printScales = data.printScales !== '' ? data.printScales.split(',').map(k => k.trim()) : [];
    const printSizes = data.printSizes.split(',').map(k => k.trim());
    const groupRows = [];
    groupsData.forEach((gd) => {
      groupRows.push(<option key={'gd' + gd.id} value={gd.id}>{lang === 'en' ? gd.nameEn : gd.name}</option>)
    });

    return (
      <div className='flight-form-container'>
        <div className='flight-form'>
          <div>
            <div>
              <label>{t('flightForm.group')}</label>
              <br/>
              { data.category_name !== undefined ?
                <input type='text' disabled defaultValue={data.category_name} />
              : <select id='flightformGroup'>
                  { groupRows }
              </select> }
            </div>
            <div>
              <label>{t('flightForm.title')}</label>
              <br/>
              <input type='text' id='flightformTitle' defaultValue={data.title !== undefined ? data.title : ''} />
            </div>
            <div>
              <label>{t('flightForm.subtitle') + ' (es)'}</label>
              <br/>
              <input type='text' id='flightformSubtitle' defaultValue={data.subtitle !== undefined ? data.subtitle : ''} />
            </div>
            <div>
              <label>{t('flightForm.subtitle') + ' (en)'}</label>
              <br/>
              <input type='text' id='flightformSubtitleEn' defaultValue={data.subtitleEn !== undefined ? data.subtitleEn : ''} />
            </div>
            <div>
              <label>{t('flightForm.descriptionEs')}</label>
              <br/>
              <textarea id='flightformDescription' defaultValue={data.description !== undefined ? data.description : ''}></textarea>
            </div>
            <div>
              <label>{t('flightForm.descriptionEn')}</label>
              <br/>
              <textarea id='flightformDescriptionEn' defaultValue={data.descriptionEn !== undefined ? data.descriptionEn : ''}></textarea>
            </div>
          </div>
          <div>
            <div>
              <label>{t('flightForm.wmsName')}</label>
              <br/>
              <input type='text' id='flightformLayer' defaultValue={data.layer !== undefined ? data.layer : ''} />
            </div>
            <div>
              <label>{t('flightForm.fileName')}</label>
              <br/>
              <input type='text' id='flightformFilesName' defaultValue={data.filesName !== undefined ? data.filesName : ''} />
            </div>
            <div>
              <label>{t('flightForm.certifiable')}</label>
              <input type='checkbox' id='flightformCertifiable' name='certifiable' onChange={this.changeCheckbox.bind(this, 'certifiable')} checked={data.certifiable} />
            </div>
            <div>
              <label>{t('flightForm.printable')}</label>
              <input type='checkbox' id='flightformPrintable' name='printable' onChange={this.changeCheckbox.bind(this, 'printable')} checked={data.printable} />
            </div>
            <div>
              <label>{t('flightForm.keys')}</label>
              <TagsInput value={keys} onChange={this.handleChangeTags.bind(this, 'keys')} onlyUnique={true} inputProps={{ id: 'flightformKeys', placeholder: t('flightForm.addValue') }} />
            </div>
            <div>
              <label>{t('flightForm.scales')}</label>
              <TagsInput value={printScales} onChange={this.handleChangeTags.bind(this, 'printScales')} onlyUnique={true} inputProps={{ id: 'flightformPrintScales', placeholder: t('flightForm.addValue') }} />
            </div>
            <div>
              <label>{t('flightForm.sizes')}</label>
              <TagsInput value={printSizes} onChange={this.handleChangeTags.bind(this, 'printSizes')} onlyUnique={true} inputProps={{ id: 'flightformPrintSizes', placeholder: t('flightForm.addValue') }} />
            </div>
          </div>
        </div>
        { errorMessage === true ?
          <div className='flightlayers-error'>{t('flightForm.error')}</div>
        : null }
        <div className='flight-form-button-container'>
          <Button text={t('flightForm.save')} active={true} onClick={this.save} />
        </div>
      </div>
    );
  }
}

export default translate()(FlightForm);
